import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClassesService from "./classes.services";

export const getClassesList = createAsyncThunk(
    "classes/get-class",
    async (classesData, thunkAPI) => {
        try {
            return await ClassesService.getclasses(classesData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getClass = createAsyncThunk(
    "classes/get-singleClass",
    async (id, thunkAPI) => {
        try {
            return await ClassesService.getClass(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const deleteClass = createAsyncThunk(
    "classes/delete-class",
    async (id, thunkAPI) => {
        try {
            return await ClassesService.deleteClass(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const updateClasses = createAsyncThunk(
    "classes/update-classes",
    async (classesData, thunkAPI) => {

        try {
            return await ClassesService.updateClass(classesData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const createClasses = createAsyncThunk(
    "classes/create-class",
    async (ClassesData, thunkAPI) => {
        try {
            return await ClassesService.createClass(ClassesData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const updateActiveInactive = createAsyncThunk(
    "classes/update-active-inactive",
    async (data, thunkAPI) => {

        try {
            return await ClassesService.updateActiveInactive(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const resetState = createAction("RevertAll");

const initialState = {
    classes: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const classesslice = createSlice({
    name: "classes",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClassesList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getClassesList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.classes = action.payload;
            })
            .addCase(getClassesList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createClasses.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createClasses.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createdClasses = action.payload;
            })
            .addCase(createClasses.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateClasses.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateClasses.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedFaq = action.payload;
            })
            .addCase(updateClasses.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteClass.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteClass.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.deletedClass = action.payload;
            })
            .addCase(deleteClass.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getClass.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getClass.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.getClass = action.payload;

            })
            .addCase(getClass.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            // .addCase(updateActiveInactive.pending, (state) => {
            //     state.isLoading = true;
            // })
            // .addCase(updateActiveInactive.fulfilled, (state, action) => {
            //     state.isLoading = false;
            //     state.isError = false;
            //     state.isSuccess = true;
            //     state.message = action.payload.message

            // })
            // .addCase(updateActiveInactive.rejected, (state, action) => {
            //     state.isLoading = false;
            //     state.isError = true;
            //     state.isSuccess = false;
            //     state.message = action.error;
            // })
            .addCase(resetState, () => initialState);
    },
});

export default classesslice.reducer;