import React from 'react';
import { RouterProvider } from "react-router-dom";
import { Routes } from "./Routes";

const router = Routes();

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
