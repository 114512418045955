import React, { useEffect, useState } from 'react'

function UserSorting({ changeLimit, currentValue }) {

    const [selectedVal, setSelectedVal] = useState(currentValue || '1')

    // Update local state when parent state changes
    useEffect(() => {
        setSelectedVal(currentValue);
    }, [currentValue]);
    return (
        <div className="col-sm-6 col-md-3">
            <div className="dataTables_length" id="dataTable_length">
                <label>
                    {/* The select element allows the user to choose the number of items to display per page. */}
                    <select
                        name="dataTable_length"
                        aria-controls="dataTable"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        // When the user selects a different option, the 'changeLimit' function is called with the event as the argument.
                        onChange={(e) => {
                            setSelectedVal(e.target.value);
                            changeLimit(e)
                        }}
                        value={selectedVal}
                    >
                        {/* Options for different page limits */}
                        <option value="">All Users</option>
                        <option value="0">Inactive Users</option>
                        <option value="1">Active users</option>
                    </select>
                </label>
            </div>
        </div>
    )
}

export default UserSorting