import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { getMenus, deleteMenu } from "../../features/menus/menus.slice";
import { toast } from "react-toastify";

const deletesMenu = ({ open, onClose, menuId, menuData }) => {
  const dispatch = useDispatch();

  const handleConfirmDeleteMenu = async () => {
    onClose();
    const response = await dispatch(deleteMenu(menuId?.idMenu));

    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getMenus(menuData));

    } else {
      toast.error(response.payload.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal">
      <DialogTitle>Delete Menu</DialogTitle>
      <DialogContent>
        Are you sure you want to delete <b>{menuId?.title}</b> menu?
      </DialogContent>
      <DialogActions>
        <button className="btn btn-primary" onClick={handleConfirmDeleteMenu}>Delete</button>
        <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
      </DialogActions>
    </Dialog>
  );
};

export default deletesMenu;
