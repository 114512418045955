
export const config = () => {
  const getTokenFromLocalStorage = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
    if (!getTokenFromLocalStorage?.data?.token) {
      // Redirect to home page
      window.location.href = "/";
      return; // Stop further execution
    }
  return {
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.data.token : ""
        }`,
      Accept: "application/json",
    },
  }
}
