import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const uploadMenuPic = async (menu) => {
    const headers = config();
    const response = await axios.post(`${base_url}menus/upload-pic`, menu, headers);

    return response.data;
};

const updateMenuPic = async (id) => {
    const headers = config();
    const response = await axios.put(`${base_url}menus/update-picture/${id}`, menu, headers);

    return response.data;
};

const updateActiveInactive = async (data) => {
    const headers = config();
    let { menuId, status } = data
    status = status === 1 ? 0 : 1;
    const formData = new FormData();
    formData.append('status', status);

    const response = await axios.patch(`${base_url}updateMenuActiveStatus/${menuId}`, formData, headers);

    return response.data;
};

const getMenus = async (menu) => {
    const headers = config();
    const requestConfig = { ...headers };

    if (menu && menu.pageSize && menu.pageNumber) {
        requestConfig.params = {
            search: menu.search || '',
            pageSize: menu.pageSize,
            pageNumber: menu.pageNumber,
        };
    }

    const response = await axios.get(`${base_url}getMenus/`, requestConfig);

    return response.data;
};

const getActiveMenus = async () => {
    const headers = config();
    const response = await axios.get(`${base_url}menus/active`, headers);

    return response.data;
};

const createMenu = async (menu) => {
    const headers = config();
    const response = await axios.post(`${base_url}addMenu/`, menu, headers);

    return response.data;
};

const getMenu = async (id) => {
    const headers = config();
    const response = await axios.get(`${base_url}getMenuById/${id}`, headers);

    return response.data;
};

const deleteMenu = async (id) => {
    const headers = config();
    const response = await axios.delete(`${base_url}deleteMenus/${id}`, headers);

    return response.data;
};

const updateMenu = async (menu) => {
    const headers = config();
    const response = await axios.put(
        `${base_url}menus/${menu.idMenu}`,
        {
            title: menu.title,
            description: menu.description,
            imageUrl: menu.imageUrl,
            categoryId: menu.categoryId,
            ingredientIds: menu.ingredientIds,
            price: menu.price
        },
        headers
    );
    return response.data;
};

const searchMenus = async (menu) => {
    const headers = config();
    const requestConfig = { ...headers };

    if (menu && menu.pageSize && menu.pageNumber || menu.search) {
        requestConfig.params = {
            search: menu.search,
            pageSize: menu.pageSize,
            pageNumber: menu.pageNumber
        };
    }

    const response = await axios.get(`${base_url}getMenus`, requestConfig);

    return response.data;
};

const MenuService = {
    getMenus,
    createMenu,
    getMenu,
    deleteMenu,
    updateMenu,
    uploadMenuPic,
    updateMenuPic,
    getActiveMenus,
    updateActiveInactive,
    searchMenus
};

export default MenuService;
