import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./auth.services";

// const getUserfromLocalStorage = localStorage.getItem("user")
//   ? JSON.parse(localStorage.getItem("user"))
//   : null;

const initialState = {
  user: {},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      
      return await authService.login(userData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const currentUser = createAsyncThunk(
  "auth/current-user",
  async (thunkAPI) => {
    try {
      return await authService.currentUser();
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateProfilePic = createAsyncThunk(
  "auth/update-profile-pic",
  async (userData, thunkAPI) => {
    try {
      return await authService.updateProfilePic(userData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/update-info",
  async (userData, thunkAPI) => {
    try {
      return await authService.updateProfile(userData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/update-password",
  async (userData, thunkAPI) => {
    try {
      return await authService.updatePassword(userData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  
  reducers: {
    logout(state, action) {
      state.user = [];
      state.isSuccess = false;
      state.status = false;
    }
  },
  extraReducers: (buildeer) => {
    buildeer
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.data;
        state.message = action.payload.message;
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(currentUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(currentUser.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.data;
        state.message = action.payload.message;
      })
      .addCase(currentUser.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = action.payload.message;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(updateProfilePic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfilePic.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = action.payload.message;
      })
      .addCase(updateProfilePic.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = action.payload.message;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message;
        state.isLoading = false;
      })
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
