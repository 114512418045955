import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from '@mui/material';
import commonFunction from '../../utils/commonFunction';
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { getUsers, searchUsers } from '../../features/users/users.slice';
import DefaultPagination from '../../config/Pagination';

function WallletComponent({ open, onClose, user, userData }) {

    const dispatch = useDispatch();
    const [walletType, setWalletType] = useState(''); // To store add/remove selection


    // const [userData, setUserData] = useState({
    //     pageSize: DefaultPagination.PAGE_SIZE,
    //     pageNumber: DefaultPagination.PAGE_NUMBER,
    //     isactive: 1,
    // });

    const schema = yup.object().shape({
        wallet: yup.number().required('Please enter valid amount number').positive('Amount must be greater than 0'),
    });

    const formik = useFormik({
        initialValues: {
            idUser: user.idUser,
            wallet: '',

        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            // Ensure a valid walletType is selected
            if (walletType === '') {
                toast.error("Please select an amount type (Add or Remove)");
                return;
            }
            // Perform wallet update logic based on walletType
            const amount = parseFloat(values.wallet);
            let updatedWallet = user.wallet; // Current wallet balance

            if (walletType === 'add') {
                updatedWallet += amount;
            } else if (walletType === 'remove') {
                if (amount > updatedWallet) {
                    toast.error("Amount exceeds the available balance, wallet cannot go negative.");
                    return;
                }
                updatedWallet -= amount;
            }
            values.wallet = updatedWallet;
            try {
                const response = await commonFunction.addWalletAmount(values);
                if (response.data.status) {
                    toast.success(response.data.message);
                    if (!userData.search || userData.search.trim() === "") {
                        // userData.search is empty or contains only whitespace
                        dispatch(getUsers(userData));
                    } else {
                        // userData.search is not empty
                        dispatch(searchUsers(userData));
                    }
                    onClose();

                    // Reset the form after successful submission
                    setWalletType('');
                    formik.resetForm();
                } else {
                    toast.error(response.payload.message);
                }
            } catch (error) {
                // Handle error if necessary
                console.log(error);
            }
        },
    });

    // Function to handle modal close and reset the form
    const handleClose = () => {
        setWalletType('')
        formik.resetForm(); // Reset the form when the modal is closed
        onClose(); // Close the modal
    };
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Wallet Details for the {user.firstName} {user.lastName} </DialogTitle>
                <DialogContent>
                    {/* Display wallet section */}
                    <Typography variant="h6" gutterBottom>
                        Total Wallet Amount:
                        <Typography variant="h6" gutterBottom>
                            "{user.wallet ? user.wallet : 'no amount'}"
                        </Typography>
                    </Typography>

                    <div className="form-group">
                        <div className="form-floating">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="wallet">Amount type</label>
                                        <select
                                            className="form-control"
                                            id="walletType"
                                            name="walletType"
                                            value={walletType}
                                            onChange={(e) => setWalletType(e.target.value)}
                                        // onBlur={formik.handleBlur}
                                        >
                                            <option value="">Select type</option>
                                            <option value="add">Add </option>
                                            <option value="remove">Remove</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <label htmlFor="wallet">Add Amount</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="wallet"
                                        placeholder="Enter amount to add in wallet"
                                        name="wallet"
                                        value={formik.values.wallet}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoComplete="on"
                                    />
                                    {formik.touched.wallet && formik.errors.wallet && (
                                        <div className="error m-1">{formik.errors.wallet}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Add</button>
                    <button className="btn btn-secondary" type='button' onClick={handleClose}>Close</button>
                </DialogActions>
            </form>
        </Dialog >
    )
}

export default WallletComponent