import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { createIngredient, getIngredients } from "../../features/ingredients/ingredients.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  title: yup.string().required("Title is Required!"),
});

const AddIngredient = ({ open, onClose, ingredientData }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
          const response = await dispatch(createIngredient(values));

        if (response.payload.status) {
          toast.success(response.payload.message);
          dispatch(getIngredients(ingredientData));
          onClose();

          // Reset the form after successful submission
          formik.resetForm();
        } else {
          toast.error(response.payload.message);
        }
      } catch (error) {
        // Handle error if necessary
        toast.error("Error submitting the form.");
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal small-dialog">
    <form onSubmit={formik.handleSubmit}>
      <DialogTitle>Add Ingredient</DialogTitle>
      <DialogContent>
        <div className="form-group">
          <div className="form-floating">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter ingredient title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="on"
            />
          </div>
          {formik.touched.title && formik.errors.title && (
            <div className="error m-1">{formik.errors.title}</div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <button className="btn btn-primary" type="submit">Add</button>
        <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
      </DialogActions>
    </form>
  </Dialog>
  );
};

export default AddIngredient;
