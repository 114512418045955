import React, { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import commonFunction from "../../utils/commonFunction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getUserOrders } from "../../features/orders/orders.slice";
import OrdersModal from "../modals/OrdersModal";

const DataTable = ({ userHistory, dateObj }) => {
  const [selectedIds, setSelectedIds] = useState([]); // Store selected IDs

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const closeModal = () => setIsModalOpen(false);
  const dispatch = useDispatch();

  const orders = (userHistory?.orders || []).slice().sort((a,b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });

  const openModal = (id) => {
    setSelectedOrderId(id);
    setIsModalOpen(true);
  };

  // Handle checkbox toggle for individual rows
  const handleCheckboxToggle = (id) => {
    setSelectedIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If already selected, remove from array
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // Otherwise, add to array
        return [...prevSelected, id];
      }
    });
  };

  // Handle toggle for "Select All" checkbox
  const handleSelectAllToggle = () => {
    if (
      selectedIds.length === orders.filter((order) => order.status !== 2).length
    ) {
      // If all selectable rows are selected, clear the selection
      setSelectedIds([]);
    } else {
      // Otherwise, select all row IDs that are not deleted
      const allSelectableIds = orders
        .filter((row) => row.status !== 2)
        .map((row) => row.id);
      setSelectedIds(allSelectableIds);
    }
  };

  const isSelectedAll =
    selectedIds.length ===
      orders.filter((order) => order.status !== 2).length && orders.length > 0;

  const handleDelete = async () => {
    try {
      const response = await commonFunction.deleteUsersOrder(selectedIds);
      if (response?.data?.status === true) {
        toast.success("Order Deleted successfully");
        dispatch(getUserOrders(dateObj));
        setSelectedIds([]);
      } else {
        toast.error("Something went wrong, Please try again !!!");
      }
    } catch (error) {
      console.error("something went wrong", error);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelectedAll}
                  indeterminate={
                    selectedIds.length > 0 && selectedIds.length < orders.length
                  }
                  onChange={handleSelectAllToggle}
                />
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Order At</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Quickpay id</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Lunch Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No data found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              orders.map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIds.includes(row.id)}
                      onChange={() => handleCheckboxToggle(row.id)}
                      disabled={row.status === 2} // Disable checkbox if order is already deleted
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(row.createdAt).toLocaleDateString("en-GB")}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      openModal(row.id);
                    }}
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    {"Order"}
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.totalPrice}</TableCell>
                  <TableCell>{"---"}</TableCell>
                  <TableCell>{row.deliveryDate}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Delete button that is only enabled if orders are selected */}
      <Button
        variant="contained"
        color="secondary"
        disabled={selectedIds.length === 0} // Disable if no orders are selected
        onClick={handleDelete}
        style={{ marginTop: 16 }}
      >
        Delete Selected Orders
      </Button>
      <OrdersModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        orders={orders}
        selectedOrderId={selectedOrderId}
      />
    </>
  );
};

export default DataTable;
