import React from 'react'
import { useNavigate } from 'react-router-dom'; // Add this import
import { authActions } from '../../features/auth/auth.slice';
import { useDispatch } from 'react-redux'

const LogoutModal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const logoutHandler = () => {
        localStorage.removeItem('user');
        dispatch(authActions.logout());
        navigate("/");
        closeModal();
      }
    
      const closeModal = () => {
        const modal = document.getElementById('logoutModal');
        if (modal) {
            modal.classList.remove('show');
            const backdrop = document.getElementsByClassName('modal-backdrop')[0];
            if (backdrop) {
                document.body.removeChild(backdrop);
            }
        }
      };
    
    return (
        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Ready to Leave?
                        </h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Select "Logout" below if you are ready to end your current session.
                    </div>
                    <div className="modal-footer">
                        <span className="btn btn-primary" onClick={logoutHandler}>
                            Logout
                        </span>

                        <button className="btn btn-secondary" type="button" data-dismiss="modal">
                            Cancel
                        </button>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoutModal