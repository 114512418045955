import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductionServies from "./production.services";

export const getProductionList = createAsyncThunk(
    "production/get-list",
    async (productionData, thunkAPI) => {
        try {
            return await ProductionServies.getProductionList(productionData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getDistributionList = createAsyncThunk(
    "distribution/get-list",
    async (distributionData, thunkAPI) => {
        try {
            return await ProductionServies.getDistributionList(distributionData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getScheduledList = createAsyncThunk(
    "schedule/get-list",
    async (scheduleData, thunkAPI) => {
        try {
            // return await ProductionServies.getDistributionList(distributionData);
            return await ProductionServies.getScheduledList(scheduleData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const deleteSchedule = createAsyncThunk(
    "schedule/delete-schedule",
    async (id, thunkAPI) => {
        try {
            return await ProductionServies.deleteSchedule(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const createSchedule = createAsyncThunk(
    "schedule/create-schedule",
    async (data, thunkAPI) => {
        try {
            return await ProductionServies.createSchedule(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);


export const resetState = createAction("RevertAll");

const initialState = {
    production: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const productionslice = createSlice({
    name: "production",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductionList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProductionList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.production = action.payload;
            })
            .addCase(getProductionList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getDistributionList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDistributionList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.distribution = action.payload;
            })
            .addCase(getDistributionList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getScheduledList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getScheduledList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.schedule = action.payload;
            })
            .addCase(getScheduledList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteSchedule.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteSchedule.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.deletedSchedule = action.payload.message;
            })
            .addCase(deleteSchedule.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createSchedule.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createSchedule.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createSchedule = action.payload;
            })
            .addCase(createSchedule.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default productionslice.reducer;