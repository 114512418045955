import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SchoolService from "./schools.services";

export const getSchoolList = createAsyncThunk(
    "schools/get-school",
    async (schoolData, thunkAPI) => {
        try {
            return await SchoolService.getschools(schoolData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getSchool = createAsyncThunk(
    "schools/get-singleSchool",
    async (id, thunkAPI) => {
        try {
            return await SchoolService.getSchool(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const deleteSchool = createAsyncThunk(
    "faqs/delete-school",
    async (id, thunkAPI) => {
        try {
            return await SchoolService.deleteSchool(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const updateSchool = createAsyncThunk(
    "schools/update-school",
    async (schoolData, thunkAPI) => {

        try {
            return await SchoolService.updateSchool(schoolData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const createSchool = createAsyncThunk(
    "schools/create-school",
    async (schoolData, thunkAPI) => {
        try {
            return await SchoolService.createSchool(schoolData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const updateActiveInactive = createAsyncThunk(
    "schools/update-active-inactive",
    async (data, thunkAPI) => {

        try {
            return await SchoolService.updateActiveInactive(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const resetState = createAction("RevertAll");

const initialState = {
    schools: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const schoolslice = createSlice({
    name: "school",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSchoolList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSchoolList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.schools = action.payload;
            })
            .addCase(getSchoolList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createSchool.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createSchool.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createdSchool = action.payload;
            })
            .addCase(createSchool.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateSchool.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateSchool.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedFaq = action.payload;
            })
            .addCase(updateSchool.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteSchool.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteSchool.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.deletedSchool = action.payload;
            })
            .addCase(deleteSchool.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getSchool.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSchool.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.getSchool = action.payload;

            })
            .addCase(getSchool.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateActiveInactive.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateActiveInactive.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload.message

            })
            .addCase(updateActiveInactive.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default schoolslice.reducer;