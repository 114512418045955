import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { image_url, PROFILE_IMAGE_PATH } from "../../utils/baseUrl";
import * as yup from "yup";
import { useFormik } from "formik";
import { currentUser, updateProfile, updateProfilePic } from '../../features/auth/auth.slice';
import { toast } from 'react-toastify';

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currentUser());
  }, [dispatch]);

  const { user } = useSelector(state => state.auth)

  let schema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || '', // Ensure initial values are set
      lastName: user?.lastName || '', // Ensure initial values are set
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {

      const response = await dispatch(updateProfile(values));
      if (response.payload.status) {

        toast.success(response.payload.message);
        dispatch(currentUser())
      } else {
        toast.error(response.payload.message);
      }
    },
  });

  const handleProfileChange = () => {
    document.querySelector("input[type='file']").click();
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const imageData = new FormData();
      imageData.append("profile_pic", file);

      const response = await dispatch(updateProfilePic(imageData)); // Dispatch upload image action
      if (response.payload.status) {
        toast.success(response.payload.message);
        dispatch(currentUser())
      } else {
        toast.error(response.payload.message);
      }
    }
  };

  return (
    <>
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>

          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Profile
          </li>
        </ol>
      </nav>

      <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">
            User Profile
          </h1>
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">

                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-5">
                      <div className="text-center">
                        <div className="container profilePic">
                          <img
                            className="img-profile rounded-circle"
                            style={{ width: "150px" }}
                            src={(user?.avatarUrl) ? (image_url + PROFILE_IMAGE_PATH + user?.idUser + '/' + user?.avatarUrl) : "http://bootdey.com/img/Content/avatar/avatar1.png"}
                            alt='no'
                          />
                          <div className="overlay">
                            <a

                              className="icon"
                              title="User Profile"
                              onClick={handleProfileChange}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                          </div>
                        </div>

                        <h1 className="h4 text-gray-900 mb-4">
                          {user.firstName}
                        </h1>
                      </div>
                      <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          type="firstName"
                          className="form-control form-control-user"
                          id="firstName"
                          placeholder="Enter your First Name"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="on"
                        />
                      </div>

                      <div className="error m-1">
                        {formik.touched.firstName && formik.errors.firstName}
                      </div>

                      <div className="form-group mt-2">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="lastName"
                          className="form-control form-control-user"
                          id="lastName"
                          placeholder="Enter your Last Name"
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="on"
                        />
                      </div>

                      <div className="error m-1">
                        {formik.touched.lastName && formik.errors.lastName}
                      </div>

                      <div className="error m-1">
                        {formik.touched.mobileNumber && formik.errors.mobileNumber}
                      </div>

                      <div className="form-group mt-2 d-none">
                        <input
                          type="file"
                          id="profile_pic"
                          name="profile_pic"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>

                      <div className="form-group mt-2">
                        <button
                          type="submit"
                          className="btn btn-primary btn-user btn-block"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile