import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                <div className="col-md-6">
                    <div className="card text-center shadow">
                        <div className="card-body">
                            <h1 className="card-title">404</h1>
                            <h3 className="card-subtitle mb-2 text-muted">Page Not Found</h3>
                            <p className="card-text">The page you are looking for does not exist.</p>
                            <Link to="/admin" className="btn btn-primary">Go Back to Dashboard</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
