import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DefaultPagination from '../../config/Pagination';
import { getHotmeals, updateActiveInactive } from '../../features/hotmeals/hotmeals.slice';
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import { MENU_IMAGE_PATH, image_url } from '../../utils/baseUrl';
import AddMeal from './Add';
import { toast } from 'react-toastify';
import EditMeal from './Edit';
import DeleteMeal from './Delete';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';

const HotMealsList = () => {
    // Set initial hot meal item data with default values
    let hotMealData = {
        pageSize: DefaultPagination.PAGE_SIZE,
        pageNumber: DefaultPagination.PAGE_NUMBER,
    };

    // Function to handle delete Menu click
    const handleDeleteMeal = (menuId) => {
        setDeletingMenuId(menuId);
        setOpenDeleteDialog(true);
    };
    const dispatch = useDispatch();
    const [deletingMenuId, setDeletingMenuId] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openAddMealDialog, setOpenAddMealDialog] = useState(false);
    const [openEditMealDialog, setOpenEditMealDialog] = useState(false);
    const [editingMealId, setEditingMealItemId] = useState(null);
    const [loading, setLoading] = useState(true);

    // Use useEffect to fetch Hot Meals when pageSize or pageNumber changes
    useEffect(() => {
        dispatch(getHotmeals(hotMealData));
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [hotMealData.pageSize, hotMealData.pageNumber]);

    // Get Hot meal items data from Redux store
    const { hotmeal } = useSelector((state) => state.hotmeals);

    // Function to handle change in page size dropdown
    const changeTableData = (e) => {
        hotMealData.pageSize = parseInt(e.target.value);
        hotMealData.pageNumber = DefaultPagination.PAGE_NUMBER;
        dispatch(getHotmeals(hotMealData));
    };

    // Function to handle opening the Add Hot meal dialog
    const handleOpenAddMealDialog = () => {
        setOpenAddMealDialog(true);
    };

    // Function to handle Status of hot meals
    const handleStatusChange = async (mealId, status) => {

        const response = await dispatch(updateActiveInactive({ mealId, status }));

        if (response.payload.status) {
            toast.success(response.payload.message);
            dispatch(getHotmeals(hotMealData));
        } else {
            toast.error(response.payload.message);
        }
    };

    // Function to handle opening the Edit Hot Meal Item dialog
    const handleOpenEditItemDialog = (itemId) => {
        setEditingMealItemId(itemId);
        setOpenEditMealDialog(true);
    };

    // Function to handle search as the user types in the search input field
    const handleSearchMeal = (keyword) => {
        hotMealData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
        hotMealData.search = keyword.target.value;
        dispatch(getHotmeals(hotMealData));
    };

    // Function to handle pagination click with a specific page number
    const paginationWithPage = (pageNumber) => {
        hotMealData.pageNumber = pageNumber;
        dispatch(getHotmeals(hotMealData));
    };

    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Hot Meals
                    </li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Hot Meal Lists
                    </h1>
                    <a>
                        <span className="text">
                            <button className="btn btn-primary" onClick={handleOpenAddMealDialog}>+ Add Hot Meal</button>
                        </span>
                    </a>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        {/* Add loader */}
                        {loading ?
                            (
                                <Loader />
                            ) :
                            (
                                <div className="table-responsive">
                                    <div className="row">
                                        {/* Page size dropdown */}
                                        <PageLimit changeLimit={(e) => changeTableData(e)} />
                                        <div className="col-sm-12 col-md-6">
                                            <div id="dataTable_filter" className="dataTables_filter">
                                                <label>
                                                    {/* Search input */}
                                                    <input
                                                        type="search"
                                                        placeholder="search Inqueries"
                                                        className="form-control form-control-sm"
                                                        aria-controls="dataTable"
                                                        onChange={handleSearchMeal} // Dynamically trigger search on input change
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <table className="table table-bordered" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col" width="5%">No</th> */}
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Hot Meal Day</th>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Change Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Render Inquiries data */}
                                                {
                                                    (hotmeal?.data?.HotMeallist != undefined && hotmeal?.data?.HotMeallist.length > 0) ?
                                                        hotmeal?.data?.HotMeallist.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {/* <td scope="row">{index + 1}</td> */}
                                                                    <td>{item.Menu?.title}</td>
                                                                    <td>{item.hotMealDay}</td>
                                                                    <td>
                                                                        {
                                                                            item.Menu?.MenuImages !== undefined && item.Menu?.MenuImages.length ? (
                                                                                <div className="bordered-img-table">
                                                                                    <img src={`${image_url}${MENU_IMAGE_PATH}` + item.Menu?.MenuImages[0]?.image} className="table-img" alt="Menu" />
                                                                                </div>
                                                                            ) : "--"
                                                                        }
                                                                    </td>
                                                                    <td>{item.status ? "Active" : "Inactive"}</td>
                                                                    <td className="tdTextCenter">
                                                                        <div className="custom-control custom-checkbox small">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                htmlFor="customCheck"
                                                                                id="customCheck"
                                                                                name={['isChecked' + index]}
                                                                                checked={item.status}
                                                                                onChange={() => handleStatusChange(item.id, item.status)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            className="actionIcon"
                                                                            title="Edit"
                                                                            onClick={() => handleOpenEditItemDialog(item)}
                                                                        >
                                                                            <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                                                        </a>
                                                                        <a
                                                                            className="actionIcon"
                                                                            title="Delete"
                                                                            onClick={() => handleDeleteMeal(item.id)}
                                                                        >
                                                                            <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }) :
                                                        // Render "No records found" if there are no Schools
                                                        <tr>
                                                            <td colSpan="8" className="noRecordFound">No records found</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Pagination component */}
                                    <Pagination
                                        totalPage={hotmeal?.data?.pagination?.totalPages}
                                        currentPage={hotmeal?.data?.pagination?.currentPage}
                                        fullPagination={paginationWithPage}
                                        totalFaqs={hotmeal?.data?.pagination?.total || 1}
                                        pageSize={hotmeal?.data?.pagination?.pageSize || 10}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* Edit Hot Meal dialog */}
            <EditMeal
                open={openEditMealDialog}
                onClose={() => setOpenEditMealDialog(false)}
                hotMealData={hotMealData}
                mealId={editingMealId}
            />

            {/* Delete Hot Meal dialog */}
            <DeleteMeal
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                mealId={deletingMenuId}
                hotMealData={hotMealData}
            />
            {/* Add Meal dialog */}
            <AddMeal
                open={openAddMealDialog}
                onClose={() => setOpenAddMealDialog(false)}
                hotMealData={hotMealData}
            />
        </>
    )
}

export default HotMealsList;