// MainLayout.js
import React from 'react';
import SideBar from '../components/Common/SideBar';
import TopBar from '../components/Common/TopBar';
import Footer from '../components/Common/Footer';
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
      <div id="wrapper">
        <SideBar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopBar />
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
  );
};

export default MainLayout;
