import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateCategory, getCategories, getCategory } from "../../features/categories/categories.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { base_url } from '../../utils/baseUrl';
import { config } from '../../utils/axiosconfig';

const schema = yup.object().shape({
  title: yup.string().required("Title is Required!"),
  description: yup.string().required("Description is Required!")
});

const EditCategory = ({ open, onClose, categoryData, categoryId }) => {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      setImageError("");
    }
  };

  // Fetch category data by ID using getCategory API
  useEffect(() => {
    dispatch(getCategory(categoryId));
  }, [categoryId, dispatch]);

  // Get category data from Redux store
  const category = useSelector((state) => state.categories.getCategory);

  const formik = useFormik({
    initialValues: {
      title: category?.title || '',
      description: category?.description || '',
    },

    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const imageData = new FormData();
        if (imageFile) {
          imageData.append("imageUrl", imageFile);
        }
        imageData.append("title", values.title);
        imageData.append("description", values.description);

        const updatePictureEndpoint = `${base_url}/updateCategory/${categoryId}`;

        const apiConfig = config();
        // First, upload the image and get the image URL from the API
        const uploadResponse = await fetch(updatePictureEndpoint, {
          method: "PATCH",
          headers: {
            ...apiConfig.headers,
          },
          body: imageData,
        });


        const data = await uploadResponse.json();

        if (data.status) {
          toast.success(data.message);
          dispatch(getCategories(categoryData));
          onClose();
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        // Handle error if necessary
        toast.error("Error submitting the form.");
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal large-dialog">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              className="form-control"
            />
            {formik.touched.title && formik.errors.title && (
              <div className="error m-1">{formik.errors.title}</div>
            )}
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              className="form-control"
            />
            {formik.touched.description && formik.errors.description && (
              <div className="error m-1">{formik.errors.description}</div>
            )}
          </div>

          <div className="form-group">
            <label>Upload New Image</label>
            <input
              type="file"
              className="form-control"
              id="imageFile"
              name="imageFile"
              accept="image/*"
              onChange={handleImageChange}
            />
            {imageError && <div className="error m-1">{imageError}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" type="submit">Edit</button>
          <button className="btn btn-secondary" onClick={onClose}>
            Cancel
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCategory;
