import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateIngredient, getIngredient, getIngredients } from "../../features/ingredients/ingredients.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  title: yup.string().required("Title is Required!"),
});

const EditIngredient = ({ open, onClose, ingredientData, ingredientId }) => {
  const dispatch = useDispatch();

  // Fetch ingredient data by ID using getIngredient API
  useEffect(() => {
    dispatch(getIngredient(ingredientId));
  }, [ingredientId, dispatch]);

  // Get ingredient data from Redux store
  const ingredient = useSelector((state) => state.ingredients.getIngredient);

  const formik = useFormik({
    initialValues: {
      title: ingredient?.title || '',
    },

    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const postData = {
          idIngredient: ingredientId,
          ...values,
        };

        const response = await dispatch(updateIngredient(postData));

        if (response.payload.status) {
          toast.success(response.payload.message);
          dispatch(getIngredients(ingredientData));
          onClose();
        } else {
          toast.error(response.payload.message);
        }
      } catch (error) {
        // Handle error if necessary
        toast.error("Error submitting the form.");
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal small-dialog">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit Ingredient</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              className="form-control"
            />
            {formik.touched.title && formik.errors.title && (
              <div className="error m-1">{formik.errors.title}</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" type="submit">Edit</button>
          <button className="btn btn-secondary" onClick={onClose}>
            Cancel
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditIngredient;
