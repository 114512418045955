import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import ReactQuill from 'react-quill';
import commonFunction from '../../utils/commonFunction';

const AppPages = ({ open, onClose }) => {
    const formik = useFormik({
        initialValues: {
            title: '',
            heading: '',
            description: ''
        },
        // validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const res = await commonFunction.submitPageData(values); // Using await here
                if (res?.status === 200 && res?.data?.status) {
                    toast.success(res?.data?.message);
                    formik.resetForm(); // Reset the form after successful submission
                    onClose(); // Close the dialog
                } else {
                    toast.error("Failed to submit.");
                }
            } catch (error) {
                // Handle error if necessary
                toast.error("Error submitting the form.");
            }
        },
    });

    const handleDescriptionChange = (content) => {
        formik.setFieldValue('description', content);
    };

    return (
        <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Add School</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Enter Page Title"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.title && formik.errors.title && (
                            <div className="error m-1">{formik.errors.title}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="heading">Heading</label>
                            <input
                                type="text"
                                className="form-control"
                                id="heading"
                                placeholder="Enter Page Heading"
                                name="heading"
                                value={formik.values.heading}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.heading && formik.errors.heading && (
                            <div className="error m-1">{formik.errors.heading}</div>
                        )}
                    </div>

                    {/* Email input box */}

                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="description">Description</label>
                            <ReactQuill
                                value={formik.values.description}
                                onChange={handleDescriptionChange}
                                theme="snow"
                            />
                        </div>
                        {formik.touched.description && formik.errors.description && (
                            <div className="error m-1">{formik.errors.description}</div>
                        )}
                    </div>

                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Add</button>
                    <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AppPages