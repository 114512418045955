import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { getContacts, updateContact } from '../../features/contact/contact.slice';

const EditContact = ({ open, onClose, contactId, contactData }) => {
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        email: yup.string().email().required('Email is required'),
        location: yup.string().required('Location is required'),
        phone: yup.number().required('Phone is required'),
    });
    const formik = useFormik({
        initialValues: {
            email: contactData?.email || '',
            phone: contactData?.phone || '',
            location: contactData?.location || '',
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const postData = {
                    id: contactId,
                    ...values,
                };

                const response = await dispatch(updateContact(postData));

                if (response.payload.status) {
                    toast.success(response.payload.message);
                    dispatch(getContacts());
                    onClose();
                } else {
                    toast.error(response.payload.message);
                }
            } catch (error) {
                // Handle error if necessary
                console.log(error)
                toast.error("Error submitting the form.", error);
            }
        },
    });
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Edit Contacts</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="email">Contact Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter Email Address"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.email && formik.errors.email && (
                            <div className="error m-1">{formik.errors.email}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="phone">Phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phone"
                                placeholder="Enter Phone Number"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.phone && formik.errors.phone && (
                            <div className="error m-1">{formik.errors.phone}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="location">Location Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="location"
                                placeholder="Enter Location"
                                name="location"
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.location && formik.errors.location && (
                            <div className="error m-1">{formik.errors.location}</div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Edit</button>
                    <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditContact;