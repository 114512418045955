import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const updateActiveInactive = async (id) => {
  const headers = config();
  const response = await axios.put(
    `${base_url}faqs/active-inactive/${id}`,
    null,
    headers
  );

  return response.data;
};

const getFaqs = async (faq) => {
  const headers = config();
  const requestConfig = { ...headers };

  if (faq && faq.pageSize && faq.pageNumber) {
    requestConfig.params = {
      pageSize: faq.pageSize,
      pageNumber: faq.pageNumber,
    };
  }

  const response = await axios.get(`${base_url}faqs/`, requestConfig);

  return response.data;
};

const createFaq = async (faq) => {
  const headers = config();
  const { question, answer, videoLink, referenceLink } = faq;

  const faqData = {
    question,
    answer,
    ...(videoLink && { videoLink }),
    ...(referenceLink && { referenceLink }),
  };

  const response = await axios.post(`${base_url}faqs/`, faqData, headers);

  return response.data;
};

const getFaq = async (id) => {
  const headers = config();
  const response = await axios.get(`${base_url}faqs/${id}`, headers);

  return response.data;
};

const deleteFaq = async (id) => {
  const headers = config();
  const response = await axios.delete(`${base_url}faqs/${id}`, headers);

  return response.data;
};

const updateFaq = async (faq) => {
  const headers = config();
  const { question, answer, videoLink, referenceLink } = faq;

  const faqData = {
    question,
    answer,
    ...(videoLink && { videoLink }),
    ...(referenceLink && { referenceLink }),
  };

  const response = await axios.put(`${base_url}faqs/${faq.idFaq}`, faqData, headers);

  return response.data;
};

const searchFaqs = async (faq) => {
  const headers = config();
  const requestConfig = { ...headers };

  if (
    faq &&
    faq.pageSize &&
    faq.pageNumber &&
    faq.search
  ) {
    requestConfig.params = {
      pageSize: faq.pageSize,
      pageNumber: faq.pageNumber,
      search: faq.search,
    };
  }

  const response = await axios.post(`${base_url}faqs/search`, {}, requestConfig);

  return response.data;
};

const FaqService = {
  getFaqs,
  createFaq,
  getFaq,
  deleteFaq,
  updateFaq,
  updateActiveInactive,
  searchFaqs,
};

export default FaqService;
