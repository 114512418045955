import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import { getFaqs, updateActiveInactive, searchFaqs } from "../../features/faqs/faqs.slice";
import { useDispatch, useSelector } from 'react-redux';
import DefaultPagination from '../../config/Pagination';
import { toast } from "react-toastify";
import DeleteFaq from './Delete';
import AddFaq from './Add';
import EditFaq from './Edit';
import ViewFaq from './View';

const FaqsList = () => {
  // Set initial FAQ data with default page size and page number
  let faqData = {
    pageSize: DefaultPagination.PAGE_SIZE,
    pageNumber: DefaultPagination.PAGE_NUMBER,
  };

  const dispatch = useDispatch();
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewingFaqId, setViewingFaqId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingFaqId, setDeletingFaqId] = useState('');
  const [openAddFaqDialog, setOpenAddFaqDialog] = useState(false);
  const [openEditFaqDialog, setOpenEditFaqDialog] = useState(false);
  const [editingFaqId, setEditingFaqId] = useState('');

  // Use useEffect to fetch FAQs when pageSize or pageNumber changes
  useEffect(() => {
    dispatch(getFaqs(faqData));
  }, [faqData.pageSize, faqData.pageNumber]);

  // Get FAQs data from Redux store
  const { faqs } = useSelector((state) => state.faqs);

  // Function to handle view FAQ click
  const handleViewFaq = (faqId) => {
    setViewingFaqId(faqId);
    setOpenViewDialog(true);
  };

  // Function to handle change in page size dropdown
  const changeTableData = (e) => {
    faqData.pageSize = parseInt(e.target.value);
    faqData.pageNumber = DefaultPagination.PAGE_NUMBER;
    dispatch(getFaqs(faqData));
  };

  // Function to handle pagination click with a specific page number
  const paginationWithPage = (pageNumber) => {
    faqData.pageNumber = pageNumber;
    dispatch(getFaqs(faqData));
  };

  // Function to handle delete FAQ click
  const handleDeleteFaq = (faqId) => {
    setDeletingFaqId(faqId);
    setOpenDeleteDialog(true);
  };

  const handleStatusChange = async (faqId) => {
    const response = await dispatch(updateActiveInactive(faqId));

    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getFaqs(faqData));
    } else {
      toast.error(response.payload.message);
    }
  };

  // Function to handle opening the Add FAQ dialog
  const handleOpenAddFaqDialog = () => {
    setOpenAddFaqDialog(true);
  };

  // Function to handle closing the Add FAQ dialog
  const handleCloseAddFaqDialog = () => {
    setOpenAddFaqDialog(false);
  };

  // Function to handle opening the Edit FAQ dialog
  const handleOpenEditFaqDialog = (faqId) => {
    setEditingFaqId(faqId);
    setOpenEditFaqDialog(true);
  };

  // Function to handle closing the Edit FAQ dialog
  const handleCloseEditFaqDialog = () => {
    setOpenEditFaqDialog(false);
  };

  // Function to handle search as the user types in the search input field
  const handleSearchFaqs = (keyword) => {
    faqData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
    faqData.search = keyword.target.value;
    dispatch(searchFaqs(faqData));
  };
  const formatDateTime = (dateString) => {
    if (!dateString) {
      return 'Invalid Date'; // Handle null, undefined, or empty date strings
    }

    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Invalid Date'; // Handle invalid date formats
    }

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'CET',
    };

    const formatter = new Intl.DateTimeFormat('en-GB', options);

    // Format the date and replace ", " with " - "
    return formatter.format(date).replace(', ', ' - ') + ' CET';
  };


  // JSX code for rendering the component UI
  return (
    <>
      {/* Breadcrumb */}
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            FAQs
          </li>
        </ol>
      </nav>

      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">
            FAQs List
          </h1>
          <a>
            <span className="text">
              <button className="btn btn-primary" onClick={handleOpenAddFaqDialog}>+ Add FAQ</button>
            </span>
          </a>
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                {/* Page size dropdown */}
                <PageLimit changeLimit={(e) => changeTableData(e)} />

                <div className="col-sm-12 col-md-6">
                  <div id="dataTable_filter" className="dataTables_filter">
                    <label>
                      {/* Search input */}
                      <input
                        type="search"
                        placeholder="search FAQs"
                        className="form-control form-control-sm"
                        aria-controls="dataTable"
                        onChange={handleSearchFaqs} // Dynamically trigger search on input change
                      />
                    </label>
                  </div>
                </div>

                <table className="table table-bordered" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      {/* <th scope="col" width="5%">No</th> */}
                      <th scope="col">Question</th>
                      <th scope="col">Answer</th>
                      <th scope="col">Video Link</th>
                      <th scope="col">Reference Link</th>
                      <th scope="col">Status</th>
                      <th scope="col">Change Status</th>
                      <th scope="10%">created At</th>
                      <th width="10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Render FAQs data */}
                    {(faqs?.data?.faqs != undefined && faqs?.data?.faqs.length > 0) ?
                      faqs.data.faqs.map((faq, index) => {
                        return (
                          <tr key={index}>
                            {/* <td scope="row">{index + 1}</td> */}
                            <td dangerouslySetInnerHTML={{ __html: faq.question }} />
                            <td dangerouslySetInnerHTML={{ __html: faq.answer }} />
                            <td>{faq.videoLink}</td>
                            <td>{faq.referenceLink}</td>
                            <td>{faq.active ? "Active" : "Inactive"}</td>
                            <td className="tdTextCenter">
                              <div className="custom-control custom-checkbox small">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  htmlFor="customCheck"
                                  id="customCheck"
                                  name={['isChecked' + index]}
                                  checked={faq.active}
                                  onChange={() => handleStatusChange(faq.idFaq)}
                                />
                              </div>
                            </td>
                            {/* <td>{formatDateTime(faq.createdAt)}</td> */}
                            <td>{faq.createdAt ? formatDateTime(faq.createdAt) : 'N/A'}</td>

                            <td>
                              <a
                                className="actionIcon"
                                title="Edit"
                                onClick={() => handleOpenEditFaqDialog(faq.idFaq)}
                              >
                                <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                              </a>
                              <a
                                className="actionIcon"
                                title="Delete"
                                onClick={() => handleDeleteFaq(faq.idFaq)}
                              >
                                <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                              </a>
                              <a
                                className="actionIcon"
                                title="View"
                                onClick={() => handleViewFaq(faq.idFaq)} // Handle view click
                              >
                                <span><img src="assets/img/svg/eye-icon.png" alt="View" /></span>
                              </a>
                            </td>
                          </tr>
                        );
                      }) :
                      // Render "No records found" if there are no FAQs
                      <tr>
                        <td colSpan="8" className="noRecordFound">No records found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>

              {/* Pagination component */}
              <Pagination
                totalPage={faqs?.data?.pagination?.totalPages || 1}
                currentPage={faqs?.data?.pagination?.currentPage || 1}
                fullPagination={paginationWithPage}
                totalFaqs={faqs?.data?.pagination?.totalFaqs || 1}
                pageSize={faqs?.data?.pagination?.pageSize || 10}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Add FAQ dialog */}
      <AddFaq
        open={openAddFaqDialog}
        onClose={handleCloseAddFaqDialog}
        faqData={faqData}
      />

      {/* Edit FAQ dialog */}
      <EditFaq
        open={openEditFaqDialog}
        onClose={handleCloseEditFaqDialog}
        faqData={faqData}
        faqId={editingFaqId}
      />

      {/* Delete FAQ dialog */}
      <DeleteFaq
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        faqData={faqData}
        faqId={deletingFaqId}
      />

      {/* View FAQ dialog */}
      <ViewFaq
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        faqData={faqData}
        faqId={viewingFaqId}
      />
    </>
  );
}

export default FaqsList;
