import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { useFormik } from "formik";
import * as yup from "yup";
import { getUsers } from '../../features/users/users.slice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import commonFunction from '../../utils/commonFunction';

function ResetPassword({ open, onClose, userId, userData }) {

    const dispatch = useDispatch();

    const schema = yup.object().shape({
        password: yup.string().required('Password is required')
            .min(8, 'Password must be at least 8 characters long'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const response = await commonFunction.resetPassword(userId, values);
            if (response.status === true) {
                toast.success(response?.message);
                dispatch(getUsers(userData));
            } else {
                toast.error('something went wrong !!');
            }
            onClose();

            // Reset the form after successful submission
            formik.resetForm();
        },
    });

    return (
        <Dialog open={open} onClose={onClose} className="mui-modal large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Change user password</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="password">New Password</label>
                            <input
                                type="text"
                                className="form-control"
                                id="password"
                                placeholder="Enter New Password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.password && formik.errors.password && (
                            <div className="error m-1" style={{ width: '100%' }}>{formik.errors.password}</div>
                        )}
                    </div>

                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Add</button>
                    <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default ResetPassword