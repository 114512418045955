import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { requestDeleteUser } from '../../features/users/users.slice';
import commonFunction from '../../utils/commonFunction';
import PageLimit from '../Common/PageLimit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RequestDelete() {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});

    useEffect(() => {
        dispatch(requestDeleteUser());
    }, [])

    const requestDeleteData = useSelector((state) => state?.users?.requestDeleteUser);

    // Function to handle change in page size dropdown
    const changeTableData = (e) => {
        // faqData.pageSize = parseInt(e.target.value);
        // faqData.pageNumber = DefaultPagination.PAGE_NUMBER;
        // dispatch(getFaqs(faqData));
    };

    // Function to handle search as the user types in the search input field
    const handleSearch = (keyword) => {
        // faqData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
        // faqData.search = keyword.target.value;
        // dispatch(searchFaqs(faqData));
        console.log(keyword.target.value, 'keyword.target.value;');
    };


    const handleDeleteUser = async (data) => {
        setMessage({
            title: 'Account Deletion Confirmation',
            message: `Are you share you want to permanently delete this ${data?.email} user`,
            option: 'delete',
            data: data
        });
        setOpen(true);
    }

    const handleClickOpen = (item) => {
        setMessage({
            title: 'Action Reverted: Account Deletion Canceled',
            message: `Are you share you want to revert ${item?.email} user`,
            option: 'revert',
            data: item
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handelSubmit = async (message) => {
        if (message.option === "revert") {
            setOpen(false);
            await commonFunction.revertUser(message?.data?.idUser).then((res) => {
                dispatch(requestDeleteUser());
                toast.success('User reverted successfully');
            }).catch((error) => {
                console.log(error);
            })

        } else if (message.option === "delete") {
            console.log(message, 'function submitted');
            setOpen(false);
            await commonFunction.deleteUser(message?.data?.idUser).then((res) => {
                dispatch(requestDeleteUser());
                toast.success('User Deleted Successfully');
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    const formatDateTime = (dateString) => {
        if (!dateString) {
            return 'Invalid Date'; // Handle null, undefined, or empty date strings
        }
    
        const date = new Date(dateString);
        if (isNaN(date)) {
            return 'Invalid Date'; // Handle invalid date formats
        }
    
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'CET',
        };
    
        const formatter = new Intl.DateTimeFormat('en-GB', options);
    
        // Format the date and replace ", " with " - "
        return formatter.format(date).replace(', ', ' - ') + ' CET';
    };

    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Account Delete Request
                    </li>
                </ol>
            </nav>

            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Account Delete Request List
                    </h1>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                {/* Page size dropdown */}
                                <PageLimit changeLimit={(e) => changeTableData(e)} />

                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            {/* Search input */}
                                            <input
                                                type="search"
                                                placeholder="search FAQs"
                                                className="form-control form-control-sm"
                                                aria-controls="dataTable"
                                                onChange={handleSearch} // Dynamically trigger search on input change
                                            />
                                        </label>
                                    </div>
                                </div>

                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">User Email</th>
                                            <th scope="col">User name</th>
                                            <th scope="col">Mobile Number</th>
                                            <th scope="col">Requested At</th>
                                            <th scope="col">Wallet Amount</th>
                                            <th width="10%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (requestDeleteData?.data != undefined && requestDeleteData?.data.length > 0) ?
                                                requestDeleteData?.data.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                {/* <td scope="row">{index + 1}</td> */}
                                                                <td>{item.email || ''}</td>
                                                                <td>{item.firstName || ''} {item.lastName || ''}</td>
                                                                <td>{item?.mobileNumber || ''}</td>
                                                                <td>{formatDateTime(item?.updatedAt)}</td>
                                                                <td>{item?.wallet || 0}</td>
                                                                <td>
                                                                    <a
                                                                        className="actionIcon"
                                                                        title="Revert"
                                                                        onClick={() => handleClickOpen(item)}
                                                                    >
                                                                        <span><img src="assets/img/svg/EditIcon.png" alt="Revert" /></span>
                                                                    </a>
                                                                    <a
                                                                        className="actionIcon"
                                                                        title="Delete"
                                                                        onClick={() => handleDeleteUser(item)}
                                                                    >
                                                                        <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                                    </a>
                                                                </td>
                                                            </tr >
                                                        </>
                                                    );
                                                }) :
                                                // Render "No records found" if there are no Schools
                                                <tr>
                                                    <td colSpan="8" className="noRecordFound">No records found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination component */}
                            {/* <Pagination
                                totalPage={faqs?.data?.pagination?.totalPages}
                                currentPage={faqs?.data?.pagination?.currentPage}
                                fullPagination={paginationWithPage}
                            /> */}
                        </div>
                    </div>
                </div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{message?.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {message?.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handelSubmit(message) }}>Agree</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default RequestDelete