import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import InqueryService from "./inqueries.services";

export const getInqueries = createAsyncThunk(
    "inqueries/get-inqueries",
    async (inqueryData, thunkAPI) => {
        try {
            return await InqueryService.getInqueries(inqueryData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const deleteInquery = createAsyncThunk(
    "faqs/delete-inquery",
    async (id, thunkAPI) => {
        try {
            return await InqueryService.deleteInquery(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

const initialState = {
    inqueries: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};
export const inqueryslice = createSlice({
    name: "inqueries",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInqueries.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getInqueries.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.inqueries = action.payload;
            })
            .addCase(getInqueries.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteInquery.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteInquery.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.deletedFaq = action.payload.message;
            })
            .addCase(deleteInquery.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
    },
})
export default inqueryslice.reducer;