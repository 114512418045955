import React, { useEffect, useState } from 'react'
import { json, Link } from 'react-router-dom'
import PageLimit from '../Common/PageLimit';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionList } from '../../features/transactions/transaction.slice';
import DefaultPagination from '../../config/Pagination';
import Pagination from '../Common/Pagination';
import { base_url } from '../../utils/baseUrl';
import axios from 'axios';
import { config } from '../../utils/axiosconfig';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    status: Yup.string().required("Status is required"),
    refundAmount: Yup.number()
        .min(0, "Refund amount is not valid")
        .max(Yup.ref('maxAmount'), "Refund amount cannot exceed available amount")
        .required("Refund amount is required")
});
function TransactionList() {

    let transactionData = {
        search: '',
        pageNumber: DefaultPagination.PAGE_NUMBER,
        pageSize: DefaultPagination.PAGE_SIZE,
    };
    const dispatch = useDispatch();
    // State for the modal and fields
    const [modalOpen, setModalOpen] = useState(false);
    const [transactionUserId, setTransactionUserId] = useState(null);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [newStatus, setNewStatus] = useState('refund');
    const [refundAmount, setRefundAmount] = useState('');
    const [Id, setId] = useState('');

    // Use useEffect to fetch transaction when pageSize or pageNumber changes
    useEffect(() => {
        if (transactions.result === undefined) {
            dispatch(getTransactionList(transactionData));
        }
    }, [transactionData.pageNumber, transactionData.pageSize]);

    // Get transactions data from Redux store
    const transactions = useSelector((state) => state?.transaction?.transaction);
    // Open modal with transaction details
    const openModal = (transaction) => {
        setTransactionUserId(transaction.idUser);
        setSelectedTransaction(transaction)
        // setNewStatus(transaction.status);
        setRefundAmount(transaction.amount);
        setId(transaction.id);
        setModalOpen(true);
    };
    // Close modal
    const closeModal = () => {
        setModalOpen(false);
        setTransactionUserId(null);
        setNewStatus('');
        setRefundAmount('');
    };
    // Function to handle change in page size dropdown
    const changeTableData = (e) => {
        transactionData.pageSize = parseInt(e.target.value);
        transactionData.pageNumber = DefaultPagination.PAGE_NUMBER;
        dispatch(getTransactionList(transactionData));
    };

    // Function to handle search as the user types in the search input field
    const handleSearch = (keyword) => {
        transactionData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
        transactionData.search = keyword.target.value;
        dispatch(getTransactionList(transactionData));
    };

    // Function to handle pagination click with a specific page number
    const paginationWithPage = (pageNumber) => {
        transactionData.pageNumber = pageNumber;
        dispatch(getTransactionList(transactionData));
    };

    const formatDateTime = (dateString) => {
        if (!dateString) {
            return 'Invalid Date'; // Handle null, undefined, or empty date strings
        }

        const date = new Date(dateString);
        if (isNaN(date)) {
            return 'Invalid Date'; // Handle invalid date formats
        }

        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Europe/Paris', // CET/CEST time zone handling
            timeZoneName: 'short',    // Display short timezone name (CET or CEST)
        };

        const formatter = new Intl.DateTimeFormat('en-GB', options);
        let formattedDate = formatter.format(date);

        // Replace the comma with a hyphen
        formattedDate = formattedDate.replace(',', ' -');

        return formattedDate;
    };
    // Function to handle status change and refund update
    const handleStatusChange = async (values) => {
        if (!transactionUserId) return;

        try {
            const headers = config();
            const payload = new URLSearchParams();
            payload.append('status', values.status);
            payload.append('idUser', transactionUserId);
            payload.append('amount', values.refundAmount);

            const response = await axios.patch(
                `${base_url}updateTransactionStatus/${Id}`,
                payload,
                {
                    ...headers,
                    headers: {
                        ...headers.headers,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            dispatch(getTransactionList(transactionData));
            closeModal();
        } catch (error) {
            console.error("Error updating transaction status:", error.response?.data || error.message);
        }
    };


    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Transactions
                    </li>
                </ol>
            </nav>

            {/* Modal for editing transaction status and refund */}
            {modalOpen && transactionUserId && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Update Transaction</h5>
                                <button type="button" className="close" onClick={closeModal}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Formik
                                    initialValues={{
                                        status: 'refund',
                                        refundAmount: selectedTransaction.amount,
                                        maxAmount: selectedTransaction.amount,
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleStatusChange}
                                >
                                    {({ values, setFieldValue, errors, touched }) => (
                                        <Form>
                                            {/* Status Field - Only "Refund" option */}
                                            <div className="form-group">
                                                <label>Status</label>
                                                <Field
                                                    as="select"
                                                    name="status"
                                                    className="form-control"
                                                    value="refund"
                                                    disabled
                                                >
                                                    <option value="refund">Refund</option>
                                                </Field>
                                                <ErrorMessage name="status" component="div" className="text-danger" />
                                            </div>

                                            {/* Refund Amount Field */}
                                            <div className="form-group">
                                                <label>Refund Amount</label>
                                                <Field
                                                    type="number"
                                                    name="refundAmount"
                                                    className="form-control"
                                                    min="0"
                                                />
                                                <ErrorMessage name="refundAmount" component="div" className="text-danger" />
                                            </div>

                                            {/* Submit Button */}
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                                                <button type="submit" className="btn btn-primary">Save changes</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {/* Table with transaction list */}
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Transaction List
                    </h1>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                {/* Page size dropdown */}
                                <PageLimit changeLimit={(e) => changeTableData(e)} />
                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            {/* Search input */}
                                            <input
                                                type="search"
                                                placeholder="search Classes"
                                                className="form-control form-control-sm"
                                                aria-controls="dataTable"
                                                onChange={handleSearch}
                                            />
                                        </label>
                                    </div>
                                </div>

                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">Transaction Id</th>
                                            <th scope="col">Transaction By</th>
                                            <th scope="col">Currency</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactions?.result?.map((item, index) => (
                                            <tr key={index}>
                                                {/* <td>{index + 1}</td> */}
                                                <td>{item?.transactionId || ''}</td>
                                                <td>{item?.User?.email || ''}</td>
                                                <td>DKK</td>
                                                <td>{item?.amount || ''}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className={`btn ${item.status === 'cancel' || item.status === 'rejected' ? 'btn-danger' :
                                                            item.status === 'processed' || item.status === '1' || item.status === 'completed' ? 'btn-success' :
                                                                item.status === 'initial' ? 'btn-warning' :
                                                                    item.status === 'refund' ? 'btn-info' : ''
                                                            }`}
                                                    >
                                                        {item.status === 'cancel' ? 'Cancel' :
                                                            item.status === 'rejected' ? 'Rejected' :
                                                                ['completed', '1', 'processed'].includes(item.status) ? 'Processed' :
                                                                    item.status === 'refund' ? 'Refund' :
                                                                        item.status === 'initial' ? 'Initial' :
                                                                            'Unknown'}
                                                    </button>

                                                    {/* Add undo icon to open the modal */}
                                                    {['completed', 'processed', '1'].includes(item.status) && (
                                                        <i
                                                            className="fa fa-undo ml-2"
                                                            aria-hidden="true"
                                                            style={{ cursor: 'pointer', color: '#158ddb' }}
                                                            onClick={() => openModal(item)}
                                                            title="Click for refund"
                                                        ></i>
                                                    )}
                                                </td>
                                                <td>{item.createdAt ? formatDateTime(item.createdAt) : 'N/A'}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* Pagination component */}
                            <Pagination
                                totalPage={transactions?.pagination?.totalPages || 1}
                                currentPage={transactions?.pagination?.currentPage || 1}
                                fullPagination={paginationWithPage}
                                totalFaqs={transactions?.pagination?.totalTransaction || 1}
                                pageSize={transactions?.pagination?.pageSize || 10}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionList