import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ContactService from "./contact.services";

export const getContacts = createAsyncThunk("contact/get-contacts",
    async (thunkAPI) => {
        try {
            return await ContactService.getContacts();
        } catch (error) {
            return await error.response.data;
        }
    }
)
export const updateContact = createAsyncThunk(
    "contact/update-contact",
    async (contactData, thunkAPI) => {

        try {
            return await ContactService.updateContact(contactData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

const initialState = {
    contacts: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const contactslice = createSlice({
    name: "contacts",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getContacts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getContacts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.contacts = action.payload;
            })
            .addCase(getContacts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateContact.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateContact.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedContact = action.payload;
            })
            .addCase(updateContact.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
    },
})
export default contactslice.reducer;