import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
// import { config } from "../../utils/axiosconfig";

const getUsers = async (user) => {
    const headers = config();
    const requestConfig = { ...headers };

    if (user && user.pageSize && user.pageNumber) {
        requestConfig.params = {
            pageSize: user.pageSize,
            pageNumber: user.pageNumber,
            isactive: user.isactive
        };
    }

    const response = await axios.get(`${base_url}users`, requestConfig);

    return response.data;
};

const searchUsers = async (user) => {
    const headers = config();
    const requestConfig = { ...headers };

    if (user && user.pageSize && user.pageNumber && user.search) {
        requestConfig.params = {
            pageSize: user.pageSize,
            pageNumber: user.pageNumber,
            isactive: user.isactive,
            search: user.search,
        };
    }

    const response = await axios.post(`${base_url}users`, {}, requestConfig);

    return response.data;
};

const blockUnblockUser = async (id) => {
    const headers = config();

    const response = await axios.put(`${base_url}users/block-unblock/${id}`, null, headers);

    return response.data;
};

const getUserStats = async () => {
    const headers = config();
    const response = await axios.get(`${base_url}users/stats`, headers);

    return response.data;
};

const deleteUser = async (id) => {
    const headers = config();
    const requestConfig = { ...headers };
    const requestData = { idUser :id }
    // const response = await axios.put(`${base_url}users/changeStatusUser/${id}`, requestData, requestConfig);
    const response = await axios.post(`${base_url}users/deleteUserPermanent `, requestData, requestConfig);
    // const response = {
    //     data: 'delete success',
    //     status: 200
    // };
    return response.data;
};


const requestDeleteUser = async () => {
    const headers = config();
    const response = await axios.get(`${base_url}users/reqdelete`, headers);

    return response.data;
};

const getAllDeletedUsers = async (user) => {
    const headers = config();
    const requestConfig = { ...headers };

    if (user) {
        requestConfig.params = {
            pageSize: user.pageSize,
            pageNumber: user.pageNumber,
            isactive: user.isactive,
            req_delete: user.req_delete,
            sortby: user.sortby || 'updatedAt', // Default sort by `updatedAt`
        };
    }

    const response = await axios.get(`${base_url}users/`, requestConfig);

    return response.data;
};

const getActiveUsersCount = async () => {
    const headers = config();
    const response = await axios.get(`${base_url}users/getloginUsercount`, headers);

    return response.data;
};

const UserService = {
    getUsers,
    searchUsers,
    blockUnblockUser,
    getUserStats,
    deleteUser,
    requestDeleteUser,
    getAllDeletedUsers,
    getActiveUsersCount,
};

export default UserService;