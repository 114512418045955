import { Autocomplete, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material'
import { Space, TimePicker } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import * as yup from "yup";

function EditSchedule({ open, onClose, scheduleData }) {
    const [value, setValue] = useState(null);

    const handleDeletePlan = (id) => {
        alert(scheduleData.id, 'id');
    }

    // Get Schools data from Redux store
    const { schools } = useSelector((state) => state.schools);
    const schoolsWithEmails = schools?.school ? schools?.school.filter(school => school.email) : [];

    const schema = yup.object().shape({
        selectedEmails: yup.array().min(1, "At least one email is required"),
        dayChecked: yup.array().of(yup.string()).min(1, "At least one day is required"),
        schedule_time: yup.string().required('Enter valid time')
    });

    const formik = useFormik({
        initialValues: {
            dayChecked: scheduleData?.dayChecked?.split(',') || [],  // Convert string to array
            schedule_time: scheduleData?.schedule_time || '',
            today: scheduleData?.today?.toString() || '',  // Convert boolean to string
            tomorrow: scheduleData?.tomorrow?.toString() || '',  // Convert boolean to string
            selectedEmails: [scheduleData?.selectedEmails] || [],  // Wrap in array
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            console.log(values, 'values');
        },
    });

    return (
        <>
            <Dialog open={open} className="mui-modal large-dialog">
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <div className="form-group">
                            <div className="form-floating">
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    Schedule printing
                                </Typography>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-floating">
                                <Typography variant="subtitle1" gutterBottom>
                                    Get the print list sent to your inbox every day
                                </Typography>
                            </div>
                        </div>
                        {/* Time input start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <Space wrap>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Print is sent every day at:
                                    </Typography>
                                    <TimePicker value={value} onChange={setValue} name='schedule_time' format="HH:mm" />
                                </Space>
                                {formik.touched.schedule_time && formik.errors.schedule_time && (
                                    <div className="error m-1">{formik.errors.schedule_time}</div>
                                )}
                            </div>
                        </div>
                        {/* Time input end */}
                        <div className="form-group">
                            <div className="form-floating">
                                <Typography>
                                    <span className='error'>*</span> Send the print with data for
                                </Typography>
                            </div>
                        </div>
                        {/* Day Input start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <Typography>
                                    <button
                                        type='button'
                                        className={'btn ' + (formik.values.today === 'true' ? 'btn-primary' : 'border-dark')}
                                        style={{ 'borderRadius': '0px' }}
                                        onClick={() => {
                                            formik.setValues({ ...formik.values, today: 'true', tomorrow: 'false' });
                                        }}
                                    >
                                        Today
                                    </button>
                                    <button
                                        type='button'
                                        className={'btn ' + (formik.values.tomorrow === 'true' ? 'btn-primary' : 'border-dark')}
                                        style={{ 'borderRadius': '0px' }}
                                        onClick={() => {
                                            formik.setValues({ ...formik.values, today: 'false', tomorrow: 'true' });
                                        }}
                                    >
                                        Tomorrow
                                    </button>
                                </Typography>
                            </div>
                        </div>
                        {/* Day Input end */}
                        {/* Weekly input day start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="select-size justify-content-between">

                                            {['M', 'T', 'W', 'TH', 'F', 'S', 'SU'].map((day) => (
                                                <React.Fragment key={day}>
                                                    <input
                                                        type="checkbox"
                                                        name="dayChecked"
                                                        value={day}
                                                        id={day.toLowerCase()}
                                                        checked={formik.values.dayChecked.includes(day)}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <label htmlFor={day.toLowerCase()} className="rounded-circle">
                                                        {day}
                                                    </label>
                                                </React.Fragment>
                                            ))}
                                            {formik.touched.dayChecked && formik.errors.dayChecked && (
                                                <div className="error m-1">{formik.errors.dayChecked}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Weekly input day end */}
                        {/* email input start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <div>
                                    <span className='error'>*</span> Email Address printed to ?
                                    <div className="form-group">

                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={schoolsWithEmails ? schoolsWithEmails : []}
                                            getOptionLabel={(option) => option.email}
                                            defaultValue={[schoolsWithEmails ? schoolsWithEmails[3] : '']}
                                            // value={selectedIngredients}
                                            // onChange={(_, newValue) => {
                                            //     const emailArray = newValue.map(schoolsWithEmails => schoolsWithEmails.email);
                                            //     setSelectedIngredients(newValue);
                                            //     formik.setFieldValue('selectedEmails', emailArray);
                                            // }}
                                            // isOptionEqualToValue={(option, value) => option.schoolName === value.schoolName}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select Email"
                                                />
                                            )}
                                        />
                                        {/* {formik.touched.selectedEmails && formik.errors.selectedEmails && (
                                            <div className="error m-1">{formik.errors.selectedEmails}</div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* email input start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <DialogActions style={{ float: "left" }}>
                                    <button className="btn btn-danger" type='button' onClick={handleDeletePlan}>Delete the Plan</button>
                                </DialogActions>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                        <button className="btn btn-primary" type='submit'>Cofirm</button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default EditSchedule