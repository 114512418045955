import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { createFaq, getFaqs } from "../../features/faqs/faqs.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const schema = yup.object().shape({
  question: yup.string().required('Question is required'),
  answer: yup.string().required('Answer is required'),
});

const AddFaq = ({ open, onClose, faqData }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      question: '',
      answer: '',
      videoLink: '',
      referenceLink: '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const response = await dispatch(createFaq(values));

        if (response.payload.status) {
          toast.success(response.payload.message);
          dispatch(getFaqs(faqData));
          onClose();

          // Reset the form after successful submission
          formik.resetForm();
        } else {
          toast.error(response.payload.message);
        }
      } catch (error) {
        // Handle error if necessary
        toast.error("Error submitting the form.");
      }
    },
  });

  const handleQuestionChange = (content) => {
    formik.setFieldValue('question', content);
  };

  const handleAnswerChange = (content) => {
    formik.setFieldValue('answer', content);
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Add FAQ</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="question">Question</label>
              <ReactQuill
                value={formik.values.question}
                onChange={handleQuestionChange}
                theme="snow" // or "bubble"
                modules={{
                  toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                  ],
                }}
              />
            </div>
            {formik.touched.question && formik.errors.question && (
              <div className="error m-1">{formik.errors.question}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="answer">Answer</label>
              <ReactQuill
                value={formik.values.answer}
                onChange={handleAnswerChange}
                theme="snow" // or "bubble"
                modules={{
                  toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                  ],
                }}
              />
            </div>
            {formik.touched.answer && formik.errors.answer && (
              <div className="error m-1">{formik.errors.answer}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="videoLink">Video Link (Optional)</label>
              <input
                type="text"
                className="form-control"
                id="videoLink"
                placeholder="Enter video link"
                name="videoLink"
                value={formik.values.videoLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="on"
              />
            </div>
            {formik.touched.videoLink && formik.errors.videoLink && (
              <div className="error m-1">{formik.errors.videoLink}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="referenceLink">Reference Link (Optional)</label>
              <input
                type="text"
                className="form-control"
                id="referenceLink"
                placeholder="Enter reference link"
                name="referenceLink"
                value={formik.values.referenceLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="on"
              />
            </div>
            {formik.touched.referenceLink && formik.errors.referenceLink && (
              <div className="error m-1">{formik.errors.referenceLink}</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" type="submit">Add</button>
          <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddFaq;
