import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getHotMeals = async (hotMealData) => {
    const headers = config();
    const requestConfig = { ...headers };

    if (hotMealData && hotMealData.pageSize && hotMealData.pageNumber) {
        requestConfig.params = {
            pageSize: hotMealData.pageSize,
            pageNumber: hotMealData.pageNumber,
            search: hotMealData.search ? hotMealData.search : '',
        };
    }

    const response = await axios.get(`${base_url}getHotMeal/`, requestConfig);

    return response.data;
};

// const getActiveMenus = async () => {
//     const response = await axios.get(`${base_url}menus/active`, config);

//     return response.data;
// };

const createHotMeals = async (meal) => {
    const headers = config();
    const response = await axios.post(`${base_url}addHotMeal/`, meal, headers);

    return response.data;
};

const getMeal = async (id) => {
    const headers = config();
    const response = await axios.get(`${base_url}getHotMealById/${id}`, headers);

    return response.data;
};

const deleteMenu = async (id) => {
    const headers = config();
    const response = await axios.delete(`${base_url}deleteHotMeal/${id}`, headers);

    return response.data;
};

const updateMeal = async (hotMealData) => {
    const headers = config();
    const { hotMealDay, menuId } = hotMealData;

    const updateMealData = {
        hotMealDay,
        menuId,
    };

    const response = await axios.patch(`${base_url}updateHotMeal/${hotMealData.id}`, updateMealData, headers);

    return response.data;
}

// const updateMenu = async (menu) => {
//     const response = await axios.put(
//         `${base_url}menus/${menu.idMenu}`,
//         {
//             title: menu.title,
//             description: menu.description,
//             imageUrl: menu.imageUrl,
//             categoryId: menu.categoryId,
//             ingredientIds: menu.ingredientIds,
//             price: menu.price
//         },
//         config
//     );
//     return response.data;
// };

// const searchMenus = async (menu) => {
//     const requestConfig = { ...config };

//     if (menu && menu.pageSize && menu.pageNumber || menu.search) {
//         requestConfig.params = {
//             search: menu.search,
//             pageSize: menu.pageSize,
//             pageNumber: menu.pageNumber
//         };
//     }

//     const response = await axios.get(`${base_url}getMenus`, requestConfig);

//     return response.data;
// };

const updateActiveInactive = async (data) => {
    const headers = config();
    let { mealId, status } = data
    status = status === 1 ? 0 : 1;

    const updateMealData = {
        status: status
    }
    const response = await axios.patch(`${base_url}updateHotMeal/${mealId}`, updateMealData, headers);

    return response.data;
};

const HotmealService = {
    getHotMeals,
    createHotMeals,
    deleteMenu,
    getMeal,
    updateMeal,
    updateActiveInactive,
};

export default HotmealService;
