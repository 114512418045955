import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import CategoryService from "./categories.services";

export const getCategories = createAsyncThunk(
  "categories/get-categories",
  async (categoryData, thunkAPI) => {
    try {
      return await CategoryService.getCategories(categoryData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const getActiveCategories = createAsyncThunk(
  "categories/get-active-categories",
  async (categoryData, thunkAPI) => {
    try {
      return await CategoryService.getActiveCategories(categoryData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const createCategory = createAsyncThunk(
  "categories/create-category",
  async (categoryData, thunkAPI) => {
    try {
      return await CategoryService.createCategory(categoryData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/update-category",
  async (category, thunkAPI) => {
    try {
      return await CategoryService.updateCategory(category);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateActiveInactive = createAsyncThunk(
  "categories/update-active-inactive",
  async (data, thunkAPI) => {
    try {
      return await CategoryService.updateActiveInactive(data);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/delete-category",
  async (id, thunkAPI) => {
    try {
      return await CategoryService.deleteCategory(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const getCategory = createAsyncThunk(
  "categories/get-product-category",
  async (id, thunkAPI) => {
    try {
      return await CategoryService.getCategory(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const searchCategories = createAsyncThunk(
  "categories/search-categories",
  async (category, thunkAPI) => {
    try {
      return await CategoryService.searchCategories(category);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const resetState = createAction("RevertAll");

const initialState = {
  categories: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const CategorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdCategory = action.payload;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedCategory = action.payload;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.deletedCategory = action.payload.message;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.getCategory = action.payload.data;
        state.categoryName = action.payload.data.title;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateActiveInactive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateActiveInactive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload.message

      })
      .addCase(updateActiveInactive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(searchCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.categories = action.payload
        state.message = action.payload.message

      })
      .addCase(searchCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getActiveCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActiveCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.categories = action.payload
        state.message = action.payload.message

      })
      .addCase(getActiveCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default CategorySlice.reducer;
