import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { deleteMenu, getHotmeals } from '../../features/hotmeals/hotmeals.slice';
import { toast } from 'react-toastify';

const DeleteMeal = ({ open, onClose, mealId, hotMealData }) => {

    const dispatch = useDispatch();

    const handleConfirmDeleteMenu = async () => {
        onClose();
        const response = await dispatch(deleteMenu(mealId));

        if (response.payload.status) {
            toast.success(response.payload.message);
            dispatch(getHotmeals(hotMealData));

        } else {
            toast.error(response.payload.message);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} className="mui-modal">
            <DialogTitle>Delete Menu</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this menu?
            </DialogContent>
            <DialogActions>
                <button className="btn btn-primary" onClick={handleConfirmDeleteMenu}>Delete</button>
                <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteMeal;