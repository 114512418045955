import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/auth/auth.slice";
import { checkAuthLoader } from "../../utils/auth";
import { toast } from "react-toastify";

let schema = yup.object().shape({
  email: yup
    .string()
    .email("Email should be valid!")
    .required("Email is Required!"),
  password: yup
    .string()
    .required("Password is Required!"),
});

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = checkAuthLoader()?.status;
  const [userRes, setUserRes] = useState('');

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const response = await dispatch(login(values));

      if (response.payload.status) {
        toast.success(response.payload.message);
        setUserRes(response.payload);
      } else {
        toast.error(response.payload.message);
      }
    },
  });

  const authState = useSelector((state) => state);

  const { user, isError, isSuccess, isLoading, message } = authState.auth;

  useEffect(() => {
    if (status === true || isSuccess) {
      navigate("admin");
    } else {
      navigate("");
    }
  }, [isSuccess, userRes]);


  return (
    <div className="content">
      <div className="container">
        <div className="row bx-shadow p-4">
          <div className="col-md-6">
            <img src="undraw_remotely_2j6y.svg" alt="Image" className="img-fluid" />
          </div>
          <div className="col-md-6 contents">
            <div className="row justify-content-center">
              <div className="col-md-8">

                <div className="avatar">
                  <i className="fas fa-user theme-color"></i>
                </div>

                <div className="mb-3">
                  <h3>Sign In</h3>
                  <p>Login to your account to continue.</p>
                  <div className="error">
                    {message.status ? "" : message.message}
                  </div>
                </div>

                <form action="" onSubmit={formik.handleSubmit}>
                  <div className="form-group">

                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter registered email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange("email")}
                        onBlur={formik.handleBlur("email")}
                        autoComplete="on"
                      />

                    </div>
                  </div>

                  <div className="error m-1">
                    {formik.touched.email && formik.errors.email}
                  </div>

                  <div className="form-group mt-2">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter your password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange("password")}
                      onBlur={formik.handleBlur("password")}
                      autoComplete="on"
                    />

                  </div>

                  <div className="error m-1">
                    {formik.touched.password && formik.errors.password}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block btn-primary w-100 f-500 mt-2"
                  >
                    Login
                  </button>

                </form>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Login;