import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import DefaultPagination from '../../config/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getSchoolList, updateActiveInactive } from '../../features/schools/schools.slice';
import ViewSchool from './View';
import DeleteSchool from './Delete';
import EditSchool from './Edit';
import AddSchool from './Add';
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';

export const SchoolList = () => {
    let schoolData = {
        pageNumber: DefaultPagination.PAGE_NUMBER,
        pageSize: DefaultPagination.PAGE_SIZE,
    };
    const dispatch = useDispatch();
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewingSchoolId, setViewingSchoolId] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletingSchoolId, setDeletingSchoolId] = useState('');
    const [openEditSchoolDialog, setOpenEditSchoolDialog] = useState(false);
    const [editingSchoolId, setEditingSchoolId] = useState('');
    const [openAddSchoolDialog, setOpenAddSchoolDialog] = useState(false);

    // Use useEffect to fetch Schools when pageSize or pageNumber changes
    useEffect(() => {
        dispatch(getSchoolList(schoolData));
    }, [schoolData.pageNumber, schoolData.pageSize]);

    // Get Schools data from Redux store

    const { schools } = useSelector((state) => state.schools);

    // Function to handle search as the user types in the search input field
    const handleSearchSchool = (keyword) => {
        schoolData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
        schoolData.search = keyword.target.value;
        dispatch(getSchoolList(schoolData));
    };

    // Function to change the School Status
    const handleStatusChange = async (id, schoolStatus) => {

        const response = await dispatch(updateActiveInactive({ id, schoolStatus }));

        if (response.payload.status) {
            toast.success(response.payload.message);
            dispatch(getSchoolList(schoolData));
        } else {
            toast.error(response.payload.message);
        }
    }

    // Open view modal
    const handleViewSchool = (id) => {
        setViewingSchoolId(id);
        setOpenViewDialog(true);
    }

    // Function to handle delete School click
    const handleDeleteSchool = (id) => {
        setDeletingSchoolId(id);
        setOpenDeleteDialog(true);
    };

    // Function to handle closing the Edit School dialog
    const handleOpenEditSchoolDialog = (id) => {
        setEditingSchoolId(id);
        setOpenEditSchoolDialog(true);
    }

    // Function to handle opening the Add School dialog
    const handleOpenAddSchoolDialog = () => {
        setOpenAddSchoolDialog(true);
    };

    // Function to handle closing the Add School dialog
    const handleCloseAddSchoolDialog = () => {
        setOpenAddSchoolDialog(false);
    };

    // Function to handle change in page size dropdown
    const changeTableData = (e) => {
        schoolData.pageSize = parseInt(e.target.value);
        schoolData.pageNumber = DefaultPagination.PAGE_NUMBER;
        dispatch(getSchoolList(schoolData));
    };

    // Function to handle pagination click with a specific page number
    const paginationWithPage = (pageNumber) => {
        schoolData.pageNumber = pageNumber;
        dispatch(getSchoolList(schoolData));
    };

    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        School
                    </li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Schools List
                    </h1>
                    <a>
                        <span className="text">
                            <button className="btn btn-primary" onClick={handleOpenAddSchoolDialog}>+ Add School</button>
                        </span>
                    </a>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                {/* Page size dropdown */}
                                <PageLimit changeLimit={(e) => changeTableData(e)} />
                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            {/* Search input */}
                                            <input
                                                type="search"
                                                placeholder="search Inqueries"
                                                className="form-control form-control-sm"
                                                aria-controls="dataTable"
                                                onChange={handleSearchSchool} // Dynamically trigger search on input change
                                            />
                                        </label>
                                    </div>
                                </div>

                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">School Name</th>
                                            <th scope="col">School Address</th>
                                            <th scope="col">School Email</th>
                                            {/* <th scope="col">Status</th> */}
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Render Inquiries data */}
                                        {
                                            (schools?.school != undefined && schools?.school.length > 0) ?
                                                schools?.school.map((school, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td scope="row">{index + 1}</td> */}
                                                            <td>{school.schoolName}</td>
                                                            <td>{school.schoolAddress}</td>
                                                            <td>{school.email}</td>
                                                            {/* <td>{school.status ? "Active" : "Inactive"}</td> */}
                                                            {/* <td className="tdTextCenter">
                                                                <div className="custom-control custom-checkbox small">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        htmlFor="customCheck"
                                                                        id="customCheck"
                                                                        name={['isChecked' + index]}
                                                                        checked={school.status}
                                                                        onChange={() => handleStatusChange(school.id, school.status)}
                                                                    />
                                                                </div>
                                                            </td> */}
                                                            <td className="tdTextCenter">
                                                                <div className="custom-control custom-switch small">
                                                                    <Switch
                                                                        id={`customSwitch${index}`}
                                                                        checked={school.status}
                                                                        onChange={() => handleStatusChange(school.id, school.status)}
                                                                    />
                                                                </div>
                                                            </td>


                                                            <td>
                                                                <a
                                                                    className="actionIcon"
                                                                    title="Edit"
                                                                    onClick={() => handleOpenEditSchoolDialog(school.id)}
                                                                >
                                                                    <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                                                </a>
                                                                <a
                                                                    className="actionIcon"
                                                                    title="Delete"
                                                                    onClick={() => handleDeleteSchool(school.id)}
                                                                >
                                                                    <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                                </a>
                                                                <a
                                                                    className="actionIcon"
                                                                    title="View"
                                                                    onClick={() => handleViewSchool(school.id)} // Handle view click
                                                                >
                                                                    <span><img src="assets/img/svg/eye-icon.png" alt="View" /></span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) :
                                                // Render "No records found" if there are no Schools
                                                <tr>
                                                    <td colSpan="8" className="noRecordFound">No records found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* Pagination component */}
                            <Pagination
                                totalPage={schools?.pagination?.totalPages || 1}
                                currentPage={schools?.pagination?.currentPage || 1}
                                fullPagination={paginationWithPage}
                                totalFaqs={schools?.pagination?.totalMenus || 1}
                                pageSize={schools?.pagination?.pageSize || 10}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit School dialog */}
            <EditSchool
                open={openEditSchoolDialog}
                onClose={() => setOpenEditSchoolDialog(false)}
                schoolData={schoolData}
                schoolId={editingSchoolId}
            />

            {/* View School dialog */}
            <ViewSchool
                open={openViewDialog}
                onClose={() => setOpenViewDialog(false)}
                // schoolData={schoolData}
                schoolId={viewingSchoolId}
            />

            {/* Delete School dialog */}
            <DeleteSchool
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                schoolId={deletingSchoolId}
                schoolData={schoolData}
            />
            {/* Add School dialog */}
            <AddSchool
                open={openAddSchoolDialog}
                onClose={handleCloseAddSchoolDialog}
                schoolData={schoolData}
            />
        </>
    )
}

export default SchoolList;