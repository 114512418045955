import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const login = async (user) => {

  const response = await axios.post(`${base_url}auth/login`, user);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const currentUser = async () => {
  const headers = config();
  const response = await axios.get(`${base_url}auth/current`, headers);
  return response.data;
};

const updateProfilePic = async (user) => {
  const headers = config();
  const response = await axios.put(`${base_url}auth/update-profile-pic`, user, headers);
  return response.data;
};

const updatePassword = async (user) => {
  const headers = config();
  const userData = {
    oldPassword: user.oldPassword,
    newPassword: user.newPassword
  }
  const response = await axios.put(`${base_url}auth/update-password`, userData, headers);
  return response.data;
};

const updateProfile = async (user) => {
  const headers = config();
  const userData = {
    firstName: user.firstName,
    lastName: user.lastName
  }
  const response = await axios.put(`${base_url}auth/update-info`, userData, headers);
  return response.data;
};

const authService = {
  login,
  currentUser,
  updateProfile,
  updateProfilePic,
  updatePassword
};

export default authService;
