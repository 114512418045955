import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import { getIngredients, updateActiveInactive, searchIngredients } from "../../features/ingredients/ingredients.slice";
import { useDispatch, useSelector } from 'react-redux';
import DefaultPagination from '../../config/Pagination';
import { toast } from "react-toastify";
import DeleteIngredient from './Delete';
import AddIngredient from './Add';
import EditIngredient from './Edit';

const IngredientsList = () => {
  // Set initial ingredient data with default page size and page number
  let ingredientData = {
    pageSize: DefaultPagination.PAGE_SIZE,
    pageNumber: DefaultPagination.PAGE_NUMBER,
  };

  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingIngredientId, setDeletingIngredientId] = useState('');
  const [openAddIngredientDialog, setOpenAddIngredientDialog] = useState(false);
  const [openEditIngredientDialog, setOpenEditIngredientDialog] = useState(false);
  const [editingIngredientId, setEditingIngredientId] = useState('');

  // Use useEffect to fetch ingredients when pageSize or pageNumber changes
  useEffect(() => {
    dispatch(getIngredients(ingredientData));
  }, [ingredientData.pageSize, ingredientData.pageNumber]);

  // Get ingredients data from Redux store
  const { ingredients } = useSelector((state) => state.ingredients);

  // Function to handle change in page size dropdown
  const changeTableData = (e) => {
    ingredientData.pageSize = parseInt(e.target.value);
    ingredientData.pageNumber = DefaultPagination.PAGE_NUMBER;
    dispatch(getIngredients(ingredientData));
  };

  // Function to handle pagination click with a specific page number
  const paginationWithPage = (pageNumber) => {
    ingredientData.pageNumber = pageNumber;
    dispatch(getIngredients(ingredientData));
  };

  // Function to handle delete ingredient click
  const handleDeleteIngredient = (ingredientId) => {
    setDeletingIngredientId(ingredientId);
    setOpenDeleteDialog(true);
  };

  const handleStatusChange = async (ingredientId) => {
    const response = await dispatch(updateActiveInactive(ingredientId));

    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getIngredients(ingredientData));
    } else {
      toast.error(response.payload.message);
    }
  };

  // Function to handle opening the Add Ingredient dialog
  const handleOpenAddIngredientDialog = () => {
    setOpenAddIngredientDialog(true);
  };

  // Function to handle closing the Add Ingredient dialog
  const handleCloseAddIngredientDialog = () => {
    setOpenAddIngredientDialog(false);
  };

  // Function to handle opening the Edit Ingredient dialog
  const handleOpenEditIngredientDialog = (ingredientId) => {
    setEditingIngredientId(ingredientId);
    setOpenEditIngredientDialog(true);
  };

  // Function to handle closing the Edit Ingredient dialog
  const handleCloseEditIngredientDialog = () => {
    setOpenEditIngredientDialog(false);
  };

  // Function to handle search as the user types in the search input field
  const handleSearchIngredients = (keyword) => {
    ingredientData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
    ingredientData.search = keyword.target.value;
    dispatch(searchIngredients(ingredientData));
  };

  // JSX code for rendering the component UI
  return (
    <>
      {/* Breadcrumb */}
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Ingredients
          </li>
        </ol>
      </nav>

      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">
            Ingredients List
          </h1>
          <a>
            <span className="text">
              <button className="btn btn-primary" onClick={handleOpenAddIngredientDialog}>+ Add Ingredient</button>
            </span>
          </a>
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                {/* Page size dropdown */}
                <PageLimit changeLimit={(e) => changeTableData(e)} />

                <div className="col-sm-12 col-md-6">
                  <div id="dataTable_filter" className="dataTables_filter">
                    <label>
                      {/* Search input */}
                      <input
                        type="search"
                        placeholder="search ingredients"
                        className="form-control form-control-sm"
                        aria-controls="dataTable"
                        onChange={handleSearchIngredients} // Dynamically trigger search on input change
                      />
                    </label>
                  </div>
                </div>

                <table className="table table-bordered" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      {/* <th scope="col" width="5%">No</th> */}
                      <th scope="10%">Title</th>
                      <th scope="col">Status</th>
                      <th scope="col">Change Status</th>
                      <th width="10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Render ingredients data */}
                    {(ingredients?.data?.ingredients != undefined && ingredients?.data?.ingredients.length > 0) ?
                      ingredients.data.ingredients.map((ingredient, index) => {
                        return (
                          <tr key={index}>
                            {/* <td scope="row">{index + 1}</td> */}
                            <td>{ingredient.title}</td>
                            <td>{ingredient.status ? "Active" : "Inactive"}</td>
                            <td className="tdTextCenter">
                              <div className="custom-control custom-checkbox small">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  htmlFor="customCheck"
                                  id="customCheck"
                                  name={['isChecked' + index]}
                                  checked={ingredient.status}
                                  onChange={() => handleStatusChange(ingredient.idIngredient, ingredient.status)}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                className="actionIcon"
                                title="Edit"

                                onClick={() => handleOpenEditIngredientDialog(ingredient.idIngredient)}
                              >
                                <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                              </a>
                              <a
                                className="actionIcon"
                                title="Delete"

                                onClick={() => handleDeleteIngredient(ingredient.idIngredient)}
                              >
                                <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                              </a>
                            </td>
                          </tr>
                        );
                      }) :
                      // Render "No records found" if there are no ingredients
                      <tr>
                        <td colSpan="6" className="noRecordFound">No records found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>

              {/* Pagination component */}
              <Pagination
                totalPage={ingredients?.data?.pagination?.totalPages || 1}
                currentPage={ingredients?.data?.pagination?.currentPage || 1}
                fullPagination={paginationWithPage}
                totalFaqs={ingredients?.data?.pagination?.totalItems || 1}
                pageSize={ingredients?.data?.pagination?.pageSize || 10}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Add Ingredient dialog */}
      <AddIngredient
        open={openAddIngredientDialog}
        onClose={handleCloseAddIngredientDialog}
        ingredientData={ingredientData}
      />

      {/* Edit Ingredient dialog */}
      <EditIngredient
        open={openEditIngredientDialog}
        onClose={handleCloseEditIngredientDialog}
        ingredientData={ingredientData}
        ingredientId={editingIngredientId}
      />

      {/* Delete ingredient dialog */}
      <DeleteIngredient
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        ingredientId={deletingIngredientId}
        ingredientData={ingredientData}
      />
    </>
  );
}

export default IngredientsList;
