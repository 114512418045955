import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import { getUsers, blockUnblockUser, searchUsers, getActiveUserCount } from "../../features/users/users.slice";
import { toast } from "react-toastify";
import DefaultPagination from '../../config/Pagination';
import UserSorting from '../Common/UserSorting';
import WallletComponent from './WallletComponent';
import ResetPassword from './ResetPassword';
import Switch from '@mui/material/Switch';
import Delete from './Delete';
import Loader from '../Common/Loader';

const List = () => {
  // Set initial user data with default page size and page number

  // Use state to manage userData
  const [userData, setUserData] = useState({
    pageSize: DefaultPagination.PAGE_SIZE,
    pageNumber: DefaultPagination.PAGE_NUMBER,
    isactive: 1,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [walletUser, setWalletUser] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [deletingUserId, setDeletingUserId] = useState('');
  const [deletingUserEmail, setDeletingUserEmail] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { users, getActiveUsersCount } = useSelector((state) => state.users);

  useEffect(() => {
    if (userData.search) {
      dispatch(searchUsers(userData));
    } else {
      dispatch(getUsers(userData));
    }
    dispatch(getActiveUserCount());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [dispatch, userData]);

  const handleStatusChange = async (idUser) => {
    const response = await dispatch(blockUnblockUser(idUser));

    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getUsers(userData));
    } else {
      toast.error(response.payload.message);
    }
  };

  const paginationWithPage = (pageNumber) => {

    setUserData((prevUserData) => ({ ...prevUserData, pageNumber: pageNumber }));
    // userData.pageNumber = pageNumber;
    if (userData.search) {
      dispatch(searchUsers(userData));
    } else {
      dispatch(getUsers(userData));
    }
  };

  const handleSearchUsers = (keyword) => {
    userData.search = keyword.target.value;
    if (keyword.target.value) {
      dispatch(searchUsers(userData));
    } else {
      dispatch(getUsers(userData));
    }
  };

  // Function to handle change in page size dropdown
  const changeTableData = (e) => {
    setUserData((prevUserData) => ({ ...prevUserData, pageSize: parseInt(e.target.value) }));
    // userData.pageSize = parseInt(e.target.value);
    dispatch(getUsers(userData));
  };

  const changeUserData = async (e) => {
    setLoading(true);
    setUserData((prevUserData) => ({ ...prevUserData, isactive: e.target.value }));
    // userData.isactive = e.target.value;
    // await dispatch(getUsers(userData)).then((res) => {
    //   if (res) {
    //     setLoading(false);
    //   }
    // })
    if (userData.search) {
      dispatch(searchUsers(userData)).then((res) => {
        setLoading(false);
      })
    } else {
      dispatch(getUsers(userData)).then((res) => {
        setLoading(false);
      })
    }
  }

  const handleOpenWallet = (user) => {
    setIsOpen(true);
    setWalletUser(user);
  }

  // Function to handle password reset component
  const handleOpenEditDialog = (userId) => {
    setEditingId(userId);
    setOpenEditDialog(true);
  }

  // function for deleting the user

  const handleDeleteUser = (userId, email) => {
    setDeletingUserId(userId);
    setDeletingUserEmail(email);
    setOpenDeleteDialog(true);
  }

  return (
    <>
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Users
          </li>
        </ol>
      </nav>

      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">
            Users List
          </h1>
        </div>

        <div className="card shadow mb-4 list">
          <div className="card-body">
            {/* Add loader */}
            {loading ?
              (
                <Loader />
              ) :
              (
                <div className="table-responsive">
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="dataTables_length" id="dataTable_length">
                        <span>Total Users: {users?.data?.pagination?.totalUsers || '0'}</span>
                        <br />
                        <span style={{ background: '#50C878' }}>Total Online users: {getActiveUsersCount?.data || '0'}</span>
                      </div>
                    </div>
                    {/* Page size dropdown */}
                    <PageLimit changeLimit={(e) => changeTableData(e)} />

                    {/* Change user sorting */}
                    <UserSorting changeLimit={(e) => changeUserData(e)} currentValue={userData?.isactive} />

                    <div className="col-sm-12 col-md-3">
                      <div id="dataTable_filter" className="dataTables_filter">
                        <label>
                          {/* Search input */}
                          <input
                            type="search"
                            placeholder="search users"
                            className="form-control form-control-sm"
                            aria-controls="dataTable"
                            value={userData?.search}
                            onChange={handleSearchUsers}
                          />
                        </label>
                      </div>
                    </div>

                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          {/* <th scope="col" width="5%">No</th> */}
                          <th scope="col">Parent</th>
                          <th scope="col">Child</th>
                          <th scope="col">Email</th>
                          <th scope="col">School</th>
                          <th scope="col">City</th>
                          <th scope="col">Phone</th>
                          <th scope="col">wallet</th>
                          <th scope="col">Created at</th>
                          <th scope="col">Last activity</th>
                          {/* <th scope="col">Status</th> */}
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(users?.data?.users != undefined && users?.data?.users.length > 0) ?
                          users?.data?.users.map((user, index) => {
                            return (
                              <tr key={index}>
                                {/* <td scope="row">{index + 1}</td> */}
                                <td>{user.firstName}&nbsp;{user.lastName}</td>
                                <td>{user?.Children?.map(child => child.name).join(', ')}</td>
                                <td>{user.email}</td>
                                <td>
                                  {user?.Children && user?.Children.map(child => child?.School?.schoolName).join(', ')}
                                </td>
                                <td>{user.city}</td>
                                <td>{user.mobileNumber}</td>
                                <td>{user.wallet}
                                  <i className="fa fa-plus-circle float-right"
                                    onClick={() => { handleOpenWallet(user) }}
                                    title='Add wallet amount'
                                    aria-hidden="true">
                                  </i>
                                </td>
                                <td>
                                  {new Date(user.createdAt).toLocaleDateString('en-GB')}
                                </td>
                                <td>
                                  {new Date(user.lastLogin ? user.lastLogin : user.createdAt).toLocaleDateString('en-GB')}
                                </td>
                                {/* <td>{user.active ? "Active" : "Inactive"}</td> */}
                                <td className="tdTextCenter">
                                  <div className="custom-control custom-switch small">
                                    <Switch
                                      id={`customSwitch${index}`}
                                      checked={user.active}
                                      onChange={() => handleStatusChange(user.idUser)}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <a
                                    className="actionIcon"
                                    title="Edit"
                                    onClick={() => handleOpenEditDialog(user.idUser)}
                                  >
                                    <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                  </a><i className="fa fa-ellipsis-v fa-2x mr-2" aria-hidden="true"></i>
                                  <a
                                    className="actionIcon"
                                    title="Delete"
                                    onClick={() => handleDeleteUser(user.idUser, user.email)}
                                  >
                                    <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                  </a>
                                  <i className="fa fa-ellipsis-v fa-2x mr-2" aria-hidden="true"></i>
                                  <Link to={{ pathname: `/admin/users/user-detail/${user.idUser}` }} state={{ user: user }}  >
                                    User's Details
                                  </Link>
                                </td>
                              </tr>
                            );
                          }) :
                          <tr>
                            <td colSpan="7" className="noRecordFound">No records found</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination component */}
                  <Pagination
                    totalPage={users?.data?.pagination?.totalPages || 1}
                    currentPage={users?.data?.pagination?.currentPage || 1}
                    fullPagination={paginationWithPage}
                    totalFaqs={users?.data?.pagination?.totalUsers || 1}
                    pageSize={users?.data?.pagination?.pageSize || 10}
                  />
                </div>
              )
            }
            <WallletComponent
              open={isOpen}
              onClose={() => { setIsOpen(false) }}
              user={walletUser}
              userData={userData}
            />
            <ResetPassword
              open={openEditDialog}
              onClose={() => setOpenEditDialog(false)}
              userId={editingId}
              userData={userData}
            />
            {/* Delete School dialog */}
            <Delete
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
              userId={deletingUserId}
              userData={userData}
              userEmail={deletingUserEmail}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
