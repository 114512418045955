import React, { useState, useEffect } from 'react';

const Pagination = (props) => {
  const { totalPage, currentPage, fullPagination, totalFaqs, pageSize } = props;
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const generatePageNumbers = () => {
      const pages = [];
      const maxPagesToShow = 5;

      if (totalPage <= maxPagesToShow) {
        // Show all pages if the total is less than the max to display
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i);
        }
      } else {
        // Show limited pages with ellipses
        if (currentPage <= 3) {
          pages.push(1, 2, 3, 4, '...', totalPage);
        } else if (currentPage >= totalPage - 2) {
          pages.push(1, '...', totalPage - 3, totalPage - 2, totalPage - 1, totalPage);
        } else {
          pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPage);
        }
      }

      setPageNumbers(pages);
    };

    generatePageNumbers();
  }, [totalPage, currentPage]);

  return (
    <div className="row">
      {/* want to show how many entries is displaying on the page */}

      <div className="col-sm-12 col-md-5">
        <p className="dataTables_info" id="dataTable_info">
          Showing {pageNumbers.length > 0 ? ` ${((currentPage * pageSize) - pageSize) + 1} to ${currentPage * pageSize > totalFaqs ? totalFaqs : currentPage * pageSize} of ${totalFaqs} entries` : ''}
        </p>
      </div>
      <div className="col-sm-12 col-md-7">
        <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
          <ul className="pagination">
            <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => fullPagination(currentPage - 1)} disabled={currentPage === 1}>
                Previous
              </button>
            </li>
            {pageNumbers.map((number, index) => (
              <li key={index} className={`paginate_button page-item ${number === currentPage ? 'active' : ''}`}>
                {number === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button className="page-link" onClick={() => fullPagination(number)}>
                    {number}
                  </button>
                )}
              </li>
            ))}
            <li className={`paginate_button page-item ${currentPage === totalPage ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => fullPagination(currentPage + 1)} disabled={currentPage === totalPage}>
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
