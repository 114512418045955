// export const base_url = "https://rtasapi.elb.cisinlive.com/admin/api/v1/";
// export const image_url = "https://rtasapi.elb.cisinlive.com/"
// export const public_url = "https://rtasapi.elb.cisinlive.com/api/";

// Dev Environment Url
// export const base_url = "http://dev.onlineskolemad.dk:5000/admin/api/v1/";
// export const image_url = "http://dev.onlineskolemad.dk:5000/"
// export const public_url = "http://dev.onlineskolemad.dk:5000/api/";

export const base_url = "https://api.onlineskolemad.dk/admin/api/v1/";
export const image_url = "https://api.onlineskolemad.dk/"
export const public_url = "https://api.onlineskolemad.dk/api/";

export const CAT_IMAGE_PATH = "/uploads/category-img/"
export const MENU_IMAGE_PATH = "/uploads/menu-img/"
export const PROFILE_IMAGE_PATH = "uploads/profile-img/"