import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

const OrdersModal = ({ isOpen, closeModal, orders, selectedOrderId }) => {
  const selectedOrder = orders.find((order) => order.id === selectedOrderId);

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return 'Invalid Date'; // Handle null, undefined, or empty date strings
    }

    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Invalid Date'; // Handle invalid date formats
    }

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'CET',
    };

    const formatter = new Intl.DateTimeFormat('en-GB', options);

    // Format the date and replace ", " with " - "
    return formatter.format(date).replace(', ', ' - ') + ' CET';
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
      <DialogTitle>Order Details</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ fontWeight: "bold", border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}
                >
                  Order Time
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}
                >
                  Order Item
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}
                >
                  Child
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}
                >
                  Child School
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}
                >
                  Child Class
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOrder ? (
                <TableRow key={selectedOrder.id}>
                  <TableCell style={{ border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}>
                    {/* {new Date(selectedOrder.createdAt).toLocaleString()} */}
                    {selectedOrder.createdAt ? formatDateTime(selectedOrder.createdAt) : 'N/A'}


                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}
                    dangerouslySetInnerHTML={{
                      __html: selectedOrder.OrdersItems.map(
                        (item) => item.itemName
                      ).join(", "),
                    }}
                  />
                  <TableCell style={{ border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}>
                    {
                      selectedOrder.User.Children.find(
                        (child) => child.id === selectedOrder.childId
                      )?.name
                    }
                  </TableCell>
                  <TableCell style={{ border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}>
                    {
                      selectedOrder.User.Children.find(
                        (child) => child.id === selectedOrder.childId
                      )?.School.schoolName
                    }
                  </TableCell>
                  <TableCell style={{ border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}>
                    {
                      selectedOrder?.User?.Children?.find(
                        (child) => child?.id === selectedOrder?.childId
                      )?.SchoolClass?.className
                    }
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    style={{ border: "1px solid black", textAlign: 'center', fontSize: '0.8rem', padding: '0px' }}
                    colSpan={5}
                    align="center"
                  >
                    No order found for the selected ID
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrdersModal;
