import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts } from '../../features/contact/contact.slice';
import EditContact from './Edit'

export const ContactList = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContacts());
    }, []);

    const { contacts } = useSelector((state) => state.contacts);
    const [openEditContactDialog, setOpenEditContactDialog] = useState(false);
    const [editingContactId, setEditingContactId] = useState('');

    // Function to handle closing the Edit FAQ dialog
    const handleCloseEditContactDialog = () => {
        setOpenEditContactDialog(false);
    };

    // Function to handle opening the Edit FAQ dialog
    const handleOpenEditContactDialog = (contactId) => {
        setEditingContactId(contactId);
        setOpenEditContactDialog(true);
    };

    const formatDateTime = (dateString) => {
        if (!dateString) {
            return 'Invalid Date'; // Handle null, undefined, or empty date strings
        }
    
        const date = new Date(dateString);
        if (isNaN(date)) {
            return 'Invalid Date'; // Handle invalid date formats
        }
    
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'CET',
        };
    
        const formatter = new Intl.DateTimeFormat('en-GB', options);
    
        // Format the date and replace ", " with " - "
        return formatter.format(date).replace(', ', ' - ') + ' CET';
    };
    
    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Contact Us
                    </li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Inquery List
                    </h1>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                {/* Page size dropdown */}
                                {/* <PageLimit changeLimit={(e) => changeTableData(e)} /> */}
                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            {/* Search input */}
                                            {/* <input
                                                type="search"
                                                placeholder="search Inqueries"
                                                className="form-control form-control-sm"
                                                aria-controls="dataTable"
                                                onChange={handleSearchInquery} // Dynamically trigger search on input change
                                            /> */}
                                        </label>
                                    </div>
                                </div>

                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">Email</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Created on</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Render Inquiries data */}
                                        <tr>
                                            {/* <td scope="row">{contacts?.data?.id}</td> */}
                                            <td>{contacts?.data?.email}</td>
                                            <td>{contacts?.data?.location}</td>
                                            <td>{contacts?.data?.phone}</td>
                                            <td>{contacts?.data?.createdAt ? formatDateTime(contacts.data.createdAt) : 'N/A'}</td>
                                            <td>
                                                <a
                                                    className="actionIcon"
                                                    title="Edit"
                                                    onClick={() => handleOpenEditContactDialog(contacts?.data?.id)}
                                                >
                                                    <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit FAQ dialog */}
            <EditContact
                open={openEditContactDialog}
                onClose={handleCloseEditContactDialog}
                contactData={contacts.data}
                contactId={editingContactId}
            />
        </>
    )
}

export default ContactList