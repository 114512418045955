import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrderService from "./orders.services";

export const getOrderList = createAsyncThunk(
    "orders/get-order",
    async (orderData, thunkAPI) => {
        try {
            return await OrderService.getOrders(orderData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const updateOrderStatus = createAsyncThunk(
    "orders/update-orderstatus",
    async (orderData, thunkAPI) => {
        try {
            return await OrderService.updateOrderStatus(orderData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getOrderStats = createAsyncThunk(
    "orders/order-stats",
    async (thunkAPI) => {
        try {
            return await OrderService.getOrderStats();
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getUserOrders = createAsyncThunk(
    "orders/users-order",
    async (obj, thunkAPI) => {
        try {
            return await OrderService.getUserOrders(obj);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const resetState = createAction("RevertAll");

const initialState = {
    orders: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const orderslice = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOrderList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrderList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.orders = action.payload;
            })
            .addCase(getOrderList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getOrderStats.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrderStats.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.orderStats = action.payload;
            })
            .addCase(getOrderStats.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateOrderStatus.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateOrderStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedOrderStatus = action.payload;
            })
            .addCase(updateOrderStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getUserOrders.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserOrders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.getUserOrders = action.payload;
            })
            .addCase(getUserOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default orderslice.reducer;