import axios from "axios";
import { base_url, image_url } from "./baseUrl";
import { config } from "./axiosconfig";

// Function to get the weekly stats data for the admin dashboard
const getWeeklyStats = async ({ start, end }) => {
  const headers = config();
  // date format YYYY:MM:DD
  // const startdate = '2023-11-29';
  // const enddate = '2023-12-06';
  try {
    const response = await axios.get(`${base_url}getWeeklyOrders?startdate=${start}&enddate=${end}`, headers);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to save censor data.
const saveCensorData = async (data) => {
  const headers = config();
  try {
    const response = await axios.post(`${base_url}addCensors`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

// function to get the sensor data
const getCensorData = async () => {
  const headers = config();
  const response = await axios.get(`${base_url}getcensors`, headers);
  return response.data;
}

// Function to save the value of schedule task.
const saveScheduleData = async (data) => {
  const headers = config();
  const response = {
    status: 200,
    message: `Email has been sent to the ${data.selectedEmail[0].schoolName} ${data.today ? 'Today' : 'Tomorrow'} at ` + data.printTime.toLocaleTimeString(),
    data: data
  };

  return response;
}

// function for add the amount in wallet.
const addWalletAmount = async (data) => {
  const headers = config();
  try {
    const response = await axios.post(`${base_url}users/addWalletAmt`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

const sendSurvey = async (data) => {
  const headers = config();
  try {
    let obj = {
      email: 'anurag@mailinator.com',
      subject: 'Your Feedback Matters! Take our OnlineSkoleMad Food Survey 🍲',
      message: `<!DOCTYPE html>
            <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
            
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width,initial-scale=1">
              <meta name="x-apple-disable-message-reformatting">
              <title></title>
              <style>
                table,
                td,
                div,
                h1,
                p {
                  font-family: Arial, sans-serif;
                }
              </style>
            </head>
            
            <body style="margin:0;padding:0;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
                <tr>
                  <td align="center" style="padding:0;">
                    <table role="presentation"
                      style="width:750px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
                      <tr>
                        <td align="center" style="padding:40px 0 30px 0;background:#EE4D1A;">
                          <img src="https://assets.codepen.io/210284/h1.png" alt="" width="300"
                            style="height:auto;display:block;" />
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:36px 30px 42px 30px;">
                          <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                              <td style="padding:0 0 36px 0;color:#153643;">
                                <h1 style="font-size:24px;margin:0 0 20px 0;font-family:Arial,sans-serif;">Survey Onlineskolmed
                                  <hr />
                                </h1>
                                <p style="margin:0 0 12px 0;font-size:16px;line-height:24px;font-family:Arial,sans-serif;">We hope
                                  this email finds you well. At OnlineSkoleMad, we value your opinions, and we are constantly
                                  striving to enhance your dining experience. Your feedback plays a crucial role in helping us
                                  understand your preferences and make improvements to our food services. <br /><br />
            
                                  We invite you to participate in our Food Survey, where you can share your thoughts on the meals we
                                  provide. Your input will assist us in tailoring our menu to better suit your tastes and
                                  preferences. <br /><br />
            
                                  By taking a few minutes to complete the survey, you contribute to the ongoing effort to provide
                                  you with delicious and satisfying meals. Your feedback is important to us, and we genuinely
                                  appreciate your time and insights.<br /><br />
            
                                  As a token of our gratitude for your participation, you will be entered into a drawing for a
                                  chance to win [prize details, if applicable]. <br /><br />
            
                                  <button type="button"
                                    style="color: #fff;background-color: #EE4D1A;border-color: #EE4D1A;cursor: pointer;display: inline-block;font-weight: 400;text-align: center;vertical-align: middle;    border: 1px solid transparent;padding: 0.375rem 4.75rem;font-size: 1rem;line-height: 1.4;border-radius: 0.35rem;transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;">
                                    Take
                                    Survey</button><br /><br />
                                  Your honest feedback is invaluable to us, and we look forward to hearing your thoughts.
                                  Thank you for being a part of the OnlineSkoleMad community.<br /><br />
            
                                  Best regards,<br /><br />
                                  Cheers to your health,<br />
                                  OnlineSkoleMad Team
                                </p>
                              </td>
                            </tr>
                            <tr>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:30px;background:#ee4c50;">
                          <table role="presentation"
                            style="width:100%;border-collapse:collapse;border:0;border-spacing:0;font-size:9px;font-family:Arial,sans-serif;">
                            <tr>
                              <td style="padding:0;width:50%;" align="left">
                                <p style="margin:0;font-size:14px;line-height:16px;font-family:Arial,sans-serif;color:#ffffff;">
                                  &reg; Someone, Somewhere 2024<br /><a href="http://www.example.com"
                                    style="color:#ffffff;text-decoration:underline;">Unsubscribe</a>
                                </p>
                              </td>
                              <td style="padding:0;width:50%;" align="right">
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </body>
            
            </html>`
    }
    data.selectedUsers.map((item, index) => {
      obj.email = item.email;
      axios.post(`${image_url}web/api/v1/sendemail`, obj, headers);
    })
    let response = {
      status: true,
      code: 200,
      message: 'Survey Email sent successfully'
    }
    return response;
  } catch (error) {
    return error.response;
  }
}

const scheduleStatus = async (id) => {
  const headers = config();
  const response = await axios.put(`${base_url}schedule/updateScheduleActiveStatus/${id}`, {}, headers);

  return response.data;
}

const getScheduleById = async (id) => {
  const headers = config();
  try {
    const response = await axios.get(`${base_url}schedule/getScheduleById/${id}`, headers);

    return response.data;
  } catch (error) {
    return error.response;
  }
}

// function to handle the user password reset.

const resetPassword = async (userId, data) => {
  const headers = config();
  const response = await axios.put(`${base_url}users/changePasswordUser/${userId}`, data, headers);
  return response.data;
}

// fucntion to insert the options in Menu.

const insertOptions = async (data) => {
  const headers = config();
  try {
    const response = await axios.post(`${base_url}addOption`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

// function for updating the options in menu

const updateOptions = async (data) => {
  const headers = config();
  try {
    let obj = {
      title: data?.title || '',
      idMenu: data?.idMenu || '',
      price: data.price || ''
    }
    const response = await axios.patch(`${base_url}updateOption/${data?.id}`, obj, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

// function to Revert user deletion request 
const revertUser = async (idUser) => {
  const data = {
    idUser: idUser
  }
  const headers = config();
  try {
    const response = await axios.post(`${base_url}users/keepActive`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

// Function to delete the user

const deleteUser = async (idUser) => {
  const data = {
    idUser: idUser
  }
  const headers = config();
  try {
    const response = await axios.post(`${base_url}users/deleteUser`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

const deleteUsersOrder = async (orderIds) => {
  const headers = config();
  try {
    let obj = {
      orderIds: orderIds,
    }
    const response = await axios.post(`${base_url}cancelBulkOrderStatus`, obj, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

// fumction to get the list of all pages

const getAllPages = async (data) => {
  const headers = config();
  try {
    const response = await axios.post(`${base_url}getPages?search=&pageNumber=1&pageSize=10`, {}, headers);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

// function to submit the page data

const submitPageData = async (data) => {
  const headers = config();
  try {
    const response = await axios.post(`${base_url}addPage`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

const deletePage = async (id) => {
  const headers = config();
  try {
    let data = {
      id: [id]
    }
    const response = await axios.post(`${base_url}deletepage`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}


const getPageById = async (id) => {
  const headers = config();
  try {
    const response = await axios.get(`${base_url}getPageById/${id}`, headers);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

const updatePageData = async (data, id) => {
  const headers = config();
  try {
    const response = await axios.patch(`${base_url}updatePage/${id}`, data, headers);
    return response;
  } catch (error) {
    return error.response;
  }
}

const commonFunction = {
  getWeeklyStats,
  saveCensorData,
  getCensorData,
  saveScheduleData,
  addWalletAmount,
  sendSurvey,
  scheduleStatus,
  getScheduleById,
  resetPassword,
  insertOptions,
  updateOptions,
  revertUser,
  deleteUser,
  deleteUsersOrder,
  getAllPages,
  submitPageData,
  deletePage,
  getPageById,
  updatePageData,
};

export default commonFunction;