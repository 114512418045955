import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { getCategories } from "../../features/categories/categories.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { base_url } from '../../utils/baseUrl';
import { config } from '../../utils/axiosconfig';

const schema = yup.object().shape({
  title: yup.string().required("Title is Required!"),
  description: yup.string().required("Description is Required!"),
});

const AddCategory = ({ open, onClose, categoryData }) => {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");

  const handleImageChange = (event) => {

    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      setImageError("");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      imageUrl: ''
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldValue }) => {
      try {
        const imageData = new FormData();
        if (imageFile) {
          imageData.append("imageUrl", imageFile);
        }
        imageData.append("title", values.title);
        imageData.append("description", values.description);

        const apiConfig = config();
        // First, upload the image and get the image URL from the API
        const uploadResponse = await fetch(base_url + "/addCategory", {
          method: "POST",
          headers: {
            ...apiConfig.headers,
          },
          body: imageData,
        });

        const data = await uploadResponse.json();

        if (data.status) {
          toast.success(data.message);
          dispatch(getCategories(categoryData));
          onClose();

          // Reset the form after successful submission
          formik.resetForm();

          // Clear the imageFile state to allow selecting a new image for the next submission
          setImageFile(null);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        // Handle error if necessary
        toast.error("Error submitting the form.");
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal large-dialog">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Enter category title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="on"
              />
            </div>
            {formik.touched.title && formik.errors.title && (
              <div className="error m-1">{formik.errors.title}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className="form-control"
                id="description"
                placeholder="Enter category description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="on"
              />
            </div>
            {formik.touched.description && formik.errors.description && (
              <div className="error m-1">{formik.errors.description}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="imageFile">Image</label>
            <input
              type="file"
              className="form-control"
              id="imageFile"
              name="imageFile"
              accept="image/*"
              onChange={handleImageChange}
            />
            {imageError && <div className="error m-1">{imageError}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" type="submit">Add</button>
          <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCategory;
