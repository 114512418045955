import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { getSchool } from '../../features/schools/schools.slice';

export const ViewSchool = ({ open, onClose, schoolId }) => {
    const dispatch = useDispatch();

    // Fetch School data by ID using getSchool API
    useEffect(() => {
        if (schoolId) {
            dispatch(getSchool(schoolId));
        }
    }, [schoolId, dispatch]);

    // Get School data from Redux store
    const school = useSelector((state) => state.schools.getSchool);

    return (
        <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
            <DialogTitle>View FAQ</DialogTitle>
            <DialogContent>
                {/* Display FAQ details */}
                <div className="form-group">
                    <label>School Name</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: school?.data?.school.schoolName }} />
                </div>

                <div className="form-group">
                    <label>School Address</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: school?.data?.school.schoolAddress }} />
                </div>

                <div className="form-group">
                    <label>School Address</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: school?.data?.school?.schoolEmail }} />
                </div>

                <div className="form-group">
                    <label>Status</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: school?.data?.school.status ? 'Active' : 'Inactive' }} />
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn btn-secondary" onClick={onClose}>Close</button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewSchool;