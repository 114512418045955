import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loader from "../Common/Loader";
import { DatePicker, Space } from "antd";
import DataTable from "./DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrders } from "../../features/orders/orders.slice";

const UserDetails = () => {
  const location = useLocation();
  const { user } = location.state; // Extract the user object from the Location state
  const { idUser } = useParams(); // Extract the user ID from the URL
  const [loading, setLoading] = useState(true);
  const [dateObj, setDateObj] = useState("");

  const dispatch = useDispatch();

  const [userHistory, setUserHistory] = useState("");

  const orders = useSelector((state) => state?.orders?.getUserOrders?.data);

  useEffect(() => {
    if (orders) {
      setUserHistory(orders); // Set the Redux data to the state
    }
    return () => {
      setUserHistory([])
    }
  }, [orders]);

  // Reset state when visiting again
  useEffect(() => {
    setUserHistory([]); // Clear state when component mounts
    setLoading(true); // Re-enable loader
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const convertToBackendFormat = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };
  const handleDateChange = (dates, dateStrings) => {
    const formattedStartDate = convertToBackendFormat(dateStrings[0]);
    const formattedEndDate = convertToBackendFormat(dateStrings[1]);
    const obj = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      userId: idUser,
    };
    setDateObj(obj);
    dispatch(getUserOrders(obj));
  };

  return (
    <>
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">Dashboard</Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            <Link to="/admin/users">Users</Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            User Details
          </li>
        </ol>
      </nav>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-body">
            {/* Add loader */}
            {loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <div className="row">
                  <h1 className="h3 mb-0 text-gray-800 fontBold">
                    User's ("{user?.email}") History
                  </h1>
                  <div className="col-sm-12">
                    <Space
                      direction="vertical"
                      size={12}
                      className="m-3"
                      style={{ width: "100%" }}
                    >
                      <DatePicker.RangePicker
                        onChange={handleDateChange}
                        format="DD/MM/YYYY"
                      />
                    </Space>
                  </div>
                </div>
                <DataTable userHistory={userHistory} dateObj={dateObj} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDetails;
