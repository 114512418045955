import { useDispatch } from 'react-redux';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import commonFunction from '../../utils/commonFunction';
import { toast } from 'react-toastify';
import { deleteSchedule, getScheduledList } from '../../features/production/production.slice';
import EditSchedule from './EditSchedule';

function ScheduleList({ scheduleData }) {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [scheduleDataById, setScheduleDataById] = useState('');

    // Get the schedule list from redux store
    const scheduleList = useSelector((state) => state?.production?.schedule);

    // Handle Status Change
    const handleStatusChange = async (id) => {
        const response = await commonFunction.scheduleStatus(id);
        if (response.status) {
            toast.success(response?.message);
            dispatch(getScheduledList(scheduleData));
        } else {
            toast.error('something went wrong !!!');
        }
    }

    // Handle Schedule List edit
    const handleOpenEditItemDialog = async (id) => {
        if (id) {
            setOpen(true);
            if (scheduleDataById.id !== id) {
                const response = await commonFunction.getScheduleById(id);
                if (response.status) {
                    setScheduleDataById(response.data);
                }
            }
        } else {
            toast.error('something went wrong !!!');
        }
    }

    // Handle schedule Delete
    const handleDeleteItem = async (id) => {

        if (confirm('Are you sure you want to delete this schedule')) {
            const response = await dispatch(deleteSchedule(id));
            if (response.payload.status) {
                toast.success('deleted successfully');
                dispatch(getScheduledList(scheduleData));
            } else {
                toast.error(response.payload.message);
            }
        }
    }

    return (
        <>
            <div className="col-md-6">
                <div className="container">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Scheduled Task List.
                    </h1>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th scope="col" width="5%">No</th>
                                <th scope="col">Mail to</th>
                                <th scope="col">Scheduled time</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Render template data */}
                            {(scheduleList?.data?.schedules != undefined && scheduleList?.data?.schedules.length > 0) ?
                                scheduleList?.data?.schedules.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td scope="row">{index + 1}</td>
                                            <td >{item.selectedEmails}</td>
                                            <td>
                                                {item?.schedule_time}
                                            </td>
                                            <td className="tdTextCenter">
                                                <div className="custom-control custom-checkbox small">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        htmlFor="customCheck"
                                                        id="customCheck"
                                                        name={['isChecked' + index]}
                                                        checked={item?.status}
                                                        onChange={() => handleStatusChange(item.id)}
                                                    />
                                                </div>
                                            </td>
                                            <td>

                                                {/* <a
                                                    className="actionIcon"
                                                    title="Edit"
                                                    onClick={() => handleOpenEditItemDialog(item.id)}
                                                >
                                                    <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                                </a> */}
                                                <a
                                                    className="actionIcon"
                                                    title="Delete"
                                                    onClick={() => handleDeleteItem(item.id)}
                                                >
                                                    <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="8" className="noRecordFound">No records found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <EditSchedule
                        open={open}
                        onClose={() => { setOpen(false) }}
                        scheduleData={scheduleDataById}
                    />
                </div>
            </div>
        </>
    )
}

export default ScheduleList