import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import IngredientsService from "./ingredients.services";

export const getIngredients = createAsyncThunk(
  "ingredients/get-ingredients",
  async (ingredientData, thunkAPI) => {
    try {
      return await IngredientsService.getIngredients(ingredientData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const createIngredient = createAsyncThunk(
  "ingredients/create-ingredient",
  async (ingredientData, thunkAPI) => {
    try {
      return await IngredientsService.createIngredient(ingredientData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateIngredient = createAsyncThunk(
  "ingredients/update-ingredient",
  async (ingredientData, thunkAPI) => {
    try {
      return await IngredientsService.updateIngredient(ingredientData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateActiveInactive = createAsyncThunk(
  "ingredients/update-active-inactive",
  async (id, thunkAPI) => {
    
    try {
      return await IngredientsService.updateActiveInactive(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const deleteIngredient = createAsyncThunk(
  "ingredients/delete-ingredient",
  async (id, thunkAPI) => {
    try {
      return await IngredientsService.deleteIngredient(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const getIngredient = createAsyncThunk(
  "ingredients/get-ingredient",
  async (id, thunkAPI) => {
    try {
      return await IngredientsService.getIngredient(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const searchIngredients = createAsyncThunk(
  "ingredients/search-ingredients",
  async (ingredientData, thunkAPI) => {
    try {
      return await IngredientsService.searchIngredients(ingredientData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const getActiveIngredients = createAsyncThunk(
  "ingredients/get-active-ingredients",
  async (ingredientData, thunkAPI) => {
    try {
      return await IngredientsService.getActiveIngredients(ingredientData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const resetState = createAction("RevertAll");

const initialState = {
  ingredients: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const IngredientSlice = createSlice({
  name: "ingredients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIngredients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.ingredients = action.payload;
      })
      .addCase(getIngredients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createIngredient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createIngredient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdIngredient = action.payload;
      })
      .addCase(createIngredient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateIngredient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIngredient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedIngredient = action.payload;
      })
      .addCase(updateIngredient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteIngredient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteIngredient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.deletedIngredient = action.payload.message;
      })
      .addCase(deleteIngredient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getIngredient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIngredient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.getIngredient = action.payload.data;

      })
      .addCase(getIngredient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateActiveInactive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateActiveInactive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload.message

      })
      .addCase(updateActiveInactive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(searchIngredients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.ingredients = action.payload
        state.message = action.payload.message

      })
      .addCase(searchIngredients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getActiveIngredients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActiveIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.ingredients = action.payload
        state.message = action.payload.message

      })
      .addCase(getActiveIngredients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default IngredientSlice.reducer;
