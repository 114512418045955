import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { createMenu, getMenus } from "../../features/menus/menus.slice";
import { getCategories } from "../../features/categories/categories.slice";
import { getIngredients } from "../../features/ingredients/ingredients.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { base_url } from '../../utils/baseUrl';
import { config } from '../../utils/axiosconfig';
import commonFunction from '../../utils/commonFunction';

const schema = yup.object().shape({
  title: yup
    .string()
    .trim() // Trim the value to remove leading and trailing spaces
    .test("is-not-empty", "Title is Required!", (value) => {
      if (typeof value !== 'string') return false; // Check if value is a string
      const strippedValue = value.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
      return strippedValue !== "" && /\S/.test(strippedValue); // Check if the stripped value contains any non-whitespace character
    }),
  description: yup
    .string()
    .trim() // Trim the value to remove leading and trailing spaces
    .test("is-not-empty", "Description is Required!", (value) => {
      if (typeof value !== 'string') return false; // Check if value is a string
      const strippedValue = value.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
      return strippedValue !== "" && /\S/.test(strippedValue); // Check if the stripped value contains any non-whitespace character
    }),
  price: yup
    .number()
    .required("Price is Required!")
    .positive("Price must be a positive number")
    .test("is-decimal", "Price must have up to 2 decimal places", (value) => {
      if (!isNaN(value)) {
        return /^(\d+\.\d{1,2}|\.\d{1,2}|\d+)$/.test(value);
      }
      return false;
    }),
  categoryId: yup.number().required("Category is Required!"),
  ingredientIds: yup.array().of(yup.number()).min(1, "At least one ingredient is required"),
});

const AddMenu = ({ open, onClose, menuItemData }) => {

  let categoryData = {
    pageSize: '',
    pageNumber: '',
  };

  let ingredientData = {
    pageSize: 50,
    pageNumber: 1
  };

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories?.categories?.data);
  const filteredCategoryData = categories?.categories?.filter(item => item.status === 1);

  const ingredients = useSelector((state) => state.ingredients?.ingredients?.data);
  const filteredIngredientsData = ingredients?.ingredients?.filter(item => item.status === 1);

  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const [rows, setRows] = useState([]); // State to hold the edited data

  // const handleAddField = () => {
  //   setInputCount(inputCount + 1);
  // };

  useEffect(() => {
    dispatch(getCategories(categoryData));
    dispatch(getIngredients(ingredientData));
  }, [dispatch]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      setImageError("");
    }
  };


  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      price: '',
      categoryId: '',
      ingredientIds: [],
      nutrition: {},
      options: {},
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const imageData = new FormData();
        if (imageFile) {
          imageData.append("image", imageFile);
        }
        imageData.append("title", values.title);
        imageData.append("description", values.description);
        imageData.append("nutrition", JSON.stringify(values.nutrition));
        imageData.append("categoryId", values.categoryId);
        imageData.append("ingredientIds", JSON.stringify(values.ingredientIds));
        imageData.append("price", values.price);

        const apiConfig = config();
        // First, upload the image and get the image URL from the API
        const uploadResponse = await fetch(base_url + "addMenu", {
          method: "POST",
          headers: {
            ...apiConfig.headers,
          },
          body: imageData,
        });

        const data = await uploadResponse.json();

        if (data.status) {
          const idMenu = data?.data?.menus?.idMenu.toString();;  // Extract idMenu from the response
          // Ensure that options processing is handled correctly
          const optionsPromises = Object.values(values?.options)?.map(async (item) => {
            const itemWithIdMenu = { ...item, idMenu: idMenu };
            const optionRes = await commonFunction.insertOptions(itemWithIdMenu);
            return optionRes;
          });

          // Wait for all promises to resolve
          await Promise.all(optionsPromises);

          toast.success(data.message);
          dispatch(getMenus(menuItemData));
          onClose();

          // Reset the form after successful submission
          formik.resetForm();

          // Clear the imageFile state to allow selecting a new image for the next submission
          setImageFile(null);
          setSelectedIngredients([]);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        // Handle error if necessary
        toast.error("Error submitting the form.");
      }
    },
  });

  const handleTitleChange = (content) => {
    formik.setFieldValue('title', content);
  };

  const handleDescriptionChange = (content) => {
    formik.setFieldValue('description', content);
  };

  const buttonStyle = {
    color: '#20a8d8',
  }

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1); // Remove the row at the specified index
    setRows(updatedRows);
    // Update Formik values
    const updatedOptions = { ...formik.values.options };
    delete updatedOptions[index]; // Delete the option at the specified index
    formik.setFieldValue('options', updatedOptions);
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog" >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Add Menu</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="title">Title</label>
              {/* <ReactQuill
                value={formik.values.title}
                onChange={handleTitleChange}
                theme="snow"
              /> */}
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Enter Menu Name"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="on"
              />
            </div>
            {formik.touched.title && formik.errors.title && (
              <div className="error m-1">{formik.errors.title}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="description">Description</label>
              <ReactQuill
                value={formik.values.description}
                onChange={handleDescriptionChange}
                theme="snow"
              />
            </div>
            {formik.touched.description && formik.errors.description && (
              <div className="error m-1">{formik.errors.description}</div>
            )}
          </div>
          Nutrition:
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16" onClick={handleAddField}>
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            <title>Add new Nutrition field</title>
          </svg> */}
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="fat">Fat</label>
              <input
                type="text"
                className="form-control"
                id="nutrition.fat"
                name="nutrition.fat"
                placeholder='Enter Fat with its Unit'
                value={formik.values.nutrition.fat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="energy">Energy</label>
              <input
                type="text"
                className="form-control"
                id="nutrition.energy"
                name="nutrition.energy"
                placeholder='Enter Energy with its Unit'
                value={formik.values.nutrition.energy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="carbohydrate">Carbohydrate</label>
              <input
                type="text"
                className="form-control"
                id="nutrition.carbohydrate"
                name="nutrition.carbohydrate"
                placeholder='Enter Carbs with its Unit'
                value={formik.values.nutrition.carbohydrate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="saturated">Saturated</label>
              <input
                type="text"
                className="form-control"
                id="nutrition.saturated"
                name="nutrition.saturated"
                placeholder='Enter Saturation with its Unit'
                value={formik.values.nutrition.saturated}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              className="form-control"
              id="price"
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.price && formik.errors.price && (
              <div className="error m-1">{formik.errors.price}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="categoryId">Category</label>
            <select
              className="form-control"
              id="categoryId"
              name="categoryId"
              value={formik.values.categoryId}
              onChange={(event) => {
                const categoryId = parseInt(event.target.value); // Convert to number
                formik.handleChange(event); // Handle change
                formik.setFieldValue('categoryId', categoryId); // Set the value in Formik state
              }}
              onBlur={formik.handleBlur}
            >
              <option value="" disabled>Select a category</option>

              {(categories?.categories != undefined && categories?.categories.length > 0) ? (
                filteredCategoryData.map((category) => (
                  <option key={category.idCategory} value={category.idCategory}>{category.title}</option>
                ))
              ) : (
                <option value="" disabled>No categories available</option>
              )}
            </select>
            {formik.touched.categoryId && formik.errors.categoryId && (
              <div className="error m-1">{formik.errors.categoryId}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="ingredientIds">Ingredients</label>

            <Autocomplete
              multiple
              id="ingredientIds"
              options={filteredIngredientsData || []}
              getOptionLabel={(ingredient) => ingredient.title}
              value={selectedIngredients}
              onChange={(_, newValue) => {
                const ingredientIdsArray = newValue.map(ingredient => ingredient.idIngredient);
                setSelectedIngredients(newValue);
                formik.setFieldValue('ingredientIds', ingredientIdsArray);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select ingredients"
                />
              )}
            />
            {formik.touched.ingredientIds && formik.errors.ingredientIds && (
              <div className="error m-1">{formik.errors.ingredientIds}</div>
            )}
          </div>

          {/* options code start */}
          <a style={buttonStyle} onClick={() => { setRows([...rows, { title: '', price: '' }]); }}>+ Add Options</a>
          {/* New rows */}
          {rows.map((row, index) => (
            <div className="form-row" key={index}>
              <div className="form-group col-md-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder='Enter Option name'
                  id='options.title'
                  name={`options[${index}].title`} // Use array notation to set dynamic field name
                  value={formik.values.options[index]?.title || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group col-md-5">
                <input
                  type="number"
                  className="form-control"
                  placeholder='Enter Option Price'
                  id='options.price'
                  name={`options[${index}].price`} // Use array notation to set dynamic field name
                  value={formik.values.options[index]?.price || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className="form-group col-md-2">
                <button type="button" className="mb-4 ml-2 btn btn-outline-secondary" onClick={() => handleDeleteRow(index)}>
                  Delete Row
                </button>
              </div>
            </div>
          ))}
          {/* options code end */}
          <div className="form-group">
            <label htmlFor="imageFile">Image</label>
            <input
              type="file"
              className="form-control"
              id="imageFile"
              name="imageFile"
              accept="image/*"
              onChange={handleImageChange}
            />
            {imageError && <div className="error m-1">{imageError}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" type="submit">Add</button>
          <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
        </DialogActions>
      </form>
    </Dialog >
  );
};

export default AddMenu;
