import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { deleteUser, getUsers } from '../../features/users/users.slice';

export const Delete = ({ open, onClose, userId, userData, userEmail }) => {
    const dispatch = useDispatch();
    const handleConfirmDeleteUser = async () => {
        onClose();
        const response = await dispatch(deleteUser(userId));

        if (response.payload.status) {
            toast.success(response.payload.message);
            dispatch(getUsers(userData));

        } else {
            toast.error(response.payload.message);
        }
    };
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal">
            <DialogTitle>Delete User</DialogTitle>
            <DialogContent>
                Are you sure you want to delete "<strong>{userEmail}</strong>" User?
            </DialogContent>
            <DialogActions>
                <button className="btn btn-primary" onClick={handleConfirmDeleteUser}>Delete</button>
                <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
            </DialogActions>
        </Dialog>
    )
}

export default Delete;