import React from 'react';

// PageLimit component displays a dropdown to select the number of items to display per page in a table.
// The component receives a 'changeLimit' prop, which is a function to handle the change event when the user selects a different limit.
const PageLimit = ({ changeLimit }) => {
  return (
    <div className="col-sm-12 col-md-6">
      <div className="dataTables_length" id="dataTable_length">
        <label>
          {/* The select element allows the user to choose the number of items to display per page. */}
          <select
            name="dataTable_length"
            aria-controls="dataTable"
            className="custom-select custom-select-sm form-control form-control-sm"
            // When the user selects a different option, the 'changeLimit' function is called with the event as the argument.
            onChange={(e) => changeLimit(e)}
          >
            {/* Options for different page limits */}
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </label>
      </div>
    </div>
  );
};

export default PageLimit;
