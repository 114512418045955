import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import * as yup from "yup";
import { getClass, getClassesList, updateClasses } from '../../features/classes/classes.slice';

export const EditClasses = ({ open, onClose, classData, classesId, schoolData }) => {
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        className: yup.string().required('Class name is required'),
        schoolId: yup.string().required('School name is required')
    });

    // Fetch Classes data by ID using getClasses API
    useEffect(() => {
        if (classesId) {
            dispatch(getClass(classesId));
        }
    }, [classesId]);

    const classes = useSelector((state) => state.classes.getClass);

    const formik = useFormik({
        initialValues: {
            className: classes?.data?.schoolclass?.className || '',
            schoolId: classes?.data?.schoolclass?.schoolId || '',
        },
        validationSchema: schema,
        enableReinitialize: true,

        onSubmit: async (values) => {
            try {
                const postData = {
                    idClass: classesId,
                    idSchool: schoolId,
                    ...values,
                };

                const response = await dispatch(updateClasses(postData));

                if (response.payload.status) {
                    toast.success(response.payload.message);
                    dispatch(getClassesList(classData));
                    onClose();
                } else {
                    toast.error(response.payload.message);
                }
            } catch (error) {
                // Handle error if necessary
                console.log(error)
                toast.error("Error submitting the form.", error);
            }
        },
    });
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Edit FAQ</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="className">class Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="className"
                                placeholder="Enter class Name"
                                name="className"
                                value={formik.values.className}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.className && formik.errors.className && (
                            <div className="error m-1">{formik.errors.className}</div>
                        )}
                    </div>

                    {/* School Dropdown */}
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="schoolId">School Name</label>
                            <select
                                className="form-control"
                                id="schoolId"
                                name="schoolId"
                                value={formik.values.schoolId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <option value="" disabled>Select a School</option>
                                {(schoolData.school || []).map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.schoolName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {formik.touched.schoolId && formik.errors.schoolId && (
                            <div className="error m-1">{formik.errors.schoolId}</div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Edit</button>
                    <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditClasses;