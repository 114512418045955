import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import DefaultPagination from '../../config/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getClassesList, updateActiveInactive } from '../../features/classes/classes.slice';
import AddClasses from './Add';
import EditClasses from './Edit';
import DeleteClasses from './Delete';
import ViewClass from './View';
import { getSchoolList } from '../../features/schools/schools.slice';

export const ClassesList = () => {
    // let classesData = {
    //     pageNumber: DefaultPagination.PAGE_NUMBER,
    //     pageSize: DefaultPagination.PAGE_SIZE,
    // };

    // Use state to manage Class Data in pagination and search 
    const [classesData, setClassesData] = useState({
        pageSize: DefaultPagination.PAGE_SIZE,
        pageNumber: DefaultPagination.PAGE_NUMBER,
        key: "",
        value: "",
    });

    let schoolsData = {
        pageNumber: 1,
        pageSize: 100,
    };

    const dispatch = useDispatch();
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewingClassesId, setViewingClassesId] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletingClassesId, setDeletingClassesId] = useState('');
    const [openEditClassesDialog, setOpenEditClassesDialog] = useState(false);
    const [editingClassesId, setEditingClassesId] = useState('');
    const [openAddClassesDialog, setOpenAddClassesDialog] = useState(false);
    const [searchBy, setSearchBy] = useState("");
    // const [loading, setLoading] = useState(true);


    // Use useEffect to fetch Classes when pageSize or pageNumber changes
    useEffect(() => {
        dispatch(getClassesList(classesData));
        // setTimeout(() => {
        //     setLoading(false);
        //   }, 1000);
    }, [classesData.pageNumber, classesData.pageSize]);

    // Get Classes data from Redux store
    const { classes } = useSelector((state) => state.classes);


    useEffect(() => {
        dispatch(getSchoolList(schoolsData));
    }, [schoolsData.pageNumber, schoolsData.pageSize]);

    // Get Classes data from Redux store
    const { schools } = useSelector((state) => state.schools);
    // Create a mapping for schoolId to schoolName
    const schoolMap = (schools.school || []).reduce((acc, school) => {
        acc[school.id] = school.schoolName;
        return acc;
    }, {});

    // Function to handle opening the Add Class dialog
    const handleOpenAddClassesDialog = () => {
        setOpenAddClassesDialog(true);
    };

    // Function to handle closing the Add Class dialog
    const handleCloseAddClassesDialog = () => {
        setOpenAddClassesDialog(false);
    };

    // Function to handle closing the Edit Class dialog
    const handleOpenEditClassesDialog = (id) => {
        setEditingClassesId(id);
        setOpenEditClassesDialog(true);
    }

    // Function to handle delete Class click
    const handleDeleteclasses = (id) => {
        setDeletingClassesId(id);
        setOpenDeleteDialog(true);
    };

    // Open view modal
    const handleViewClass = (id) => {
        setViewingClassesId(id);
        setOpenViewDialog(true);
    }

    // Function to change the Classes Status
    const handleStatusChange = async (id, classStatus) => {

        const response = await dispatch(updateActiveInactive({ id, classStatus }));

        if (response.payload.status) {
            toast.success(response.payload.message);
            dispatch(getClassesList(classesData));
        } else {
            toast.error(response.payload.message);
        }
    }

    // Function to handle change in page size dropdown
    const changeTableData = (e) => {
        const newSize = parseInt(e.target.value);
        setClassesData((prevData) => ({
            ...prevData,
            pageSize: newSize,
            pageNumber: DefaultPagination.PAGE_NUMBER,
        }));

        dispatch(getClassesList({
            ...classesData,
            pageSize: newSize,
            pageNumber: DefaultPagination.PAGE_NUMBER,
        }));

        // setClassesData((prevUserData) => ({ ...prevUserData, pageSize: parseInt(e.target.value) }));
        // dispatch(getClassesList(classesData));
    };
    let searchTimeout;

    // Function to handle search as the user types in the search input field
    const handleSearchClasses = (key) => {

        const keyword = key.target.value;

        if (searchTimeout) clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
            setClassesData((prevData) => ({
                ...prevData,
                value: keyword,
                pageNumber: DefaultPagination.PAGE_NUMBER,
            }));

            dispatch(getClassesList({
                ...classesData,
                key: searchBy,
                value: keyword,
                pageNumber: DefaultPagination.PAGE_NUMBER,
            }));
        }, 300);
    };

    // Function to handle pagination click with a specific page number
    const paginationWithPage = (pageNumber) => {
        setClassesData((prevData) => ({
            ...prevData,
            pageNumber,
        }));

        dispatch(getClassesList({
            ...classesData,
            pageNumber,
        }));
    };

    // Update searchBy when dropdown value changes
    const handleSearchByChange = (event) => {
        const selectedValue = event.target.value;

        setSearchBy(selectedValue);
        setClassesData((prevData) => ({
            ...prevData,
            key: selectedValue,
            // value: "", 
            // pageNumber: DefaultPagination.PAGE_NUMBER,
        }));

    };

    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Classes
                    </li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Class List
                    </h1>
                    <a>
                        <span className="text">
                            <button className="btn btn-primary" onClick={handleOpenAddClassesDialog}>+ Add Classes</button>
                        </span>
                    </a>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                {/* Page size dropdown */}
                                <PageLimit changeLimit={(e) => changeTableData(e)} />
                                {/* <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            <input
                                                type="search"
                                                placeholder="search Classes"
                                                className="form-control form-control-sm"
                                                aria-controls="dataTable"
                                                onChange={handleSearchClasses} // Dynamically trigger search on input change
                                            />
                                        </label>
                                    </div>
                                </div> */}
                                <div className="col-sm-12 col-md-6 row">
                                    <div className="mb-3 col-md-8 col-sm-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <label htmlFor="searchBySelect" className="form-label ">
                                            Search By
                                        </label>
                                        <select id="searchBySelect" className="form-select"
                                            value={classesData.key} onChange={handleSearchByChange}
                                        >
                                            <option value="">select option</option>
                                            <option value="className">Class Name</option>
                                            <option value="schoolName">School Name</option>
                                        </select>
                                    </div>


                                    <div id="dataTable_filter" className="dataTables_filter col-md-4 col-sm-6">
                                        <label>{/* Search input */}
                                            <input type="search" placeholder="search Inqueries" className="form-control form-control-sm" aria-controls="dat aTable" onChange={handleSearchClasses} />
                                        </label>
                                    </div>
                                </div>

                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">Classes</th>
                                            <th scope="col">School</th>
                                            <th scope="col">Created At</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Change Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Render Inquiries data */}
                                        {
                                            (classes?.schoolclass != undefined && classes?.schoolclass.length > 0) ?
                                                classes?.schoolclass.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td scope="row">{index + 1}</td> */}
                                                            <td>{item.className}</td>
                                                            <td>{schoolMap[item.schoolId] || "N/A"}</td>
                                                            <td>{item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-GB') : 'N/A'}</td>
                                                            <td>{item.status ? 'Active' : 'Inactive'}</td>
                                                            <td className="tdTextCenter">
                                                                <div className="custom-control custom-checkbox small">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        htmlFor="customCheck"
                                                                        id="customCheck"
                                                                        name={['isChecked' + index]}
                                                                        checked={item.status}
                                                                        onChange={() => handleStatusChange(item.id, item.status)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    className="actionIcon"
                                                                    title="Edit"
                                                                    onClick={() => handleOpenEditClassesDialog(item.id)}
                                                                >
                                                                    <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                                                </a>
                                                                <a
                                                                    className="actionIcon"
                                                                    title="Delete"
                                                                    onClick={() => handleDeleteclasses(item.id)}
                                                                >
                                                                    <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                                </a>
                                                                <a
                                                                    className="actionIcon"
                                                                    title="View"
                                                                    onClick={() => handleViewClass(item.id)} // Handle view click
                                                                >
                                                                    <span><img src="assets/img/svg/eye-icon.png" alt="View" /></span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) :
                                                // Render "No records found" if there are no Class
                                                <tr>
                                                    <td colSpan="8" className="noRecordFound">No records found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* Pagination component */}
                            <Pagination
                                totalPage={classes?.pagination?.totalPages || 1}
                                currentPage={classes?.pagination?.currentPage || 1}
                                fullPagination={paginationWithPage}
                                totalFaqs={classes?.pagination?.totalMenus || 1}
                                pageSize={classes?.pagination?.pageSize || 10}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit Classes dialog */}
            <EditClasses
                open={openEditClassesDialog}
                onClose={() => setOpenEditClassesDialog(false)}
                classData={classesData}
                classesId={editingClassesId}
                schoolData={schools}
            />

            {/* View Classes dialog */}
            <ViewClass
                open={openViewDialog}
                onClose={() => setOpenViewDialog(false)}
                classId={viewingClassesId}
                schoolData={schools}

            />

            {/* Delete Class dialog */}
            <DeleteClasses
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                classId={deletingClassesId}
                classData={classesData}
            />
            {/* Add Classes dialog */}
            <AddClasses
                open={openAddClassesDialog}
                onClose={handleCloseAddClassesDialog}
                classesData={classesData}
                schoolData={schools}
            />
        </>
    )
}

export default ClassesList;