import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import MenuService from "./menus.services";

export const getMenus = createAsyncThunk(
  "menus/get-menus",
  async (menuData, thunkAPI) => {
    try {
      return await MenuService.getMenus(menuData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const createMenu = createAsyncThunk(
  "menus/create-menu",
  async (menuData, thunkAPI) => {
    try {
      return await MenuService.createMenu(menuData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateMenu = createAsyncThunk(
  "menus/update-menu",
  async (menu, thunkAPI) => {
    try {
      return await MenuService.updateMenu(menu);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateActiveInactive = createAsyncThunk(
  "menus/update-active-inactive",
  async (data, thunkAPI) => {

    try {
      return await MenuService.updateActiveInactive(data);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const deleteMenu = createAsyncThunk(
  "menus/delete-menu",
  async (id, thunkAPI) => {
    try {
      return await MenuService.deleteMenu(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const getMenu = createAsyncThunk(
  "menus/get-product-menu",
  async (id, thunkAPI) => {

    try {
      return await MenuService.getMenu(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const searchMenus = createAsyncThunk(
  "menus/search-menus",
  async (menu, thunkAPI) => {
    try {
      return await MenuService.searchMenus(menu);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const resetState = createAction("RevertAll");

const initialState = {
  menus: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const MenuSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMenus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMenus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.menus = action.payload;
      })
      .addCase(getMenus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdMenu = action.payload;
      })
      .addCase(createMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedMenu = action.payload;
      })
      .addCase(updateMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.deletedMenu = action.payload.message;
      })
      .addCase(deleteMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.getMenu = action.payload.data;
        state.categoryName = action.payload.data.title;
      })
      .addCase(getMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateActiveInactive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateActiveInactive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload.message

      })
      .addCase(updateActiveInactive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(searchMenus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchMenus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.menus = action.payload
        state.message = action.payload.message

      })
      .addCase(searchMenus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default MenuSlice.reducer;
