import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getschools = async (schools) => {
    const headers = config();
    const response = await axios.post(`${base_url}getSchool?search=${schools.search || ''}&pageNumber=${schools.pageNumber || ''}&pageSize=${schools.pageSize || ''}`, {}, headers);
    return response.data.data;
};

const getSchool = async (id) => {
    const headers = config();
    const response = await axios.get(`${base_url}getSchoolById/${id}`, headers);

    return response.data;
};

const deleteSchool = async (id) => {
    const headers = config();
    const requestConfig = { ...headers };
    const requestData = { id: [id] }

    const response = await axios.post(`${base_url}deleteSchool`, requestData, requestConfig);
    return response.data;
};

const updateSchool = async (schoolData) => {
    const headers = config();
    const { schoolAddress, schoolName, email } = schoolData;

    const updateSchoolData = {
        schoolAddress,
        schoolName,
        email,
    };

    const response = await axios.patch(`${base_url}updateSchool/${schoolData.idSchool}`, updateSchoolData, headers);

    return response.data;
}

const createSchool = async (school) => {
    const headers = config();
    const { schoolName, schoolAddress, email, status } = school;

    const schoolData = {
        schoolName,
        schoolAddress,
        status,
        email
    };

    const response = await axios.post(`${base_url}addSchool/`, schoolData, headers);

    return response.data;
};

const updateActiveInactive = async (data) => {
    const headers = config();
    let { id, schoolStatus } = data
    schoolStatus = schoolStatus === true ? false : true;

    const updateSchoolData = {
        status: schoolStatus
    }
    const response = await axios.patch(`${base_url}updateSchool/${id}`, updateSchoolData, headers);
    return response.data;
};
const SchoolService = {
    getschools,
    getSchool,
    deleteSchool,
    updateSchool,
    createSchool,
    updateActiveInactive
};

export default SchoolService;