import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getProductionList = async ({ schoolId, date }) => {
    const headers = config();
    const response = await axios.get(`${base_url}getProductionlist?schoolId=${schoolId}&deliveryDate=${date}`, headers);
    return response.data;
};

const getDistributionList = async ({ schoolId, date }) => {
    const headers = config();
    const response = await axios.get(`${base_url}getDistributionlist?schoolId=${schoolId}&deliveryDate=${date}`, headers);

    return response.data;
};

const getScheduledList = async (scheduleData) => {
    const headers = config();
    const response = await axios.get(`${base_url}schedule/getAllSchedules/?pageNumber=${scheduleData.pageNumber}&pageSize=${scheduleData.pageSize}`, headers);

    return response.data;
}

const deleteSchedule = async (id) => {
    const headers = config();
    const response = await axios.delete(`${base_url}schedule/deleteSchedule/${id}`, headers);
    return response.data;
}

const createSchedule = async (data) => {
    const headers = config();
    data.subject = 'Test schedule';
    data.message = 'Hello Dosto Thanks';
    data.dayChecked = data.dayChecked.join(',');
    // data.selectedEmails = data.selectedEmails.join(',');
    data.selectedEmails = 'anurag.p@mailinator.com';
    data.status = 1;

    const response = await axios.post(`${base_url}schedule/createSchedule/`, data, headers);

    return response.data;
}


const ProductionServies = {
    getProductionList,
    getDistributionList,
    getScheduledList,
    deleteSchedule,
    createSchedule,
};

export default ProductionServies;