import React, { Fragment } from 'react';
import LogoutModal from '../modals/LogoutModal';

const Footer = () => {

    return (
        <Fragment>
            {/* <!-- Footer --> */}
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; Your Website 2024</span>
                    </div>
                </div>
            </footer>
            {/* <!-- End of Footer --> */}

            {/* <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>

            <LogoutModal />
        </Fragment>

    );
};

export default Footer;
