import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getInqueries = async (inqueryData) => {
    const headers = config();
    const requestConfig = { ...headers };
    requestConfig.params = {
        search: inqueryData.search,
        pageSize: inqueryData.pageSize,
        pageNumber: inqueryData.pageNumber,
    };

    const response = await axios.get(`${base_url}getinquiry/`, requestConfig);

    return response.data;
};

const deleteInquery = async (id) => {
    const headers = config();
    const requestConfig = { ...headers };
    const requestData = { id: [id] }

    const response = await axios.post(`${base_url}deleteInquiry`, requestData, requestConfig);
    return response.data;
};
const InqueryService = {
    getInqueries,
    deleteInquery
};

export default InqueryService;