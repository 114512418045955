import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateFaq, getFaqs, getFaq } from "../../features/faqs/faqs.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const schema = yup.object().shape({
  question: yup
    .string()
    .trim() // Trim the value to remove leading and trailing spaces
    .test("is-not-empty", "Question is Required!", (value) => {
      if (typeof value !== 'string') return false; // Check if value is a string
      const strippedValue = value.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
      return strippedValue !== "" && /\S/.test(strippedValue); // Check if the stripped value contains any non-whitespace character
    }),
  answer: yup
    .string()
    .trim() // Trim the value to remove leading and trailing spaces
    .test("is-not-empty", "Answer is Required!", (value) => {
      if (typeof value !== 'string') return false; // Check if value is a string
      const strippedValue = value.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
      return strippedValue !== "" && /\S/.test(strippedValue); // Check if the stripped value contains any non-whitespace character
    }),
  videoLink: yup.string().url("Invalid Video Link"),
  referenceLink: yup.string().url("Invalid Reference Link"),
});

const EditFaq = ({ open, onClose, faqData, faqId }) => {
  const dispatch = useDispatch();

  // Fetch FAQ data by ID using getFaq API
  useEffect(() => {
    dispatch(getFaq(faqId));
  }, [faqId, dispatch]);

  // Get FAQ data from Redux store
  const faq = useSelector((state) => state.faqs.getFaq);

  const formik = useFormik({
    initialValues: {
      question: faq?.question || '',
      answer: faq?.answer || '',
      videoLink: faq?.videoLink || '',
      referenceLink: faq?.referenceLink || '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const postData = {
          idFaq: faqId,
          ...values,
        };

        const response = await dispatch(updateFaq(postData));

        if (response.payload.status) {
          toast.success(response.payload.message);
          dispatch(getFaqs(faqData));
          onClose();
        } else {
          toast.error(response.payload.message);
        }
      } catch (error) {
        // Handle error if necessary
        console.log(error)
        toast.error("Error submitting the form.", error);
      }
    },
  });

  const handleQuestionChange = (content) => {
    formik.setFieldValue('question', content);
  };

  const handleAnswerChange = (content) => {
    formik.setFieldValue('answer', content);
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit FAQ</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="question">Question</label>
              <ReactQuill
                value={formik.values.question}
                onChange={handleQuestionChange}
                theme="snow"
                modules={{
                  toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                  ],
                }}
              />
            </div>
            {formik.touched.question && formik.errors.question && (
              <div className="error m-1">{formik.errors.question}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="answer">Answer</label>
              <ReactQuill
                value={formik.values.answer}
                onChange={handleAnswerChange}
                theme="snow"
                modules={{
                  toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                  ],
                }}
              />
            </div>
            {formik.touched.answer && formik.errors.answer && (
              <div className="error m-1">{formik.errors.answer}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="videoLink">Video Link (Optional)</label>
              <input
                type="text"
                className="form-control"
                id="videoLink"
                placeholder="Enter video link"
                name="videoLink"
                value={formik.values.videoLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="on"
              />
            </div>
            {formik.touched.videoLink && formik.errors.videoLink && (
              <div className="error m-1">{formik.errors.videoLink}</div>
            )}
          </div>

          <div className="form-group">
            <div className="form-floating">
              <label htmlFor="referenceLink">Reference Link (Optional)</label>
              <input
                type="text"
                className="form-control"
                id="referenceLink"
                placeholder="Enter reference link"
                name="referenceLink"
                value={formik.values.referenceLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="on"
              />
            </div>
            {formik.touched.referenceLink && formik.errors.referenceLink && (
              <div className="error m-1">{formik.errors.referenceLink}</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" type="submit">Edit</button>
          <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditFaq;

