import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { DatePicker, Space } from "antd";
import commonFunction from "../../utils/commonFunction";

Chart.register(...registerables);

const MyBarChart = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totlatValue, setTotlatValue] = useState("");
    const [selectedDate, setSelectedDate] = useState({
        start: startDate,
        end: endDate,
    });
    const [isDateFiltered, setIsDateFiltered] = useState(false);
    const chartRef = useRef(null);

    const getWeekRange = () => {
        const today = moment();
        const startOfWeek = today.clone().startOf("isoWeek").format("YYYY-MM-DD");
        const endOfWeek = today.clone().endOf("isoWeek").format("YYYY-MM-DD");
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        setSelectedDate({
            start: startOfWeek,
            end: endOfWeek,
        });
    };

    useEffect(() => {
        getWeekRange();
    }, []);

    const [data, setData] = useState({
        labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        datasets: [
            {
                label: "Weekly Sales",
                data: [12, 19, 3, 5, 2],
                backgroundColor: "rgba(0, 159, 221)",
                borderColor: "rgba(75,192,192,1)",
                borderWidth: 1,
            },
        ],
    });

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
        },
        hover: {
            mode: null,
        },
        animation: {
            onComplete: () => {
                const chart = chartRef.current;
                if (chart) {
                    const ctx = chart.ctx;
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    ctx.fillStyle = "white";
                    ctx.font = "bold 14px Arial";

                    chart.data.datasets.forEach((dataset, i) => {
                        const meta = chart.getDatasetMeta(i);
                        meta.data.forEach((bar, index) => {
                            const value = dataset.data[index];
                            const xPos = bar.x;
                            const yPos = bar.y + bar.height / 2;
                            ctx.fillText(value, xPos, yPos);
                        });
                    });
                }
            },
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedDate.start !== "") {
                    const response = await commonFunction.getWeeklyStats(selectedDate);
                    const labels = response?.data?.orders?.map((order) => {
                        const dayOfWeek = moment(order.order_date).format("ddd");
                        const fullDate = moment(order.order_date).format("DD/MM/YYYY");
                        return `${dayOfWeek}\n${fullDate}`;
                    });
                    const dataValues = response?.data?.orders?.map(
                        (order) => order.count
                    );
                    const totalValue = dataValues?.reduce(
                        (total, count) => total + parseInt(count),
                        0
                    );
                    setTotlatValue(totalValue);

                    setData({
                        labels: labels,
                        datasets: [
                            {
                                label: "Weekly Sales",
                                data: dataValues,
                                backgroundColor: "rgba(0, 159, 221)",
                                borderColor: "rgba(75,192,192,1)",
                                borderWidth: 1,
                            },
                        ],
                    });
                }
            } catch (error) {
                console.error("Error fetching weekly stats:", error);
            }
        };

        fetchData();
    }, [selectedDate, totlatValue, isDateFiltered]);

    const handleDateChange = (dates, dateStrings) => {
        const obj = {
            start: dateStrings[0],
            end: dateStrings[1],
        };
        setSelectedDate(obj);
        setIsDateFiltered(!!(dateStrings[0] && dateStrings[1]));
    };

    return (
        <div className="col-xl-12 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
                <div className="float-right"></div>
                <Space direction="vertical" className="m-3" style={{ width: "100%" }}>
                    <DatePicker.RangePicker onChange={handleDateChange} />
                </Space>
                <div className="card-body">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="font-weight-bold text-uppercase mb-1 row">
                                <div className="col-md-9">Order Overview</div>
                                <div className="col-md-3">
                                    total for the period:{" "}
                                    <b>{totlatValue !== "" ? totlatValue : "0"}</b>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <Bar ref={chartRef} data={data} options={options} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyBarChart;
