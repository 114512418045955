import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { createMenu, getMenus, getMenu, updateMenu } from "../../features/menus/menus.slice";
import { getActiveCategories, getCategories } from "../../features/categories/categories.slice";
import { getActiveIngredients, getIngredients } from "../../features/ingredients/ingredients.slice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { base_url } from '../../utils/baseUrl';
import { config } from '../../utils/axiosconfig';
import commonFunction from '../../utils/commonFunction';

const schema = yup.object().shape({
    title: yup
        .string()
        .trim() // Trim the value to remove leading and trailing spaces
        .test("is-not-empty", "Title is Required!", (value) => {
            if (typeof value !== 'string') return false; // Check if value is a string
            const strippedValue = value.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
            return strippedValue !== "" && /\S/.test(strippedValue); // Check if the stripped value contains any non-whitespace character
        }),
    description: yup
        .string()
        .trim() // Trim the value to remove leading and trailing spaces
        .test("is-not-empty", "Description is Required!", (value) => {
            if (typeof value !== 'string') return false; // Check if value is a string
            const strippedValue = value.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
            return strippedValue !== "" && /\S/.test(strippedValue); // Check if the stripped value contains any non-whitespace character
        }),
    price: yup
        .number()
        .required("Price is Required!")
        .positive("Price must be a positive number")
        .test("is-decimal", "Price must have up to 2 decimal places", (value) => {
            if (!isNaN(value)) {
                return /^(\d+\.\d{1,2}|\.\d{1,2}|\d+)$/.test(value);
            }
            return false;
        }),
    categoryId: yup.number().required("Category is Required!"),
    ingredientIds: yup.array().of(yup.number()).min(1, "At least one ingredient is required"),
});

const EditMenu = ({ open, onClose, menuItemData, itemId }) => {
    const dispatch = useDispatch();

    let categoryData = {
        pageSize: '',
        pageNumber: '',
    };

    let ingredientData = {
        pageSize: 50,
        pageNumber: 1
    };
    // Fetch Menu data by ID using getMenu API
    useEffect(() => {
        if (itemId) {
            dispatch(getMenu(itemId));
        }
    }, [itemId, dispatch]);

    // Get Menu data from Redux store

    const [imageFile, setImageFile] = useState(null);
    const [imageError, setImageError] = useState("");
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    useEffect(() => {
        dispatch(getCategories(categoryData));
        dispatch(getIngredients(ingredientData));
    }, [dispatch]);

    const menu = useSelector((state) => state.menus.getMenu);

    // Initialize selected ingredients based on menu data
    useEffect(() => {
        if (menu && menu.Ingredients) {
            const initialSelectedIngredients = menu.Ingredients.map(ingredient => ({
                idIngredient: ingredient.idIngredient,
                title: ingredient.title
            }));
            setSelectedIngredients(initialSelectedIngredients);
        }
    }, [menu]);

    // const categories = useSelector((state) => state.categories?.categories?.data);
    const categories = useSelector((state) => state.categories?.categories?.data);
    const filteredCategoryData = categories?.categories?.filter(item => item.status === 1);

    // const ingredients = useSelector((state) => state.ingredients.ingredients.data?.ingredients);
    const ingredients = useSelector((state) => state.ingredients?.ingredients?.data);
    const filteredIngredientsData = ingredients?.ingredients?.filter(item => item.status === 1);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            setImageError("");
        }
    };

    const formik = useFormik({
        initialValues: {
            idMenu: itemId,
            title: menu?.title || '',
            description: menu?.description || '',
            price: menu?.price || '',
            categoryId: menu?.categoryId || '',
            nutrition: menu?.nutrition || null,
            ingredientIds: menu?.Ingredients?.map((ingredient) => ingredient.idIngredient) || '',
            options: menu?.Options || [],
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {

            try {
                const imageData = new FormData();
                if (imageFile) {

                    imageData.append("image", imageFile);
                }
                imageData.append("title", values.title ? values.title : '');
                imageData.append("description", values.description);
                imageData.append("nutrition", values.nutrition ? JSON.stringify(values.nutrition) : null);
                imageData.append("categoryId", values.categoryId);
                imageData.append("ingredientIds", values.ingredientIds ? JSON.stringify(values.ingredientIds) : null);
                imageData.append("price", values.price);

                const updatePictureEndpoint = `${base_url}/updateMenu/${itemId}`;
                const apiConfig = config();
                const uploadResponse = await fetch(updatePictureEndpoint, {
                    method: "PATCH",
                    headers: {
                        ...apiConfig.headers,
                    },
                    body: imageData,
                });

                const data = await uploadResponse.json();

                // const imageUrl = data.data.menuImage;
                // values.imageUrl = imageUrl;

                // const response = await Promise.all([
                //     dispatch(updateMenu(values)),
                // ]);

                if (data.status) {

                    const optionsPromises = Object.values(values?.options)?.map(async (item) => {
                        const optionRes = await commonFunction.updateOptions(item);
                        return optionRes;
                    });

                    // Wait for all promises to resolve
                    await Promise.all(optionsPromises);

                    toast.success(data.message);
                    dispatch(getMenus(menuItemData));
                    onClose();

                    formik.resetForm();
                    setImageFile(null);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                toast.error("Error submitting the form." + error);
            }
        },
    });

    const handleTitleChange = (content) => {
        formik.setFieldValue('title', content);
    };

    const handleDescriptionChange = (content) => {
        formik.setFieldValue('description', content);
    };

    const buttonStyle = {
        color: '#20a8d8',
    }

    return (

        <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Edit Menu</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="title">Title</label>
                            {/* <ReactQuill
                                value={formik.values.title}
                                onChange={handleTitleChange}
                                theme="snow"
                            /> */}
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Enter Menu Name"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.title && formik.errors.title && (
                            <div className="error m-1">{formik.errors.title}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="description">Description</label>
                            <ReactQuill
                                value={formik.values.description}
                                onChange={handleDescriptionChange}
                                theme="snow"
                            />
                        </div>
                        {formik.touched.description && formik.errors.description && (
                            <div className="error m-1">{formik.errors.description}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="price">Price</label>
                        <input
                            type="number"
                            className="form-control"
                            id="price"
                            name="price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.price && formik.errors.price && (
                            <div className="error m-1">{formik.errors.price}</div>
                        )}
                    </div>

                    Nutrition:
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16" onClick={handleAddField}>
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            <title>Add new Nutrition field</title>
          </svg> */}
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="fat">Fat</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nutrition.fat"
                                name="nutrition.fat"
                                placeholder='Enter Fat with its Unit'
                                value={formik.values?.nutrition?.fat}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="energy">Energy</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nutrition.energy"
                                name="nutrition.energy"
                                placeholder='Enter Energy with its Unit'
                                value={formik.values?.nutrition?.energy}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="carbohydrate">Carbohydrate</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nutrition.carbohydrate"
                                name="nutrition.carbohydrate"
                                placeholder='Enter Carbs with its Unit'
                                value={formik.values?.nutrition?.carbohydrate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="saturated">Saturated</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nutrition.saturated"
                                name="nutrition.saturated"
                                placeholder='Enter Saturation with its Unit'
                                value={formik.values?.nutrition?.saturated}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="categoryId">Category</label>
                        <select
                            className="form-control"
                            id="categoryId"
                            name="categoryId"
                            value={formik.values.categoryId}
                            onChange={(event) => {
                                const categoryId = parseInt(event.target.value);
                                formik.handleChange(event);
                                formik.setFieldValue('categoryId', categoryId);
                            }}
                            onBlur={formik.handleBlur}
                        >
                            <option value="" disabled>Select a category</option>
                            {filteredCategoryData?.map((category) => (
                                <option key={category.idCategory} value={category.idCategory}>{category.title}</option>
                            ))}
                        </select>
                        {formik.touched.categoryId && formik.errors.categoryId && (
                            <div className="error m-1">{formik.errors.categoryId}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="ingredientIds">Ingredients</label>

                        <Autocomplete
                            multiple
                            id="ingredientIds"
                            options={filteredIngredientsData?.filter(ingredient => !selectedIngredients.some(selected => selected.idIngredient === ingredient.idIngredient)) || []}
                            getOptionLabel={(ingredient) => ingredient.title}
                            value={selectedIngredients}
                            onChange={(_, newValue) => {
                                const selectedIngredientObjects = filteredIngredientsData.filter(ingredient =>
                                    newValue.some(selected => selected.idIngredient === ingredient.idIngredient)
                                );
                                setSelectedIngredients(selectedIngredientObjects);
                                formik.setFieldValue('ingredientIds', selectedIngredientObjects.map(ingredient => ingredient.idIngredient));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select ingredients"
                                />
                            )}
                        />


                        {formik.touched.ingredientIds && formik.errors.ingredientIds && (
                            <div className="error m-1">{formik.errors.ingredientIds}</div>
                        )}
                    </div>

                    {/* options code start */}
                    <a style={buttonStyle}>Menu Options</a>
                    {/* New rows */}
                    {menu?.Options && menu?.Options?.length > 0 ? menu?.Options?.map((row, index) => (
                        <div className="form-row" key={index}>
                            <div className="form-group col-md-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='Enter Option name'
                                    id='options.title'
                                    name={`options[${index}].title`} // Use array notation to set dynamic field name
                                    value={formik?.values?.options[index]?.title || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="form-group col-md-5">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder='Enter Option Price'
                                    id='options.price'
                                    name={`options[${index}].price`} // Use array notation to set dynamic field name
                                    value={formik?.values?.options[index]?.price || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>

                            <div className="form-group col-md-2">
                                {/* <button type="button" className="mb-4 ml-2 btn btn-outline-secondary" onClick={() => handleDeleteRow(index)}>
                                    Delete Row
                                </button> */}
                            </div>
                        </div>
                    )) : (
                        <p>No options available</p>
                    )}
                    {/* options code end */}

                    <div className="form-group">
                        <label htmlFor="imageFile">Image</label>
                        <input
                            type="file"
                            className="form-control"
                            id="imageFile"
                            name="imageFile"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {imageError && <div className="error m-1">{imageError}</div>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Update</button>
                    <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditMenu