import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const updateActiveInactive = async (ingredientId) => {
  const headers = config();
  const response = await axios.patch(`${base_url}updateIngredientActiveStatus/${ingredientId}`, {}, headers);

  return response.data;
};

const getIngredients = async (ingredient) => {
  const headers = config();
  const requestConfig = { ...headers };

  if (ingredient && ingredient.pageSize && ingredient.pageNumber) {
    requestConfig.params = {
      pageSize: ingredient.pageSize,
      pageNumber: ingredient.pageNumber,
    };
  }

  const response = await axios.get(`${base_url}getIngredients/`, requestConfig);

  return response.data;
};

const createIngredient = async (ingredient) => {
  const headers = config();
  const response = await axios.post(`${base_url}addIngredients/`, ingredient, headers);

  return response.data;
};

const getIngredient = async (id) => {
  const headers = config();
  const response = await axios.get(`${base_url}getIngredientById/${id}`, headers);

  return response.data;
};

const deleteIngredient = async (id) => {
  const headers = config();
  const response = await axios.delete(`${base_url}deleteIngredient/${id}`, headers);

  return response.data;
};

const updateIngredient = async (ingredient) => {
  const headers = config();

  const response = await axios.patch(
    `${base_url}updateIngredient/${ingredient.idIngredient}`,
    {
      idIngredient: ingredient.idIngredient,
      title: ingredient.title,
      description: ingredient.description,
      imageUrl: ingredient.imageUrl,
      status: 1
    },
    headers
  );

  return response.data;
};

const searchIngredients = async (ingredient) => {
  const headers = config();
  const requestConfig = { ...headers };

  if (
    ingredient &&
    ingredient.pageSize &&
    ingredient.pageNumber
  ) {
    requestConfig.params = {
      search: ingredient.search ? ingredient.search : '',
      pageSize: ingredient.pageSize,
      pageNumber: ingredient.pageNumber,
    };
  }

  const response = await axios.get(`${base_url}getIngredients`, requestConfig);

  return response.data;
};

const getActiveIngredients = async () => {
  const headers = config();
  const response = await axios.get(`${base_url}ingredients/active`, headers);
  return response.data;
};

const IngredientService = {
  getIngredients,
  createIngredient,
  getIngredient,
  deleteIngredient,
  updateIngredient,
  updateActiveInactive,
  searchIngredients,
  getActiveIngredients
};

export default IngredientService;
