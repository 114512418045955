import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getContacts = async () => {
    const headers = config();
    const requestConfig = { ...headers };
    // requestConfig.params = {
    //     search: inqueryData.search,
    //     pageSize: inqueryData.pageSize,
    //     pageNumber: inqueryData.pageNumber,
    // };

    const response = await axios.get(`${base_url}contactus/`, requestConfig);

    return response.data;
};

const updateContact = async (contact) => {

    const headers = config();
    const { id, email, location, phone } = contact;

    const contactData = {
        id,
        email,
        location,
        phone,
    };

    const response = await axios.post(`${base_url}createContactUs`, contactData, headers);
    return response.data;
};

const ContactService = {
    getContacts,
    updateContact,
};

export default ContactService;