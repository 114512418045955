import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMenus } from '../../features/menus/menus.slice';
import { useFormik } from 'formik';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { getHotmeals, updateHotMeals } from '../../features/hotmeals/hotmeals.slice';

const EditMeal = ({ open, onClose, hotMealData, mealId }) => {

    let menuItemData = {
        pageSize: '',
        pageNumber: '',
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMenus(menuItemData));
    }, [dispatch])

    const menus = useSelector((state) => state.menus?.menus?.data);
    const filteredMenusData = menus?.menus?.filter(item => item.status === 1);

    const schema = yup.object().shape({
        hotMealDay: yup.string().required('Meal Day is required'),
        menuId: yup.string().required('Menu Item is required')
    });

    const formik = useFormik({
        initialValues: {
            hotMealDay: mealId?.hotMealDay ? mealId?.hotMealDay : '',
            menuId: mealId?.menuId ? mealId.menuId : '',
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {

                const postData = {
                    id: mealId.id,
                    ...values,
                };

                const response = await dispatch(updateHotMeals(postData));

                if (response.payload) {
                    toast.success(response.payload.message);
                    dispatch(getHotmeals(hotMealData));
                    onClose();

                    // Reset the form after successful submission
                    formik.resetForm();
                } else {
                    toast.error(response.payload.message);
                }
            } catch (error) {
                // Handle error if necessary
                toast.error("Error submitting the form.");
            }
        },
    });

    return (
        <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Edit FAQ</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-group">
                            <label htmlFor="hotmealDay">Hot Meal Day</label>
                            <select
                                className="form-control"
                                id="hotMealDay"
                                name="hotMealDay"
                                value={formik.values.hotMealDay}
                                onChange={(event) => {
                                    const hotMealDay = event.target.value;
                                    formik.handleChange(event); // Handle change
                                    formik.setFieldValue('hotMealDay', hotMealDay); // Set the value in Formik state
                                }}
                                onBlur={formik.handleBlur}
                            >
                                <option value="" disabled>Select a category</option>
                                <option value="Mon">Monday</option>
                                <option value="Tue">Tuesday</option>
                                <option value="Wed">Wednesday</option>
                                <option value="Thu">Thursday</option>
                                <option value="Fri">Friday</option>
                            </select>
                            {formik.touched.hotMealDay && formik.errors.hotMealDay && (
                                <div className="error m-1">{formik.errors.hotMealDay}</div>
                            )}
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-group">
                            <label htmlFor="hotmealDay">Hot Meal Day</label>
                            <select
                                className="form-control"
                                id="menuId"
                                name="menuId"
                                value={formik.values.menuId}
                                onChange={(event) => {
                                    const menuId = event.target.value;
                                    formik.handleChange(event); // Handle change
                                    formik.setFieldValue('menuId', menuId); // Set the value in Formik state
                                }}
                                onBlur={formik.handleBlur}
                            >
                                <option value="" disabled>Select a Menu</option>
                                {(menus?.menus != undefined && menus?.menus.length > 0) ? (
                                    filteredMenusData.map((category) => (
                                        <option key={category.idMenu} value={category.idMenu}>{category.title}</option>
                                    ))
                                ) : (
                                    <option value="" disabled>No categories available</option>
                                )}
                            </select>
                            {formik.touched.menuId && formik.errors.menuId && (
                                <div className="error m-1">{formik.errors.menuId}</div>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Edit</button>
                    <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditMeal