import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { getCategories, deleteCategory } from "../../features/categories/categories.slice";
import { toast } from "react-toastify";

const DeleteCategory = ({ open, onClose, categoryId, categoryData}) => {
  const dispatch = useDispatch();

  const handleConfirmDeleteCategory = async () => {
    onClose();
    const response = await dispatch(deleteCategory(categoryId));

    if (response.payload.status) {
        toast.success(response.payload.message);
        dispatch(getCategories(categoryData));

    } else {
      toast.error(response.payload.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal">
      <DialogTitle>Delete Category</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this category?
      </DialogContent>
      <DialogActions>
        <button className="btn btn-primary" onClick={handleConfirmDeleteCategory}>Delete</button>
        <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCategory;
