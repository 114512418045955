import React, { useState, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import commonFunction from '../../utils/commonFunction';
import { toast } from 'react-toastify';

function CensorList() {

    const componentPdf = useRef();
    const [tableData, setTableData] = useState([]);

    const [editedData, setEditedData] = useState([...tableData]); // State to hold the edited data

    const addRow = () => {
        const newRow = {
            id: editedData.length + 1,
            date: '2022-01-' + (editedData.length + 1),
            idMenu: 'Item ' + (editedData.length + 1),
            total: (editedData.length + 1) * 10,
        };

        setEditedData([...editedData, newRow]);
    };

    const handleInputChange = (e, rowIndex, field) => {
        const updatedData = editedData.map((row, index) =>
            index === rowIndex ? { ...row, [field]: e.target.value } : row
        );
        setEditedData(updatedData);
    };

    // function to save the censor data
    const handleCensorSave = async () => {
        // Access the updated data in the editedData state
        const response = commonFunction.saveCensorData(editedData);
        response.then((res) => {
            toast.success(res.data.message)
        })
    };

    const getCensorData = async () => {
        const response = await commonFunction.getCensorData();
        setEditedData(response.data);
    }

    useEffect(() => {
        getCensorData();
    }, [])

    // function to print the censor data
    const generatePdf = useReactToPrint({
        content: () => componentPdf.current,
        documentTitle: 'Censor Data',
        onAfterPrint: () => alert('data print success')
    });

    return (
        <>
            <div className="row mt-3">
                <div className="col-md-10">
                    <div ref={componentPdf} style={{ width: '100%' }}>
                        <button type="button" className="mb-4 ml-2 btn btn-outline-secondary" onClick={addRow}>
                            + Add row
                        </button>
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Menu</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editedData.map((row, index) => (
                                    <tr key={row.id}>
                                        <th scope="row">{row.id}</th>
                                        <td>
                                            <input
                                                type="text"
                                                value={row.date}
                                                onChange={(e) => handleInputChange(e, index, 'date')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={row.idMenu}
                                                onChange={(e) => handleInputChange(e, index, 'idMenu')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={row.total}
                                                onChange={(e) => handleInputChange(e, index, 'total')}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-5 d-flex justify-content-between">
                        <button type="button" className="btn btn-success" onClick={handleCensorSave}>
                            Save data
                        </button>
                        <button type="button" className="btn btn-success" onClick={generatePdf}>
                            Print data
                        </button>
                    </div>
                </div>
                {/* <div className="col-md-2">
                    <button type="button" className="btn btn-outline-secondary" onClick={addRow}>
                        + Add row
                    </button>
                </div> */}
            </div>
        </>
    );
}

export default CensorList;