import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const uploadCategoryPic = async (category) => {
    const headers = config();
    const response = await axios.post(`${base_url}categories/upload-pic`, category, headers);

    return response.data;
};

const updateCategoryPic = async (id) => {
    const headers = config();
    const response = await axios.put(`${base_url}categories/update-picture/${id}`, category, headers);

    return response.data;
};

const updateActiveInactive = async (data) => {
    const headers = config();

    let { categoryId, status } = data
    status = status === 1 ? 0 : 1;
    const formData = new FormData();
    formData.append('status', status);

    const response = await axios.patch(`${base_url}updateCategory/${categoryId}`, formData, headers);

    return response.data;
};

const getCategories = async (category) => {
    const headers = config();

    const requestConfig = { ...headers };

    if (category && category.pageSize && category.pageNumber) {
        requestConfig.params = {
            pageSize: category.pageSize,
            pageNumber: category.pageNumber,
        };
    }

    const response = await axios.get(`${base_url}getCategories/`, requestConfig);

    return response.data;
};

const getActiveCategories = async () => {
    const headers = config();
    const response = await axios.get(`${base_url}categories/active`, headers);

    return response.data;
};

const createCategory = async (category) => {
    const headers = config();
    const response = await axios.post(`${base_url}categories/`, category, headers);

    return response.data;
};

const getCategory = async (id) => {
    const headers = config();
    const response = await axios.get(`${base_url}getCategoryById/${id}`, headers);

    return response.data;
};

const deleteCategory = async (id) => {
    const headers = config();
    const response = await axios.delete(`${base_url}deleteCategory/${id}`, headers);

    return response.data;
};

const updateCategory = async (category) => {
    const headers = config();
    const response = await axios.put(
        `${base_url}categories/${category.idCategory}`,
        {
            title: category.title,
            description: category.description,
            imageUrl: category.imageUrl
        },
        config
    );
    return response.data;
};

const searchCategories = async (category) => {
    const headers = config();
    const requestConfig = { ...headers };

    if (category && category.pageSize && category.pageNumber || category.search) {
        requestConfig.params = {
            pageSize: category.pageSize,
            pageNumber: category.pageNumber,
            search: category.search
        };
    }

    const response = await axios.get(`${base_url}getCategories`, requestConfig);

    return response.data;
};

const CategoryService = {
    getCategories,
    createCategory,
    getCategory,
    deleteCategory,
    updateCategory,
    uploadCategoryPic,
    updateCategoryPic,
    getActiveCategories,
    updateActiveInactive,
    searchCategories
};

export default CategoryService;
