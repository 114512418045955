import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { deleteClass, getClassesList } from '../../features/classes/classes.slice';

export const DeleteClasses = ({ open, onClose, classId, classData }) => {
    const dispatch = useDispatch();

    const handleConfirmDeleteClass = async () => {
        onClose();
        const response = await dispatch(deleteClass(classId));

        if (response.payload.status) {
            toast.success(response.payload.message);
            dispatch(getClassesList(classData));

        } else {
            toast.error(response.payload.message);
        }
    };
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal">
            <DialogTitle>Delete Class</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this Class?
            </DialogContent>
            <DialogActions>
                <button className="btn btn-primary" onClick={handleConfirmDeleteClass}>Delete</button>
                <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteClasses;