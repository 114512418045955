import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getclasses = async (classes) => {
    const headers = config();
    const requestConfig = { ...headers };
    if (classes && classes.pageSize && classes.pageNumber) {
        requestConfig.params = {
            key: classes.key || '',  // Include key filter
            value: classes.value || '', // Include value filter
            pageSize: classes.pageSize,
            pageNumber: classes.pageNumber,
            // search: classes.search ? classes.search : '',
        };
    }
    const response = await axios.get(`${base_url}getSchoolClass`, requestConfig);

    return response.data.data;
};

const getClass = async (id) => {
    const headers = config();
    const response = await axios.get(`${base_url}getSchoolClassById/${id}`, headers);

    return response.data;
};

const deleteClass = async (id) => {
    const headers = config();
    const requestConfig = { ...headers };
    const requestData = { id: [id] }

    const response = await axios.post(`${base_url}deleteSchoolClass`, requestData, requestConfig);

    return response.data;
};

const updateClass = async (classData) => {
    const headers = config();

    const { className,schoolId } = classData;

    const updateClassData = {
        className,
        schoolId,
    };

    const response = await axios.patch(`${base_url}updateSchoolClass/${classData.idClass}`, updateClassData, headers);

    return response.data;
}

const createClass = async (classes) => {
    const headers = config();

    const { className,schoolId, status, } = classes;

    const classData = {
        className,
        schoolId,
        status
    };

    const response = await axios.post(`${base_url}addSchoolClass/`, classData, headers);

    return response.data;
};

const updateActiveInactive = async (data) => {
    const headers = config();
    let { id, classStatus } = data
    classStatus = classStatus === true ? false : true;

    const updateClassData = {
        status: classStatus
    }
    const response = await axios.patch(`${base_url}updateSchoolClass/${id}`, updateClassData, headers);
    return response.data;
};
const ClassesService = {
    getclasses,
    createClass,
    getClass,
    updateClass,
    deleteClass,
    updateActiveInactive,
};

export default ClassesService;