import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TransactionService from "./transaction.services";

export const getTransactionList = createAsyncThunk(
    "transactions/get-transaction",
    async (data, thunkAPI) => {
        try {
            return await TransactionService.getTransactionList(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);


export const resetState = createAction("RevertAll");

const initialState = {
    transaction: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const transactionslice = createSlice({
    name: "transaction",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTransactionList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTransactionList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.transaction = action.payload;
            })
            .addCase(getTransactionList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default transactionslice.reducer;