import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { getClass } from '../../features/classes/classes.slice';

export const ViewClass = ({ open, onClose, classId, schoolData }) => {
    const dispatch = useDispatch();

    // Fetch Class data by ID using getClass API
    useEffect(() => {
        if (classId) {
            dispatch(getClass(classId));
        }
    }, [classId, dispatch]);



    const classes = useSelector((state) => state.classes.getClass);
    const schools = schoolData?.school || [];

    const schoolName = schools.find(
        (school) => school.id == classes?.data?.schoolclass?.schoolId
    )?.schoolName || "N/A";


    return (
        <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
            <DialogTitle>View Class</DialogTitle>
            <DialogContent>
                {/* Display Class details */}
                <div className="form-group">
                    <label>Class Name</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: classes?.data?.schoolclass.className }} />
                </div>
                <div className="form-group">
                    <label>School Name</label>
                    <div className="faq-content">
                        {schoolName}
                    </div>
                </div>


                <div className="form-group">
                    <label>Status</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: classes?.data?.schoolclass.status ? 'Active' : 'Inactive' }} />
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn btn-secondary" onClick={onClose}>Close</button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewClass;