import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import { getMenus, updateActiveInactive, deleteMenu, searchMenus } from "../../features/menus/menus.slice";
import DefaultPagination from '../../config/Pagination';
import EditMenuItem from './Edit'; // Import your EditMenuItem component
import AddMenuItem from './Add'; // Import your AddMenuItem component
import DeleteMenuItem from './Delete'; // Import your DeleteMenuItem component
import { MENU_IMAGE_PATH, image_url } from '../../utils/baseUrl';
import ViewMenuItem from './View';
import Loader from '../Common/Loader';

const Menu = () => {

  // Use state to manage Menu Data in pagination and search 
  const [menuItemData, setMenuData] = useState({
    pageSize: DefaultPagination.PAGE_SIZE,
    pageNumber: DefaultPagination.PAGE_NUMBER,
    search: ''
  });
  const dispatch = useDispatch();
  const [openEditMenuDialog, setOpenEditMenuDialog] = useState(false);
  const [editingMenuItemId, setEditingMenuItemId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingMenuId, setDeletingMenuId] = useState('');
  const [openAddMenuDialog, setOpenAddMenuDialog] = useState(false);
  const [viewingMenuId, setViewingMenuId] = useState(null);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  // Use useEffect to fetch Menus when pageSize or pageNumber changes
  useEffect(() => {
    dispatch(getMenus(menuItemData));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [menuItemData.pageSize, menuItemData.pageNumber]);

  // Get menu items data from Redux store
  const { menus } = useSelector((state) => state.menus);

  // Function to handle opening the Edit Menu Item dialog
  const handleOpenEditMenuItemDialog = (itemId) => {
    setEditingMenuItemId(itemId);
    setOpenEditMenuDialog(true);
  };

  // Function to handle closing the Edit Menu Item dialog
  const handleCloseEditMenuItemDialog = () => {
    setOpenEditMenuDialog(false);
  };

  // Function to handle change in page size dropdown
  const changeTableData = (e) => {
    setMenuData((prevUserData) => ({ ...prevUserData, pageSize: parseInt(e.target.value) }));
    dispatch(getMenus(menuItemData));
  };

  // Function to handle pagination click with a specific page number
  const paginationWithPage = (pageNumber) => {
    setMenuData((prevUserData) => ({ ...prevUserData, pageNumber: pageNumber }));
    dispatch(getMenus(menuItemData));
  };

  const handleStatusChange = async (menuId, status) => {

    const response = await dispatch(updateActiveInactive({ menuId, status }));

    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getMenus(menuItemData));
    } else {
      toast.error(response.payload.message);
    }
  };

  // Function to handle search as the user types in the search input field
  const handleSearchMenus = (keyword) => {
    // menuItemData.pageNumber = DefaultPagination.PAGE_NUMBER;
    menuItemData.search = keyword.target.value;
    dispatch(searchMenus(menuItemData));
  };

  // Function to handle delete Menu click
  const handleDeleteMenu = (menuId) => {
    setDeletingMenuId(menuId);
    setOpenDeleteDialog(true);
  };

  // Function to handle opening the Add FAQ dialog
  const handleOpenAddMenuDialog = () => {
    setOpenAddMenuDialog(true);
  };

  // Function to handle closing the Add Menu dialog
  const handleCloseAddMenuDialog = () => {
    setOpenAddMenuDialog(false);
  };

  // Open view modal
  const handleViewMenuItem = (id) => {
    setViewingMenuId(id);
    setOpenViewDialog(true);
  }

  // JSX code for rendering the component UI
  return (
    <>
      {/* Breadcrumb */}
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Menus
          </li>
        </ol>
      </nav>

      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">
            Menus List
          </h1>
          <a>
            <span className="text">
              <button className="btn btn-primary" onClick={handleOpenAddMenuDialog}>+ Add Menu</button>
            </span>
          </a>
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            {/* Add loader */}
            {loading ?
              (
                <Loader />
              ) :
              (

                <div className="table-responsive">
                  <div className="row">
                    {/* Page size dropdown */}
                    <PageLimit changeLimit={(e) => changeTableData(e)} />

                    <div className="col-sm-12 col-md-6">
                      <div id="dataTable_filter" className="dataTables_filter">
                        <label>
                          {/* Search input */}
                          <input
                            type="search"
                            placeholder="search Menus"
                            className="form-control form-control-sm"
                            aria-controls="dataTable"
                            onChange={handleSearchMenus}
                          />
                        </label>
                      </div>
                    </div>

                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          {/* <th scope="col" width="5%">No</th> */}
                          <th scope="col">Title</th>
                          <th scope="col">Description</th>
                          <th scope="col">Category</th>
                          <th scope="col">Ingredients</th>
                          <th scope="col">Image</th>
                          <th scope="col">Nutrition</th>
                          <th scope="col">Price</th>
                          {/* <th scope="col">Status</th> */}
                          <th scope="col">Change Status</th>
                          <th width="10%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Render Menus data */}
                        {(menus?.data?.menus != undefined && menus?.data?.menus.length > 0) ?
                          menus.data.menus.map((menu, index) => {
                            const ingredientTitles = menu.Ingredients.map(ingredient => ingredient.title).join(', ');

                            return (
                              <tr key={index}>
                                {/* <td scope="row">{index + 1}</td> */}
                                <td dangerouslySetInnerHTML={{ __html: menu.title }} />
                                <td dangerouslySetInnerHTML={{ __html: menu.description }} />
                                <td>{menu.Category?.title}</td>
                                <td>{ingredientTitles}</td>
                                <td>
                                  {
                                    menu.MenuImages !== undefined && menu.MenuImages.length ? (
                                      <div className="bordered-img-table">
                                        <img src={`${image_url}${MENU_IMAGE_PATH}` + menu?.MenuImages[0]?.image} className="table-img" alt="Menu" />
                                      </div>
                                    ) : "--"
                                  }
                                </td>
                                <td>{menu.nutrition != '' && menu.nutrition != undefined ?
                                  Object.keys(menu.nutrition).map((key, index) => {
                                    const value = menu.nutrition[key];
                                    return (
                                      <span key={index}>
                                        {`${key}: ${value}`}
                                        <br />
                                      </span>
                                    );
                                  }) : 'Null'}</td>
                                <td>{menu.price}</td>
                                {/* <td>{menu.status ? "Active" : "Inactive"}</td> */}
                                <td className="tdTextCenter">
                                  <div className="custom-control custom-checkbox small">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      htmlFor="customCheck"
                                      id="customCheck"
                                      name={['isChecked' + index]}
                                      checked={menu.status}
                                      onChange={() => handleStatusChange(menu.idMenu, menu.status)}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <a
                                    className="actionIcon"
                                    title="Edit"
                                    onClick={() => handleOpenEditMenuItemDialog(menu.idMenu)}
                                  >
                                    <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                  </a>
                                  <a
                                    className="actionIcon"
                                    title="Delete"
                                    onClick={() => handleDeleteMenu(menu)}
                                  >
                                    <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                  </a>
                                  <a
                                    className="actionIcon"
                                    title="View"
                                    onClick={() => handleViewMenuItem(menu.idMenu)} // Handle view click
                                  >
                                    <span><img src="assets/img/svg/eye-icon.png" alt="View" /></span>
                                  </a>
                                </td>
                              </tr>
                            );
                          }) :
                          // Render "No records found" if there are no menus
                          <tr>
                            <td colSpan="8" className="noRecordFound">No records found</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination component */}
                  <Pagination
                    totalPage={menus?.data?.pagination?.totalPages || 1}
                    currentPage={menus?.data?.pagination?.currentPage || 1}
                    fullPagination={paginationWithPage}
                    totalFaqs={menus?.data?.pagination?.totalMenus || 1}
                    pageSize={menus?.data?.pagination?.pageSize || 10}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>

      {/* Edit Menu Item dialog */}
      <EditMenuItem
        open={openEditMenuDialog}
        onClose={handleCloseEditMenuItemDialog}
        menuItemData={menuItemData}
        itemId={editingMenuItemId}
      />

      {/* Add Menu Item dialog */}
      <AddMenuItem
        open={openAddMenuDialog}
        onClose={() => setOpenAddMenuDialog(false)}
        menuItemData={menuItemData}
      />

      {/* Delete Menu Item dialog */}
      <DeleteMenuItem
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        menuData={menuItemData}
        menuId={deletingMenuId}
      />

      {/* View Menu Item dialog */}
      <ViewMenuItem
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        menuData={menuItemData}
        menuId={viewingMenuId}
      />
    </>
  );
}

export default Menu;
