import React, { useEffect, useState } from 'react';
import PageLimit from '../Common/PageLimit';
import { Link } from 'react-router-dom';
import commonFunction from '../../utils/commonFunction';
import AddPage from './AppPages'
import { toast } from "react-toastify";
import EditPage from './EditPage';
import './PagesStyle.css';

const WebsitePages = () => {
    const [pages, setPages] = useState([]);
    const [pagination, setPagination] = useState({});
    const [openAddPageDialog, setOpenAddPageDialog] = useState(false);
    const [openEditSchoolDialog, setOpenEditSchoolDialog] = useState(false);
    const [editingSchoolId, setEditingSchoolId] = useState('');

    useEffect(() => {
        // Fetch pages from API
        commonFunction.getAllPages()
            .then((res) => {
                if (res?.status && res?.data?.page) {
                    setPages(res?.data?.page);
                    setPagination(res?.data?.pagination);
                } else {
                    console.error('No pages found');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    // Function to handle opening the Add Page dialog
    const handleOpenAddPageDialog = () => {
        setOpenAddPageDialog(true);
    };

    // Function to handle closing the Edit School dialog
    const handleOpenEditSchoolDialog = (id) => {
        setEditingSchoolId(id);
        setOpenEditSchoolDialog(true);
    }

    // Function to handle closing the Add School dialog
    const handleCloseAddSchoolDialog = () => {
        setOpenAddPageDialog(false);
        commonFunction.getAllPages()
            .then((res) => {
                if (res?.status && res?.data?.page) {
                    setPages(res?.data?.page);
                    setPagination(res?.data?.pagination);
                } else {
                    console.error('No pages found');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCloseEditSchoolDialog = () => {
        setOpenEditSchoolDialog(false);
        commonFunction.getAllPages()
            .then((res) => {
                if (res?.status && res?.data?.page) {
                    setPages(res?.data?.page);
                    setPagination(res?.data?.pagination);
                } else {
                    console.error('No pages found');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // Function to handle change in page size dropdown
    const changeTableData = (e) => {
        console.log(e.target.value);
    };


    const handleDeletePage = async (id) => {
        const response = await commonFunction.deletePage(id);
        if (response.status === 200 && response?.data?.status === true) {
            toast.success('page deleted successfully');
            commonFunction.getAllPages()
                .then((res) => {
                    if (res?.status && res?.data?.page) {
                        setPages(res?.data?.page);
                        setPagination(res?.data?.pagination);
                    } else {
                        console.error('No pages found');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const formatDateTime = (dateString) => {
        if (!dateString) {
            return 'Invalid Date'; // Handle null, undefined, or empty date strings
        }
    
        const date = new Date(dateString);
        if (isNaN(date)) {
            return 'Invalid Date'; // Handle invalid date formats
        }
    
        // Define options for formatting the date only
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'CET', // Ensure the date is based on CET timezone
        };
    
        // Use Intl.DateTimeFormat to format the date
        const formatter = new Intl.DateTimeFormat('en-GB', options);
    
        return formatter.format(date); // Return formatted date without time
    };
    

    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">Dashboard</Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">Website Pages</li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">Page List</h1>
                    <button className="btn btn-primary" onClick={handleOpenAddPageDialog}>+ Add Page Content</button>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                {/* Page size dropdown */}
                                <PageLimit changeLimit={(e) => changeTableData(e)} />
                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" placeholder="Search Pages" className="form-control form-control-sm" aria-controls="dataTable" />
                                        </label>
                                    </div>
                                </div>
                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">Title</th>
                                            <th scope="col">Heading</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Created At</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pages.length > 0 ? (
                                            pages.map((page, index) => (
                                                <tr key={page.id} className='remove-space'>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td>{page.title}</td>
                                                    <td>{page.heading}</td>
                                                    <td dangerouslySetInnerHTML={{ __html: page.description }}></td>
                                                    {/* <td>{new Date(page.createdAt).toLocaleDateString()}</td> */}
                                                    <td>{page.updatedAt ? formatDateTime(page.updatedAt) : 'N/A'}</td>
                                                    <td>
                                                        <a
                                                            className="actionIcon"
                                                            title="Edit"
                                                            onClick={() => handleOpenEditSchoolDialog(page)}
                                                        >
                                                            <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                                                        </a>
                                                        <a
                                                            className="actionIcon"
                                                            title="Delete"
                                                            onClick={() => handleDeletePage(page.id)}
                                                        >
                                                            <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                        </a>
                                                        <a
                                                            className="actionIcon"
                                                            title="View"
                                                        // onClick={() => handleViewSchool(school.id)} // Handle view click
                                                        >
                                                            <span><img src="assets/img/svg/eye-icon.png" alt="View" /></span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="noRecordFound">No records found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add School dialog */}
            <AddPage
                open={openAddPageDialog}
                onClose={handleCloseAddSchoolDialog}
            />

            {/* Edit School dialog */}
            <EditPage
                open={openEditSchoolDialog}
                onClose={handleCloseEditSchoolDialog}
                schoolId={editingSchoolId}
            />
        </>
    );
};

export default WebsitePages;
