import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { getMenu } from '../../features/menus/menus.slice';
import { MENU_IMAGE_PATH, image_url } from '../../utils/baseUrl';

export const ViewMenuItem = ({ open, onClose, faqData, menuId }) => {
    const dispatch = useDispatch();

    // Fetch FAQ data by ID using getFaq API
    useEffect(() => {
        if (menuId) {
            dispatch(getMenu(menuId));
        }
    }, [menuId, dispatch]);

    // Get Menu data from Redux store
    const menu = useSelector((state) => state.menus.getMenu);

    return (
        <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
            <DialogTitle>Edit Menu</DialogTitle>
            <DialogContent>
                <div className="form-group">
                    <div className="form-floating">
                        <label htmlFor="title">Title</label>
                        <div className="faq-content" >
                            {menu?.title}
                        </div>
                    </div>

                </div>

                <div className="form-group">
                    <div className="form-floating">
                        <label htmlFor="description">Description</label>
                        <div className="faq-content" >
                            {menu?.description}
                        </div>
                    </div>

                </div>

                <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <div className="faq-content" >
                        {menu?.price} Kr.
                    </div>
                </div>

                Nutrition:
                <div className="form-row">
                    {menu?.nutrition != '' && menu?.nutrition != undefined ?
                        Object.keys(menu?.nutrition).map((key, index) => {
                            const value = menu?.nutrition[key];
                            return (
                                <div className="form-group col-md-6">
                                    <span key={index}>
                                        {`${key}: ${value}`}
                                        <br />
                                    </span>
                                </div>
                            );
                        }) : 'Null'}
                </div>
                <div className="form-group">
                    <label htmlFor="categoryId">Category</label>
                    <div className="faq-content" >
                        {menu?.Category?.title}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="ingredientIds">Ingredients</label>
                    <div className="faq-content" >
                        {menu?.nutrition != '' && menu?.nutrition != undefined ? menu?.Ingredients?.map(ingredient => ingredient.title).join(', ') : 'Null'}
                    </div>

                </div>

                <div className="form-group">
                    <label htmlFor="imageFile">Image</label>
                    <div className="faq-content" >
                        {
                            menu?.MenuImages !== undefined && menu?.MenuImages.length ? (
                                <div className="bordered-img-table">
                                    <img src={`${image_url}${MENU_IMAGE_PATH}` + menu?.MenuImages[0]?.image} className="table-img" alt="Menu" />
                                </div>
                            ) : "--"
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewMenuItem;