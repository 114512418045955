import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { createClasses, getClassesList } from '../../features/classes/classes.slice';


const schema = yup.object().shape({
    className: yup.string().required('Class name is required'),
    schoolId: yup.string().required('School name is required')
});

export const AddClasses = ({ open, onClose, classesData, schoolData }) => {
    const dispatch = useDispatch();
    
    const formik = useFormik({
        initialValues: {
            className: '',
            schoolId: '',
            status: true
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            console.log(JSON.stringify(values));
            try {
                const response = await dispatch(createClasses(values));

                if (response.payload.status) {
                    toast.success(response.payload.message);
                    dispatch(getClassesList(classesData));
                    onClose();

                    // Reset the form after successful submission
                    formik.resetForm();
                } else {
                    toast.error(response.payload.message);
                }
            } catch (error) {
                // Handle error if necessary
                toast.error("Error submitting the form.");
            }
        },
    });
        
        
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Add Class</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="className">class Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="className"
                                placeholder="Enter class Name"
                                name="className"
                                value={formik.values.className}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.className && formik.errors.className && (
                            <div className="error m-1">{formik.errors.className}</div>
                        )}
                    </div>

                    {/* School Name dropdown */}
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="schoolName">School Name</label>
                            <select
                                className="form-control"
                                id="schoolId"
                                name="schoolId"
                                value={formik.values.schoolId} // Holds the selected schoolId
                                onChange={(event) => {
                                    const schoolId = event.target.value; // Get the selected school ID
                                    formik.setFieldValue('schoolId', schoolId); // Update form state with the school ID
                                }}
                                onBlur={formik.handleBlur}
                            >
                                <option value="" disabled>Select a School</option>
                                {schoolData.school?.length > 0 ? (
                                    schoolData.school.map((school) => (
                                        <option key={school.id} value={school.id}>
                                            {school.schoolName} {/* Display school name in dropdown */}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No schools available</option>
                                )}
                            </select>
                        </div>
                        {formik.touched.schoolName && formik.errors.schoolName && (
                            <div className="error m-1">{formik.errors.schoolName}</div>
                        )}
                    </div>
                        
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Add</button>
                    <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddClasses;