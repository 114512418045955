import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDeletedUsers } from '../../features/users/users.slice';
import DefaultPagination from '../../config/Pagination';
import Pagination from '../Common/Pagination';

function ClosedAccount() {
    const [userData, setUserData] = useState({
        pageSize: DefaultPagination.PAGE_SIZE,
        pageNumber: DefaultPagination.PAGE_NUMBER,
        isactive: 0,
        req_delete: 1,
        sortby: 'updatedAt',
    });

    const dispatch = useDispatch();

    // Extract deletedUsers and pagination from Redux store
    const { deletedUsers } = useSelector((state) => state.users);
    console.log(deletedUsers,"deletedUsers");

    useEffect(() => {
        // Fetch the deleted users on component mount and when userData changes
        dispatch(getAllDeletedUsers(userData));
    }, [dispatch, userData]);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setUserData((prevUserData) => ({
            ...prevUserData,
            pageNumber: pageNumber,
        }));
    };

    const changeTableData = (e) => {
        // Logic for changing page size or other table settings
    };

    const handleSearch = (keyword) => {
        // Logic for handling search input
        if (keyword.target.value) {
            userData.search = keyword.target.value;
            dispatch(getAllDeletedUsers(userData));
        } else {
            dispatch(getAllDeletedUsers(userData));
        }
    };

    const formatDateTime = (dateString) => {
        if (!dateString) {
            return 'Invalid Date'; // Handle null, undefined, or empty date strings
        }

        const date = new Date(dateString);
        if (isNaN(date)) {
            return 'Invalid Date'; // Handle invalid date formats
        }

        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'CET',
        };

        const formatter = new Intl.DateTimeFormat('en-GB', options);

        // Format the date and replace ", " with " - "
        return formatter.format(date).replace(', ', ' - ') + ' CET';
    };

    const sortedUsers = useMemo(() => {
        if (deletedUsers?.data?.users) {
            return [...deletedUsers.data.users].sort(
                (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
        }
        return [];
    }, [deletedUsers]);
    
    

    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">Dashboard</Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Deleted Account
                    </li>
                </ol>
            </nav>

            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">Deleted Account List</h1>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div>
                                <h6>Total Users: {deletedUsers?.data?.pagination?.totalUsers}</h6>
                            </div>
                            <div className="row">
                                {/* Page size dropdown */}
                                <PageLimit changeLimit={(e) => changeTableData(e)} />

                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        {/* <label>
                                            <input
                                                type="search"
                                                placeholder="search users"
                                                className="form-control form-control-sm"
                                                aria-controls="dataTable"
                                                onChange={handleSearch} // Dynamically trigger search on input change
                                            />
                                        </label> */}
                                    </div>
                                </div>

                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">User Email</th>
                                            <th scope="col">Mobile Number</th>
                                            <th scope="col">Deleted Date</th>
                                            {/* <th width="10%">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sortedUsers.length > 0 ? (
                                        sortedUsers.map((user, index) => (
                                                <tr key={user.idUser}>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td>{user.email}</td>
                                                    <td>{user.mobileNumber || 'N/A'}</td>
                                                    {/* <td>{user.updatedAt ? new Date(user.updatedAt).toLocaleDateString() : 'N/A'}</td> */}
                                                    <td>{user.updatedAt ? formatDateTime(user.updatedAt) : 'N/A'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="noRecordFound">No records found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                totalPage={deletedUsers?.data?.pagination?.totalPages || 1}
                                currentPage={deletedUsers?.data?.pagination?.currentPage || 1}
                                fullPagination={handlePageChange}
                                totalFaqs={deletedUsers?.data?.pagination?.totalUsers || 1}
                                pageSize={deletedUsers?.data?.pagination?.pageSize || 10}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClosedAccount;
