import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup'; // Import Yup for schema validation
import { getUsers } from '../../features/users/users.slice';
import { toast } from 'react-toastify';
import commonFunction from '../../utils/commonFunction';

function AddInquery({ open, onClose }) {

    const [questions, setQuestions] = useState(['']); // State to store questions
    const [selectedUsers, setSelectedUsers] = useState([]);

    // Use useEffect to fetch Users when pageSize or pageNumber changes
    const dispatch = useDispatch();
    let userData = {
        pageNumber: 1,
        pageSize: 100,
        isactive: 1,
    };

    useEffect(() => {
        dispatch(getUsers(userData));
    }, [dispatch]);

    // Get Users data from Redux store
    const { users } = useSelector((state) => state.users);

    const handleAddQuestions = () => {
        setQuestions([...questions, '']); // Add a new empty question
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = value;
        setQuestions(updatedQuestions);
    };
    const handleDeleteQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
        const updatedFormikValues = { ...formik.values };
        updatedFormikValues.questions = updatedQuestions;
        formik.setValues(updatedFormikValues);
    };
    const validationSchema = Yup.object().shape({
        questions: Yup.array().of(Yup.string().required('Question is required')), // Validate each question
        selectedUsers: Yup.array().min(1, "At least one User is required"),
    });
    const formik = useFormik({
        initialValues: {
            questions: questions,
            selectedUsers: selectedUsers,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (values.questions.length > 0) {
                const response = await commonFunction.sendSurvey(values);
                if (response.status) {
                    toast.success(response.message);
                    setSelectedUsers([]);
                    onClose();
                    // Reset the form after successful submission
                    formik.resetForm();
                }
            } else {
                toast.error('Atleast one question is required')
            }
        },
    });

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Set all users to selectedUsers when checkbox is checked
            setSelectedUsers([...users?.data?.users]);
        } else {
            // Clear selectedUsers when checkbox is unchecked
            setSelectedUsers([]);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} className='mui-modal xx-large-dialog'>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                    Add new survey
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-9">
                            <p>
                                Questions:
                            </p>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-primary" type='button' onClick={handleAddQuestions}>Add Question Row</button>
                        </div>
                    </div>

                    {/* dynamic input fiels */}
                    {questions.map((question, index) => (
                        <div className="form-group" key={index}>
                            <div className="form-floating  row">
                                <label htmlFor={`question-${index}`} className='col-sm-2 col-form-label'>Enter Question</label>
                                <div className="col-sm-10 d-flex">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`question-${index}`}
                                        placeholder="Enter Question"
                                        value={question}
                                        onChange={(e) => {
                                            handleQuestionChange(index, e.target.value);
                                            formik.handleChange(e);
                                        }}
                                        autoComplete="on"
                                        name={`questions[${index}]`}
                                    />
                                    <div className='pt-2 pl-2' onClick={() => handleDeleteQuestion(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            {formik.errors.questions && formik.errors.questions[index] && (
                                <div className="error m-1">{formik.errors.questions[index]}</div>
                            )}
                        </div>
                    ))}

                    <div className="form-group">
                        <label htmlFor="selectedUsers">Ingredients</label>

                        <Autocomplete
                            multiple
                            id="selectedUsers"
                            options={users?.data?.users || []}
                            getOptionLabel={(ingredient) => ingredient.firstName}
                            value={selectedUsers}
                            onChange={(_, newValue) => {
                                const ingredientIdsArray = newValue.map(ingredient => ingredient);
                                setSelectedUsers(newValue);
                                formik.setFieldValue('selectedUsers', ingredientIdsArray);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select ingredients"
                                />
                            )}
                        />
                        {formik.touched.selectedUsers && formik.errors.selectedUsers && (
                            <div className="error m-1">{formik.errors.selectedUsers}</div>
                        )}
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" onChange={handleCheckboxChange} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            All Users
                        </label>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type='submit'>Add</button>
                    <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddInquery