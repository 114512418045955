import React, { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import SchedulePrint from './SchedulePrint';

function DistributionData({ open, onClose, distributionData, date, selectedSchoolName }) {

    const componentPdf = useRef();
    const componentLablePdf = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    // function to print the Distribution data
    const generatePdf = useReactToPrint({
        content: () => componentPdf.current,
        documentTitle: 'Distribution Data',
        onAfterPrint: () => {
            onClose();
        }
    });

    // function to print the Distribution labels
    const generateLabelPdf = useReactToPrint({
        content: () => componentLablePdf.current,
        documentTitle: 'Distribution Label',
        onAfterPrint: () => {
            onCloseLabel();
        }
    });

    const openLabelPdf = () => {
        setIsOpen(true);
    }

    const onCloseLabel = () => {
        setIsOpen(false);
    }

    const showModal = () => {
        setOpenModal(true);
    };

    const groupedData = {};

    distributionData?.orders?.forEach(entry => {
        const groupKey = `${entry.childName}_${entry.className}`; // Create a unique key for each child and class
        if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
        }
        groupedData[groupKey].push(entry);
    });

    // Convert the grouped data to an array of arrays
    const groupedList = Object.values(groupedData);
    // Function to remove HTML tags
    const removeHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    // Function to decode HTML entities
    const decodeHtmlEntities = (str) => {
        const element = document.createElement('div');
        if (str) {
            element.innerHTML = str;
            return element.textContent || element.innerText;
        }
        return '';
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
                <DialogContent>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div ref={componentPdf} style={{ width: '100%' }}>
                                {/* <DialogTitle>Distribution List of {selectedSchoolName} School for {date}</DialogTitle> */}
                                <Typography variant="h5" align="center" style={{ font: '600 25px/28px Raleway, sans-serif', marginTop: '25px' }}>Distribution List <br /> {selectedSchoolName} {date}</Typography>
                                <table className="table table-striped table-bordered" style={{ width: '76%', margin: '20px 0', marginLeft: '13%', border: 'black solid' }}>
                                    <thead className="thead-dark" style={{ border: 'black solid' }}>
                                        <tr style={{ border: 'black solid' }}>
                                            {/* <th scope="col">#</th> */}
                                            <th scope="col" style={{ border: 'black solid', backgroundColor: '#212529', font: '600 25px/28px Raleway, sans-serif' }}>Barnets Navn, Klasse & Bestilling</th>
                                            <th scope="col" style={{ border: 'black solid', backgroundColor: '#212529', font: '600 25px/28px Raleway, sans-serif', width: '25%' }}>Sæt Kryds X</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ border: 'black solid' }}>
                                        {
                                            groupedList && groupedList.map((list, index) => {
                                                return (
                                                    <tr key={index} style={{ border: 'black solid' }}>
                                                        {/* <th scope="row">{index + 1}</th> */}
                                                        <td style={{ color: '#000000', border: 'black solid' }}>
                                                            <span style={{ font: '600 25px/25px Raleway, sans-serif' }}>
                                                                {list[0].childName} {list[0].className} <br />
                                                                {list && list.map((item, i) => {
                                                                    return (
                                                                        <span key={`${i}_${item.id}`}>
                                                                            {item.count} * {decodeHtmlEntities(removeHtmlTags(item.itemName))} <br />
                                                                        </span>
                                                                    )
                                                                })}
                                                            </span>
                                                        </td>
                                                        <td style={{ color: '#000000', border: 'black solid' }}>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-5 d-flex justify-content-between" style={{ width: '68%', textAlign: 'center', margin: '20px 0', marginLeft: '17%' }}>
                                <button type="button" className="btn btn-success" onClick={generatePdf}>
                                    Print data
                                </button>
                                <button type="button" className="btn btn-success" onClick={openLabelPdf}>
                                    Labels
                                </button>
                                <Button variant="outlined" onClick={showModal}>Plan</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                </DialogActions>
            </Dialog>
            <Dialog open={isOpen} onClose={onCloseLabel} className="mui-modal xx-large-dialog">
                <DialogContent>
                    <div className="row p-3" ref={componentLablePdf} style={{ width: '100%' }} >
                        {
                            (distributionData?.orders != undefined && distributionData?.orders.length > 0) ? distributionData?.orders.map((item, index) => (
                                <div className="col-sm-4 mt-2 mb-4" key={index}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <h5 className="card-title" dangerouslySetInnerHTML={{ __html: item?.schoolName.replace(/<[^>]+>/g, '') }}></h5>
                                                <h5 className="card-title" dangerouslySetInnerHTML={{ __html: item?.deliveryDate.replace(/<[^>]+>/g, '') }}></h5>
                                                {/* <h5 className='justify-content-end'>{item.deliveryDate}</h5> */}
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p className="card-text">{item.className} </p>
                                                <p className="card-text">{item.childName} </p>
                                            </div>
                                            <h5 className="card-title" dangerouslySetInnerHTML={{ __html: item?.itemName.replace(/<[^>]+>/g, '') }}></h5>
                                            {/* <p className="card-title">{item.itemName} </p> */}
                                        </div>
                                    </div>
                                </div>
                            )) : 'No data Found '
                        }
                    </div>
                    <div className="col-md-5 d-flex justify-content-between">
                        <button type="button" className="btn btn-success" onClick={generateLabelPdf}>
                            Print Label
                        </button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-secondary" onClick={onCloseLabel}>Cancel</button>
                </DialogActions>
            </Dialog>

            {/* Schedule Print Section */}
            <SchedulePrint
                openModal={openModal}
                onClose={() => setOpenModal(false)}
            />
        </>
    )
}

export default DistributionData