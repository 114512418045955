import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AddClosed from './Add';

function List() {

    const [openAddDialog, setOpenAddDialog] = useState(false);

    // Function to handle opening the Add Class dialog
    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    // Function to handle closing the Add Class dialog
    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };
    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Cloased Days
                    </li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Closed Days template List
                    </h1>
                    <a>
                        <span className="text">
                            <button className="btn btn-primary" onClick={handleOpenAddDialog}>+ Add New Closing Period</button>
                        </span>
                    </a>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th scope="col" width="5%">No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">From</th>
                                            <th scope="col">To</th>
                                            <th scope="col">Sales Places</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Render template data */}
                                        <tr>
                                            <td colSpan="8" className="noRecordFound">No records found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* Pagination component */}
                            {/* <Pagination
                                totalPage={classes?.pagination?.totalPages}
                                currentPage={classes?.pagination?.currentPage}
                                fullPagination={paginationWithPage}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Add dialog */}
            <AddClosed
                open={openAddDialog}
                onClose={handleCloseAddDialog}
            />
        </>
    )
}

export default List