import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import DefaultPagination from '../../config/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getInqueries } from '../../features/inqueries/inqueries.slice';
import DeleteInquery from './Delete';
import AddInquery from './Add';

const InqueryList = () => {

    let inqueryData = {
        pageSize: DefaultPagination.PAGE_SIZE,
        pageNumber: DefaultPagination.PAGE_NUMBER,
    };

    const dispatch = useDispatch();
    const [deletingInqueryId, setDeletingInqueryId] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);

    // Use useEffect to fetch FAQs when pageSize or pageNumber changes
    useEffect(() => {
        dispatch(getInqueries(inqueryData));
    }, [inqueryData.pageSize, inqueryData.pageNumber]);

    const { inqueries } = useSelector((state) => state.inqueries);

    // Function to handle search as the user types in the search input field
    const handleSearchInquery = (keyword) => {
        inqueryData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
        inqueryData.search = keyword.target.value;
        dispatch(getInqueries(inqueryData))
    };

    // Function to handle pagination click with a specific page number
    const paginationWithPage = (pageNumber) => {
        inqueryData.pageNumber = pageNumber;
        dispatch(getInqueries(inqueryData));
    };

    // Function to handle change in page size dropdown
    const changeTableData = (e) => {
        inqueryData.pageSize = parseInt(e.target.value);
        inqueryData.pageNumber = DefaultPagination.PAGE_NUMBER;
        dispatch(getInqueries(inqueryData));
    };

    const handleDeleteInquery = (inqueryId) => {
        setDeletingInqueryId(inqueryId);
        setOpenDeleteDialog(true);
    };

    const handleCreateFeedback = () => {
        setOpenAddDialog(true);
    }


    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Inquiries
                    </li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Inquery List
                    </h1>
                    {/* <a>
                        <span className="text">
                            <button className="btn btn-primary" onClick={handleCreateFeedback}>+ Create new feedback survey</button>
                        </span>
                    </a> */}
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                {/* Page size dropdown */}
                                <PageLimit changeLimit={(e) => changeTableData(e)} />
                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            {/* Search input */}
                                            <input
                                                type="search"
                                                placeholder="search Inqueries"
                                                className="form-control form-control-sm"
                                                aria-controls="dataTable"
                                                onChange={handleSearchInquery} // Dynamically trigger search on input change
                                            />
                                        </label>
                                    </div>
                                </div>

                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col" width="5%">No</th> */}
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Option</th>
                                            <th scope="col">Created on</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Render Inquiries data */}
                                        {
                                            (inqueries?.data?.inquiry != undefined && inqueries?.data?.inquiry.length > 0) ?
                                                inqueries?.data?.inquiry.map((inquiry, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td scope="row">{index + 1}</td> */}
                                                            <td>{inquiry.name}</td>
                                                            <td>{inquiry.email}</td>
                                                            <td>{inquiry.message}</td>
                                                            <td>{inquiry.option}</td>
                                                            <td>{new Date(inquiry.createdAt).toLocaleDateString()}</td>
                                                            <td>
                                                                <a
                                                                    className="actionIcon"
                                                                    title="Delete"
                                                                    onClick={() => handleDeleteInquery(inquiry.id)}
                                                                >
                                                                    <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) :
                                                // Render "No records found" if there are no FAQs
                                                <tr>
                                                    <td colSpan="8" className="noRecordFound">No records found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination component */}
                            <Pagination
                                totalPage={inqueries?.data?.pagination?.totalPages || 1}
                                currentPage={inqueries?.data?.pagination?.currentPage || 1}
                                fullPagination={paginationWithPage}
                                totalFaqs={inqueries?.data?.pagination?.totalMenus || 1}
                                pageSize={inqueries?.data?.pagination?.pageSize || 10}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Delete Inquery dialog */}
            <DeleteInquery
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                inqueryData={inqueryData}
                inqueryId={deletingInqueryId}
            />

            {/* Add Survey dialog */}
            <AddInquery
                open={openAddDialog}
                onClose={() => setOpenAddDialog(false)}
            />

        </>
    )
}

export default InqueryList;