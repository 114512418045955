import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { DatePicker, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSchoolList } from '../../features/schools/schools.slice';
import { useFormik } from 'formik';
import * as yup from "yup";

function AddClosed({ open, onClose }) {

    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    let schoolData = {
        pageNumber: 1,
        pageSize: 100,
    };
    const dispatch = useDispatch();

    // Use useEffect to fetch Schools when pageSize or pageNumber changes
    useEffect(() => {
        dispatch(getSchoolList(schoolData));
    }, [schoolData.pageNumber, schoolData.pageSize]);

    // Get Schools data from Redux store
    const { schools } = useSelector((state) => state.schools);
    
    const handleDateChange = (dates, dateStrings) => {

        const obj = {
            start: dateStrings[0],
            end: dateStrings[1],
        }
        // Use Formik's setFieldValue to update the date in Formik state
        formik.setFieldValue('closingPeriod', obj);
    };

    const handleSelectAll = () => {
        setSelectedIngredients(schools.school);
    };

    const optionsWithSelectAll = Array.isArray(schools.school)
        ? [{ id: 'selectAll', schoolName: 'Select All' }, ...schools.school]
        : [];

    const schema = yup.object().shape({
        closingName: yup.string().required('Closing Days name is required'),
        closingPeriod: yup.object().shape({
            start: yup.string().required('Start date is required'),
            end: yup.string().required('End date is required'),
        }),
        selectedIngredients: yup.array().min(1, 'Select at least one School'),
        selectDesign: yup.string().required('Select a design is required'),
        title: yup.string().required('Design title is required'),
        text: yup.string().required('Text is required'), // New validation rule

    });

    const formik = useFormik({
        initialValues: {
            closingName: '',
            closingPeriod: {
                start: '',
                end: '',
            },
            selectedIngredients: [],
            selectDesign: 'custom',
            title: 'The kitchen does not deliver this day',
            text: 'Come back later...', // Initial value for text
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            console.log(values, 'form submit values');
            onClose();
            // Reset the form after successful submission
            setSelectedIngredients([]);
            formik.resetForm();
        },
    });

    const disabledDate = (current) => {
        // Disable dates before tomorrow
        return current && current < moment().startOf('day');
    };

    // closing Preview finction
    const closingPreview = () => {
        setOpenModal(true);
    }
    const onCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Add Closing period</DialogTitle>
                    <DialogContent>
                        <div className="form-group">
                            <div className="form-floating">
                                <label htmlFor="className">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="closingName"
                                    placeholder="Enter Name"
                                    name="closingName"
                                    value={formik.values.closingName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoComplete="on"
                                />
                            </div>
                            {formik.touched.closingName && formik.errors.closingName && (
                                <div className="error m-1">{formik.errors.closingName}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <div className="form-floating">
                                <label htmlFor="className">Closing Period</label>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <DatePicker.RangePicker onChange={handleDateChange} disabledDate={disabledDate} />
                                </Space>
                            </div>
                            {/* Display validation error for closingPeriod */}
                            {formik.touched.closingPeriod && formik.errors.closingPeriod && (
                                <div className="error m-1">{formik.errors.closingPeriod.start}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="ingredientIds">Sales Place</label>

                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={optionsWithSelectAll}
                                getOptionLabel={(option) => option.schoolName}
                                value={selectedIngredients}
                                onChange={(_, newValue) => {
                                    if (newValue.some((option) => option.id === 'selectAll')) {
                                        handleSelectAll();
                                    } else {
                                        setSelectedIngredients(newValue.filter((option) => option.id !== 'selectAll'));
                                    }
                                    // Use Formik's setFieldValue to update selectedIngredients in Formik state
                                    formik.setFieldValue('selectedIngredients', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="schools"
                                        placeholder="School Name"
                                    />
                                )}
                            />
                            {/* Display validation error for selectedIngredients */}
                            {formik.touched.selectedIngredients && formik.errors.selectedIngredients && (
                                <div className="error m-1">{formik.errors.selectedIngredients}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="selectDesign">Select design</label><br />
                                <span style={{ fontSize: '13px' }}>Use this field to select one of our templates.</span>
                                <select
                                    className="form-control"
                                    id="selectDesign"
                                    name="selectDesign"
                                    value={formik.values.selectDesign}
                                    onChange={(event) => {
                                        const selectedDesign = event.target.value;
                                        formik.handleChange(event); // Handle change
                                        formik.setFieldValue('selectDesign', selectedDesign); // Set the value in Formik state
                                    }}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="" disabled>Select a design</option>
                                    <option value="custom">The kitchen does not deliver this day</option>
                                    <option value="summer">Summer vacation</option>
                                    <option value="winter">Winter holiday</option>
                                    <option value="Christmas">Christmas holiday</option>
                                    <option value="Autumn">Autumn holiday</option>
                                    <option value="Easter">Easter</option>
                                    <option value="covid">COVID-19</option>
                                </select>
                                {/* Display validation error for selectDesign */}
                                {formik.touched.selectDesign && formik.errors.selectDesign && (
                                    <div className="error m-1">{formik.errors.selectDesign}</div>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-floating">
                                <label htmlFor="title"> Edit Titile</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    placeholder="Enter Title for the message"
                                    name="title"
                                    value={formik.values.title}  // Updated line
                                    onChange={formik.handleChange} // Updated line
                                    onBlur={formik.handleBlur}
                                    autoComplete="on"
                                />
                            </div>
                            {formik.touched.title && formik.errors.title && (
                                <div className="error m-1">{formik.errors.title}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <div className="form-floating">
                                <label htmlFor="text"> Edit Text</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="text"
                                    placeholder="Enter text for the message"
                                    name="text"
                                    value={formik.values.text}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoComplete="on"
                                />
                            </div>
                            {formik.touched.text && formik.errors.text && (
                                <div className="error m-1">{formik.errors.text}</div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-primary" type='button' onClick={closingPreview}>Preview</button>
                        <button className="btn btn-primary" type="submit">Add Period</button>
                        <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                    </DialogActions>
                </form>
            </Dialog >
            <Dialog open={openModal} onClose={onCloseModal} className='mui-modal large-dialog'>
                <DialogTitle>
                    New Closing period preview
                </DialogTitle>
                <DialogContent>
                    <table className="table table-hover table-bordered custom-table">
                        <tbody>
                            <tr>
                                <th scope="row" className='table-secondary'>Name</th>
                                <th>{formik.values.closingName}</th>
                            </tr>
                            <tr>
                                <th scope="row" className='table-secondary'>Start</th>
                                <th>{new Date(formik.values.closingPeriod.start).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}</th>
                            </tr>
                            <tr>
                                <th scope="row" className='table-secondary'>End</th>
                                <th>{new Date(formik.values.closingPeriod.end).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}</th>
                            </tr>
                            <tr>
                                <th scope="row" className='table-secondary'>Sales places</th>
                                <th>
                                    {formik.values?.selectedIngredients[0]?.id === 'selectAll'
                                        ? 'All School'
                                        : Object.keys(formik.values?.selectedIngredients).length > 0
                                            ? formik.values.selectedIngredients.map((item) => item.schoolName).join(', ')
                                            : 'No Record found'
                                    }
                                </th>
                            </tr>
                            <tr>
                                <th scope="row" className='table-secondary'>Design</th>
                                <th>
                                    <span className='font-weight-bold text-dark'> {formik.values.title}</span> <br />
                                    <span>{formik.values.text}</span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" onClick={onCloseModal}>Confirm</button>
                    <button className="btn btn-secondary" onClick={onCloseModal}>Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddClosed