import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { image_url } from "../../utils/baseUrl";

const getTransactionList = async (data) => {
    const headers = config();
    const response = await axios.get(`${image_url}web/api/v1/transaction/getlist/?search=${data.search}&pageNumber=${data.pageNumber || ''}&pageSize=${data.pageSize || ''}`, headers);
    return response.data.data;
};

const TransactionService = {
    getTransactionList,
};

export default TransactionService;