import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import DefaultPagination from '../../config/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderList, updateOrderStatus } from '../../features/orders/orders.slice';
import Pagination from '../Common/Pagination';
import { MENU_IMAGE_PATH, image_url } from '../../utils/baseUrl';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./list.css"

const List = () => {

  const ORDER_PLACED = 1;
  const ORDER_REJECT = 2;
  const ORDER_DELIVER = 3;

  // let orderData = {
  //   pageNumber: DefaultPagination.PAGE_NUMBER,
  //   pageSize: DefaultPagination.PAGE_SIZE,
  // };

  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchBy, setSearchBy] = useState("");
  const [orderData, setOrderData] = useState({
    pageNumber: DefaultPagination.PAGE_NUMBER,
    pageSize: DefaultPagination.PAGE_SIZE,
    key: "",
    value: "",
  });

  const handleChange = (event) => {
    setSearchBy(event.target.value);
  };


  useEffect(() => {
    dispatch(getOrderList(orderData));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [orderData.pageNumber, orderData.pageSize]);

  const changeTableData = (event) => {
    const newSize = parseInt(event.target.value);

    setOrderData((prevData) => ({
      ...prevData,
      pageSize: newSize,
      pageNumber: DefaultPagination.PAGE_NUMBER,
    }));

    dispatch(getOrderList({
      ...orderData,
      pageSize: newSize,
      pageNumber: DefaultPagination.PAGE_NUMBER,
    }));
  };
  let searchTimeout;

  const handleSearchOrders = (event) => {
    const keyword = event.target.value;

    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setOrderData((prevData) => ({
        ...prevData,
        value: keyword,
        pageNumber: DefaultPagination.PAGE_NUMBER,
      }));

      dispatch(getOrderList({
        ...orderData,
        key: searchBy,
        value: keyword,
        pageNumber: DefaultPagination.PAGE_NUMBER,
      }));
    }, 300);
  };




  const paginationWithPage = (pageNumber) => {
    setOrderData((prevData) => ({
      ...prevData,
      pageNumber,
    }));

    dispatch(getOrderList({
      ...orderData,
      pageNumber,
    }));
  };

  const { orders } = useSelector((state) => state.orders);

  // Open Order Detail tab
  const handleClick = (id) => {
    open === id ? setOpen(null) : setOpen(id)
  }

  // This function handle the Delivery status
  const handelDeliver = async (id) => {
    const postData = {
      orderId: id,
      orderStatus: ORDER_DELIVER
    }
    const response = await dispatch(updateOrderStatus(postData));
    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getOrderList(orderData));
    } else {
      toast.error(response.payload.message);
    }
  }

  // This function handle the Reject status
  const handelReject = async (id) => {
    const postData = {
      orderId: id,
      orderStatus: ORDER_REJECT
    }
    const response = await dispatch(updateOrderStatus(postData));
    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getOrderList(orderData));
    } else {
      toast.error(response.payload.message);
      dispatch(getOrderList(orderData));
    }
  }

  const handleSearchByChange = (event) => {
    const selectedValue = event.target.value;
  
    if (selectedValue === "") {
      setOrderData((prevData) => ({
        ...prevData,
        key: "",
        value: "",
        pageNumber: DefaultPagination.PAGE_NUMBER,
      }));
  
      dispatch(getOrderList({
        ...orderData,
        key: "",
        value: "",
        pageNumber: DefaultPagination.PAGE_NUMBER,
      }));
    } else {
      setSearchBy(selectedValue);
      setOrderData((prevData) => ({
        ...prevData,
        key: selectedValue,
      }));
    }
  };
  

  return (
    <>
      {/* Breadcrumb */}
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Order
          </li>
        </ol>
      </nav>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">
            Orders List
          </h1>
        </div>
        <div className="card shadow mb-4">
          <div className="card-body">
            {/* Add loader */}
            {loading ?
              (
                <Loader />
              ) :
              (
                <div className="table-responsive">
                  <div className="row">
                    {/* Page size dropdown */}
                    <div className='col-md-6'>
                      <PageLimit changeLimit={(e) => changeTableData(e)} />
                    </div>
                    <div className="col-sm-12 col-md-6 row">
                      <div className="mb-3 col-md-8 col-sm-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <label htmlFor="searchBySelect" className="form-label ">
                          Search By
                        </label>
                        <select id="searchBySelect" className="form-select" value={orderData.key} onChange={handleSearchByChange}>
                          <option value="">select option</option>
                          <option value="orderid">Order ID</option>
                          <option value="name">Name</option>
                          <option value="email">Email</option>
                          <option value="childname">Child Name</option>
                        </select>
                      </div>


                      <div id="dataTable_filter" className="dataTables_filter col-md-4 col-sm-6">
                        <label>{/* Search input */}
                          <input type="search" placeholder="search Inqueries" className="form-control form-control-sm" aria-controls="dat aTable" onChange={handleSearchOrders} />
                        </label>
                      </div>
                    </div>

                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          {/* <th scope="col" width="5%">No</th> */}
                          <th scope="col">Order Id</th>
                          <th scope="col">Order By</th>
                          <th scope="col">Order for (Child)</th>
                          <th scope="col">Order Price</th>
                          <th scope="col">Order Quantity</th>
                          <th scope="col">Delivery Date</th>
                          {/* <th scope="col">Order Status</th> */}
                          <td scope='col'>Change Order Status</td>
                          <td scope='col'>Order List</td>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Render Inquiries data */}
                        {
                          (orders?.orders != undefined && orders?.orders.length > 0) ?
                            orders?.orders.map((item, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    {/* <td scope="row">{index + 1}</td> */}
                                    <td>{item.id}</td>
                                    <td>{item.User?.email}</td>
                                    <td>{item.Child?.name}</td>
                                    <td>{item.totalPrice}</td>
                                    <td>{item?.OrdersItems[0]?.quantity}</td>
                                    <td>{item.deliveryDate}</td>
                                    {/* <td>{item.status === ORDER_PLACED ? 'Order Placed' : item.status === ORDER_REJECT ? 'Order Rejected' : item.status === ORDER_DELIVER ? 'Order Delivered' : 'Not Found'}</td> */}
                                    <td>
                                      <button
                                        className={`btn btn-success mr-2 ${item.status === ORDER_DELIVER ? 'active' : ''}`}
                                        disabled={item.status === ORDER_REJECT ? 'disabled' : ''}
                                        onClick={() => { item.status !== ORDER_DELIVER ? handelDeliver(item.id) : toast.success('This Order is already Delivered') }}
                                      >
                                        Deliver
                                      </button>
                                      <button
                                        className={`btn btn-danger ${item.status === ORDER_REJECT ? 'active' : ''}`}
                                        disabled={item.status === ORDER_DELIVER ? 'disabled' : ''}
                                        onClick={() => { item.status !== ORDER_REJECT ? handelReject(item.id) : toast.error('This Order is Rejected') }}
                                      >
                                        Reject
                                      </button>
                                    </td>
                                    <td>
                                      <a
                                        className="actionIcon"
                                        title="View"
                                        onClick={() => { handleClick(item.id) }}
                                      >
                                        {
                                          open === item.id
                                            ? <span><img src="assets/img/svg/eyelashes_7197670.png" height={30} width={30} alt="View" /></span>
                                            : <span><img src="assets/img/svg/close-eye_8442590.png" height={40} width={40} alt="View" /></span>
                                        }
                                      </a>
                                    </td>
                                  </tr >
                                  {
                                    open === item.id && (
                                      <>
                                        <tr>
                                          <td colSpan="8"> {/* Set the colSpan to the number of columns in the first table */}
                                            <div>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" colSpan={7}>Order Details For the Order ID :- {item.id}</th>
                                                  </tr>
                                                  <tr>
                                                    <th scope="col">Item Name</th>
                                                    <th scope="col">Item Image</th>
                                                    <th scope="col">Item Description</th>
                                                    <th scope="col">Item Price</th>
                                                    <th scope="col">Item Quantity</th>
                                                    <th scope="col">Option Name</th>
                                                    <th scope="col">Option price</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {
                                                    (item.OrdersItems != undefined && item.OrdersItems.length > 0)
                                                      ? item.OrdersItems.map((item, index) => {
                                                        return (
                                                          <>
                                                            <tr key={index}>
                                                              <td dangerouslySetInnerHTML={{ __html: item.itemName }} />
                                                              {/* <td>{item.itemName}</td> */}
                                                              <td>
                                                                {
                                                                  item.image !== undefined ? (
                                                                    <div className="bordered-img-table">
                                                                      <img src={`${image_url}${MENU_IMAGE_PATH}` + item?.image} className="table-img" alt="Menu" />
                                                                    </div>
                                                                  ) : "--"
                                                                }
                                                              </td>
                                                              <td dangerouslySetInnerHTML={{ __html: item.description }} />
                                                              {/* <td>{item.description}</td> */}
                                                              <td>{item.price}</td>
                                                              <td>{item.quantity}</td>
                                                              <td>{JSON.parse(item?.options)?.title || ''}</td>
                                                              <td>{JSON.parse(item?.options)?.price || 0}</td>
                                                            </tr>
                                                          </>
                                                        )
                                                      })
                                                      :
                                                      <tr>
                                                        <td colSpan={5}> No data Found</td>
                                                      </tr>
                                                  }
                                                </tbody>
                                              </table>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  }
                                </>
                              );
                            }) :
                            // Render "No records found" if there are no Schools
                            <tr>
                              <td colSpan="8" className="noRecordFound">No records found</td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination component */}
                  <Pagination
                    totalPage={orders?.pagination?.totalPages || 1}
                    currentPage={orders?.pagination?.currentPage || 1}
                    fullPagination={paginationWithPage}
                    totalFaqs={orders?.pagination?.total || 1}
                    pageSize={orders?.pagination?.pageSize || 10}
                  />
                </div>

              )
            }
          </div>
        </div>
      </div >
      {/* Edit School dialog */}
      {/* <EditSchool
        open={openEditSchoolDialog}
        onClose={() => setOpenEditSchoolDialog(false)}
        schoolData={schoolData}
        schoolId={editingSchoolId}
      /> */}

      {/* View School dialog */}
      {/* <ViewSchool
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        // schoolData={schoolData}
        schoolId={viewingSchoolId}
      /> */}

      {/* Delete School dialog */}
      {/* <DeleteSchool
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        schoolId={deletingSchoolId}
        schoolData={schoolData}
      /> */}
      {/* Add School dialog */}
      {/* <AddSchool
        open={openAddSchoolDialog}
        onClose={handleCloseAddSchoolDialog}
        schoolData={schoolData}
      /> */}
    </>
  )
}

export default List
