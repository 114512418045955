import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { updatePassword, currentUser } from '../../features/auth/auth.slice'; // Import the appropriate action

const ChangePassword = () => {
  const dispatch = useDispatch();

  let schema = yup.object().shape({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup
      .string()
      .required('New Password is required')
      .min(8, 'New Password must be at least 8 characters'),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const response = await dispatch(updatePassword(values)); // Dispatch the updatePassword action
      if (response.payload.status) {
        toast.success(response.payload.message);
        dispatch(currentUser());
        // Clear the form
        formik.resetForm();
      } else {
        toast.error(response.payload.message);
      }
    },
  });

  return (
    <>
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>

          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Change Password
          </li>
        </ol>
      </nav>

      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">Change Password</h1>
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-5">
                      <div className="form-group">
                        <label>Old Password</label>
                        <input
                          type="password"
                          className="form-control form-control-user"
                          name="oldPassword"
                          value={formik.values.oldPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.oldPassword && formik.errors.oldPassword && (
                          <div className="error m-1">{formik.errors.oldPassword}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label>New Password</label>
                        <input
                          type="password"
                          className="form-control form-control-user"
                          name="newPassword"
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.newPassword && formik.errors.newPassword && (
                          <div className="error m-1">{formik.errors.newPassword}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          className="form-control form-control-user"
                          name="confirmPassword"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                          <div className="error m-1">{formik.errors.confirmPassword}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-user btn-block">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-sm-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
