import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSelector, useDispatch } from 'react-redux';
import { getFaq } from "../../features/faqs/faqs.slice";

const ViewFaq = ({ open, onClose, faqData, faqId }) => {
    const dispatch = useDispatch();

    // Fetch FAQ data by ID using getFaq API
    useEffect(() => {
        if (faqId) {
            dispatch(getFaq(faqId));
        }
    }, [faqId, dispatch]);

    // Get FAQ data from Redux store
    const faq = useSelector((state) => state.faqs.getFaq);

    // Define a function to extract the video ID from a YouTube video link
    const extractVideoIdFromLink = (videoLink) => {
        const videoIdRegex = /(?:\/|%3D|v=|vi=)([0-9A-Za-z_-]{11})(?:[%#?&]|$)/;
        const match = videoLink.match(videoIdRegex);
        return match ? match[1] : null;
    };

    const renderVideo = () => {
        if (faq && faq.videoLink) {
            // Extract video ID from the video link
            const videoId = extractVideoIdFromLink(faq.videoLink);

            // Construct the Embedded Player URL
            const embeddedPlayerUrl = `https://www.youtube.com/embed/${videoId}`;

            return (
                <div className="form-group">
                    <label>Video</label>
                    <div className="video-container">
                        <iframe
                            title="Video"
                            src={embeddedPlayerUrl}
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            width="80%" // Increase video width to 100%
                            height="450px" // Set a custom height for the video container
                        ></iframe>
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderReferenceLink = () => {
        if (faq && faq.referenceLink) {
            return (
                <div className="form-group">
                    <label>Reference Link: </label>
                    <a
                        href={faq.referenceLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {faq.referenceLink}
                    </a>
                </div>
            );
        }
        return null;
    };


    return (
        <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
            <DialogTitle>View FAQ</DialogTitle>
            <DialogContent>
                {/* Display FAQ details */}
                <div className="form-group">
                    <label>Question</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: faq?.question }} />
                </div>

                <div className="form-group">
                    <label>Answer</label>
                    <div className="faq-content" dangerouslySetInnerHTML={{ __html: faq?.answer }} />
                </div>

                {renderVideo()}

                {renderReferenceLink()}
            </DialogContent>
            <DialogActions>
                <button className="btn btn-secondary" onClick={onClose}>Close</button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewFaq;
