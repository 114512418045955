import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { getSchool, getSchoolList, updateSchool } from '../../features/schools/schools.slice';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import * as yup from "yup";

export const EditSchool = ({ open, onClose, schoolData, schoolId }) => {
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        schoolName: yup.string().required('School Name is required'),
        schoolAddress: yup.string().required('School Address is required'),
        email: yup.string().email('Enter correct email address of school'),
    });

    // Fetch School data by ID using getSchool API
    useEffect(() => {
        if (schoolId) {
            dispatch(getSchool(schoolId));
        }
    }, [schoolId]);
    const school = useSelector((state) => state.schools.getSchool);

    const formik = useFormik({
        initialValues: {
            schoolName: school?.data?.school?.schoolName || '',
            schoolAddress: school?.data?.school?.schoolAddress || '',
            email: school?.data?.school?.email || '',
        },
        validationSchema: schema,
        enableReinitialize: true,

        onSubmit: async (values) => {
            try {
                const postData = {
                    idSchool: schoolId,
                    ...values,
                };

                const response = await dispatch(updateSchool(postData));

                if (response.payload.status) {
                    toast.success(response.payload.message);
                    dispatch(getSchoolList(schoolData));
                    onClose();
                } else {
                    toast.error(response.payload.message);
                }
            } catch (error) {
                // Handle error if necessary
                console.log(error)
                toast.error("Error submitting the form.", error);
            }
        },
    });

    return (
        <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Edit FAQ</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="schoolName">School Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="schoolName"
                                placeholder="Enter School Name"
                                name="schoolName"
                                value={formik.values.schoolName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.schoolName && formik.errors.schoolName && (
                            <div className="error m-1">{formik.errors.schoolName}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="address">School Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="schoolAddress"
                                placeholder="Enter School Address"
                                name="schoolAddress"
                                value={formik.values.schoolAddress}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.schoolAddress && formik.errors.schoolAddress && (
                            <div className="error m-1">{formik.errors.schoolAddress}</div>
                        )}
                    </div>

                    {/* Input box for the email address */}
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="email">School Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="schoolEmail"
                                placeholder="Enter School Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.email && formik.errors.email && (
                            <div className="error m-1">{formik.errors.email}</div>
                        )}
                    </div>

                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Edit</button>
                    <button className="btn btn-secondary" type='button' onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditSchool;