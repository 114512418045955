import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStats } from "../features/users/users.slice";
import { getOrderStats } from '../features/orders/orders.slice';
import MyBarChart from './Common/MyChart';

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserStats());
    dispatch(getOrderStats());
  }, [dispatch]);

  const { userStat } = useSelector((state) => state.users);
  const { orderStats } = useSelector((state) => state.orders);

  return (
    <>
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>

          </li>
        </ol>
      </nav>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid">

        <div className="row">
          {/* <!-- Number of users --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total number of registered users</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{userStat?.data?.totalUsers}</div>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/users"><i className="fas fa-users fa-2x text-gray-300"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Number of active users --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total number of active/not-blocked users</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{userStat?.data?.activeUsers}</div>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/users"><i className="fas fa-users fa-2x text-gray-300"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Number of active users --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">Total number of inactive/blocked users</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{userStat?.data?.inactiveUsers}</div>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/users"><i className="fas fa-users fa-2x text-gray-300"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Total Number of Orders --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total number of Orders</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{orderStats?.total_order}</div>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/orders"><i className="fas fa-shopping-bag fa-2x text-gray-300"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Total Number of Placed Orders --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total number of Placed Orders</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{orderStats?.placed_order}</div>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/orders"><i className="fas fa-shopping-bag fa-2x text-gray-300"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Total Number of Delivered Orders --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total number of Delivered Orders</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{orderStats?.delivered_order}</div>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/orders"><i className="fas fa-shopping-bag fa-2x text-gray-300"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Total Number of Canceled Orders --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">Total number of cancelled Orders</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{orderStats?.cancel_order}</div>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/orders"><i className="fas fa-shopping-bag fa-2x text-gray-300"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Line chart */}
          <MyBarChart />
        </div>
      </div>
    </>
  )
}

export default Dashboard
