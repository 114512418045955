import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/auth.slice";
import categoriesReducer from "../features/categories/categories.slice";
import usersReducer from "../features/users/users.slice";
import ingredientsReducer from "../features/ingredients/ingredients.slice";
import faqsReducer from "../features/faqs/faqs.slice";
import menusReducer from "../features/menus/menus.slice";
import inqueriesReducer from "../features/inqueries/inqueries.slice";
import contactReducer from "../features/contact/contact.slice"
import schoolsReducer from "../features/schools/schools.slice";
import classesReducer from "../features/classes/classes.slice";
import hotmealsSlice from "../features/hotmeals/hotmeals.slice";
import ordersSlice from "../features/orders/orders.slice";
import productionReducer from "../features/production/production.slice";
import transactionSlice from "../features/transactions/transaction.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categoriesReducer,
    users: usersReducer,
    ingredients: ingredientsReducer,
    faqs: faqsReducer,
    menus: menusReducer,
    inqueries: inqueriesReducer,
    contacts: contactReducer,
    schools: schoolsReducer,
    classes: classesReducer,
    hotmeals: hotmealsSlice,
    orders: ordersSlice,
    production: productionReducer,
    transaction: transactionSlice,
  },
});

