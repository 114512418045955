import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import HotmealService from "./hotmeals.services";

export const getHotmeals = createAsyncThunk(
    "meals/get-hotmeals",
    async (hotMealData, thunkAPI) => {
        try {
            return await HotmealService.getHotMeals(hotMealData);
        } catch (error) {
            return await error.response.data;
        }
    }
);


export const createHotMeals = createAsyncThunk(
    "meals/create-hotmeals",
    async (menuData, thunkAPI) => {
        try {
            return await HotmealService.createHotMeals(menuData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const updateHotMeals = createAsyncThunk(
    "meals/update-hotmeal",
    async (hotMealData, thunkAPI) => {

        try {
            return await HotmealService.updateMeal(hotMealData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const updateActiveInactive = createAsyncThunk(
    "meals/update-active-inactive",
    async (data, thunkAPI) => {

        try {
            return await HotmealService.updateActiveInactive(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const deleteMenu = createAsyncThunk(
    "menus/delete-menu",
    async (id, thunkAPI) => {
        try {
            return await HotmealService.deleteMenu(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getMeal = createAsyncThunk(
    "meal/get-hot-meal",
    async (id, thunkAPI) => {

        try {
            return await HotmealService.getMeal(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

// export const searchMenus = createAsyncThunk(
//   "menus/search-menus",
//   async (menu, thunkAPI) => {
//     try {
//       return await MenuService.searchMenus(menu);
//     } catch (error) {
//       return await error.response.data;
//     }
//   }
// );

export const resetState = createAction("RevertAll");

const initialState = {
    hotmeal: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const hotmealSlice = createSlice({
    name: "hotmeal",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHotmeals.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getHotmeals.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.hotmeal = action.payload;
            })
            .addCase(getHotmeals.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createHotMeals.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createHotMeals.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createdMenu = action.payload;
            })
            .addCase(createHotMeals.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateHotMeals.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateHotMeals.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedMenu = action.payload;
            })
            .addCase(updateHotMeals.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getMeal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMeal.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.getMeal = action.payload.data;
                state.categoryName = action.payload.data.title;
            })
            .addCase(getMeal.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateActiveInactive.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateActiveInactive.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload.message

            })
            .addCase(updateActiveInactive.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            //   .addCase(searchMenus.pending, (state) => {
            //     state.isLoading = true;
            //   })
            //   .addCase(searchMenus.fulfilled, (state, action) => {
            //     state.isLoading = false;
            //     state.isError = false;
            //     state.isSuccess = true;
            //     state.menus = action.payload
            //     state.message = action.payload.message

            //   })
            //   .addCase(searchMenus.rejected, (state, action) => {
            //     state.isLoading = false;
            //     state.isError = true;
            //     state.isSuccess = false;
            //     state.message = action.error;
            //   })
            .addCase(resetState, () => initialState);
    },
});



export default hotmealSlice.reducer;
