import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deleteInquery, getInqueries } from '../../features/inqueries/inqueries.slice';

export const DeleteInquery = ({ open, onClose, inqueryId, inqueryData }) => {

    const dispatch = useDispatch();
    const handleConfirmDeleteInquery = async () => {
        onClose();

        const response = await dispatch(deleteInquery(inqueryId));
        if (response.payload.status) {
            toast.success('deleted successfully');
            dispatch(getInqueries(inqueryData));

        } else {
            toast.error(response.payload.message);
        }
    }
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal">
            <DialogTitle>Delete FAQ</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this FAQ?
            </DialogContent>
            <DialogActions>
                <button className="btn btn-primary" onClick={handleConfirmDeleteInquery}>Delete</button>
                <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteInquery;