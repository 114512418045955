import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';

const SideBar = () => {

    const [showMenu, setShowMenu] = useState(false);
    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <a className="sidebar-brand d-flex align-items-center justify-content-center">
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                </div>
                <div className="sidebar-brand-text mx-3">
                    Deniz
                </div>
            </a>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item active">
                <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>
                        Dashboard
                    </span>
                </Link>
            </li>


            <hr className="sidebar-divider" />

            <React.Fragment>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/users">
                        <i className="fas fa-fw fa-users"></i> {/* Updated icon Users */}
                        <span>
                            Users
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/menus">
                        <i className="fas fa-fw fa-utensils"></i> {/* Updated icon Menus */}
                        <span>
                            Menus
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/hot-meals">
                        <i className="fas fa-fw fa-utensils"></i> {/* Updated icon Hot meals */}
                        <span>
                            Hot Meals
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/orders">
                        <i className="fas fa-fw fa-file-alt"></i> {/* Updated icon Order */}
                        <span>
                            Orders
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/print-out">
                        <i className="fa fa-cog"></i> {/* Updated icon Order */}
                        <span>
                            Production & Distribution List
                        </span>
                    </Link>
                </li>


                <li className="nav-item">
                    <Link className="nav-link" to="/admin/transactions">
                        <i className="fa fa-credit-card"></i> {/* Updated icon Order */}
                        <span>
                            All Transactions
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/categories">
                        <i className="fas fa-fw fa-tags"></i> {/* Updated icon Cat */}
                        <span>
                            Categories
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/ingredients">
                        <i className="fas fa-fw fa-carrot"></i> {/* Updated icon */}
                        <span>
                            Ingredients
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/schools">
                        <i className="fas fa-fw fa-graduation-cap"></i> {/* Updated icon */}
                        <span>
                            School
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/classes">
                        <i className="fas fa-fw fa-graduation-cap"></i> {/* Updated icon */}
                        <span>
                            Classes
                        </span>
                    </Link>
                </li>

                {/* <li className="nav-item">
                    <Link className="nav-link" to="/admin/closed-days">
                        <i className="fa fa-umbrella"></i>
                        <span>
                            Closed Days
                        </span>
                    </Link>
                </li> */}

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/inqueries">
                        <i className="fas fa-fw fa-info"></i> {/* Updated icon */}
                        <span>
                            Feedback
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/contact">
                        <i className="fas fa-fw fa-file-alt"></i> {/* Updated icon */}
                        <span>
                            Contact us
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/faqs">
                        <i className="fas fa-fw fa-question-circle"></i> {/* Updated icon */}
                        <span>
                            Faqs
                        </span>
                    </Link>
                </li>

                <li className="treeview nav-item ">
                    <a className="nav-link" onClick={() => { setShowMenu(!showMenu) }}>
                        <i className="fas fa-fw fa-file-alt" aria-hidden="true"></i>
                        <span>Account Close</span>
                        <i className="fa fa-angle-left pull-right" style={{ float: 'right', marginTop: '7px', transform: showMenu ? 'rotate(-90deg)' : 'rotate(0deg)' }} />
                    </a>
                    {showMenu && (
                        <>
                            <ul className="treeview-menu nav-item" style={{ overflow: 'hidden' }}>
                                <Link className='nav-link' to={'/admin/request-delete'}>
                                    <span>Close Request</span>
                                </Link>
                            </ul>
                            <ul className="treeview-menu" style={{ overflow: 'hidden' }}>
                                <Link className="nav-link" to={'/admin/closed-account'}>
                                    <span>Closed</span>
                                </Link>
                            </ul>
                        </>
                    )}
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/admin/pages">
                        <i className="fas fa-fw fa-question-circle"></i> {/* Updated icon */}
                        <span>
                            Edited Pages
                        </span>
                    </Link>
                </li>

            </React.Fragment>

            <hr className="sidebar-divider" />

        </ul>
    )
}

export default SideBar;
