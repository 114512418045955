import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import UserService from "./users.services";

const initialState = {
    users: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const getUsers = createAsyncThunk(
    "users/get-users",
    async (userData, thunkAPI) => {
        try {
            return await UserService.getUsers(userData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const searchUsers = createAsyncThunk(
    "users/search-users",
    async (userData, thunkAPI) => {
        try {
            return await UserService.searchUsers(userData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const blockUnblockUser = createAsyncThunk(
    "users/block-unblock-user",
    async (id, thunkAPI) => {

        try {
            return await UserService.blockUnblockUser(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getUserStats = createAsyncThunk(
    "users/get-user-stats",
    async (thunkAPI) => {
        try {
            return await UserService.getUserStats();
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const deleteUser = createAsyncThunk(
    "users/delete-user",
    async (id, thunkAPI) => {
        try {
            return await UserService.deleteUser(id);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const requestDeleteUser = createAsyncThunk(
    "users/req-delete-user",
    async (id, thunkAPI) => {
        try {
            return await UserService.requestDeleteUser();
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getAllDeletedUsers = createAsyncThunk(
    "users/get-deleted-users",
    async (userData, thunkAPI) => {
        try {
            return await UserService.getAllDeletedUsers(userData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getActiveUserCount = createAsyncThunk(
    "users/get-active-users-count",
    async () => {
        try {
            return await UserService.getActiveUsersCount();
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const resetState = createAction("RevertAll");

export const UserSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.users = action.payload;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(searchUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(searchUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.users = action.payload
                state.message = action.payload.message

            })
            .addCase(searchUsers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(blockUnblockUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(blockUnblockUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload.message

            })
            .addCase(blockUnblockUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getUserStats.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserStats.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.userStat = action.payload;
                state.message = action.payload.message

            })
            .addCase(getUserStats.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.deleteUser = action.payload;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(requestDeleteUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(requestDeleteUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.requestDeleteUser = action.payload;
            })
            .addCase(requestDeleteUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getAllDeletedUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDeletedUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.deletedUsers = action.payload;
            })
            .addCase(getAllDeletedUsers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getActiveUserCount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getActiveUserCount.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.getActiveUsersCount = action.payload;
            })
            .addCase(getActiveUserCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default UserSlice.reducer;