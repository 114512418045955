import { redirect } from 'react-router-dom';

export function getAuthToken() {
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    return token;
}

export function tokenLoader() {
    return getAuthToken();
}

export async function checkAuthLoader() {
    const token = await getAuthToken();
    if (!token) {
        return redirect('/');
    }
    
    return false;
}
