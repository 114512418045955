import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

// const getOrders = async (orders) => {
//     const headers = config();
//     const requestConfig = { ...headers };
//     if (orders && orders.pageSize && orders.pageNumber) {
//         requestConfig.params = {
//             key: orders.key || '',  
//             value: orders.value || '',
//             pageSize: orders.pageSize,
//             pageNumber: orders.pageNumber,
//             // search: orders.search ? orders.search : '',
//         };
//     }
//     const response = await axios.get(`${base_url}getOrdersList`, requestConfig);

//     return response.data.data;
// };

const getOrders = async (orders) => {
    const headers = config();
    const requestConfig = {
      ...headers,
      params: {
          key: orders.key || '',  // Include key filter
          value: orders.value || '', // Include value filter
        pageSize: orders.pageSize,
        pageNumber: orders.pageNumber,
      },
    };
  
    const response = await axios.get(`${base_url}getOrdersList`, requestConfig);
    return response.data.data;
  };
  

const getOrderStats = async () => {
    const headers = config();
    const response = await axios.get(`${base_url}getOrdersCount`, headers);
    return response.data.data[0];
};

const updateOrderStatus = async (orderData) => {
    const headers = config();
    const response = await axios.patch(`${base_url}updateOrderStatus/${orderData.orderId}/${orderData.orderStatus}`, {}, headers);

    return response.data;
}

const getUserOrders = async (orderData) => {
    const headers = config();
    try {
        const response = await axios.post(`${base_url}getuserOrdersbwtdates`, orderData, headers);
        return response?.data;
    } catch (error) {
        return error.response;
    }
}
const OrderService = {
    getOrders,
    updateOrderStatus,
    getOrderStats,
    getUserOrders,
};

export default OrderService;