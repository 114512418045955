import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import FaqService from "./faqs.services";

export const getFaqs = createAsyncThunk(
  "faqs/get-faqs",
  async (faqData, thunkAPI) => {
    try {
      return await FaqService.getFaqs(faqData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const createFaq = createAsyncThunk(
  "faqs/create-faq",
  async (faqData, thunkAPI) => {
    try {
      return await FaqService.createFaq(faqData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateFaq = createAsyncThunk(
  "faqs/update-faq",
  async (faqData, thunkAPI) => {
    
    try {
      return await FaqService.updateFaq(faqData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const updateActiveInactive = createAsyncThunk(
  "faqs/update-active-inactive",
  async (id, thunkAPI) => {
    
    try {
      return await FaqService.updateActiveInactive(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const deleteFaq = createAsyncThunk(
  "faqs/delete-faq",
  async (id, thunkAPI) => {
    try {
      return await FaqService.deleteFaq(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const getFaq = createAsyncThunk(
  "faqs/get-faq",
  async (id, thunkAPI) => {
    try {
      return await FaqService.getFaq(id);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const searchFaqs = createAsyncThunk(
  "faqs/search-faqs",
  async (faqData, thunkAPI) => {
    try {
      return await FaqService.searchFaqs(faqData);
    } catch (error) {
      return await error.response.data;
    }
  }
);

export const resetState = createAction("RevertAll");

const initialState = {
  faqs: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const faqslice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaqs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFaqs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.faqs = action.payload;
      })
      .addCase(getFaqs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdFaq = action.payload;
      })
      .addCase(createFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedFaq = action.payload;
      })
      .addCase(updateFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.deletedFaq = action.payload.message;
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.getFaq = action.payload.data;

      })
      .addCase(getFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateActiveInactive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateActiveInactive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload.message

      })
      .addCase(updateActiveInactive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(searchFaqs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchFaqs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.faqs = action.payload
        state.message = action.payload.message

      })
      .addCase(searchFaqs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default faqslice.reducer;
