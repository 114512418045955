import React, { useEffect, useState } from 'react'
import { Autocomplete, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material'
import { Space } from 'antd';
import './custom.css';
import { useFormik } from "formik";
import * as yup from "yup";
import { TimePicker } from 'antd';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { getSchoolList } from '../../features/schools/schools.slice';
import { createSchedule } from '../../features/production/production.slice';

function SchedulePrint({ openModal, onClose }) {

    const dispatch = useDispatch();
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    let schoolData = {
        pageNumber: 1,
        pageSize: 50,
    };

    // Use useEffect to fetch Schools when pageSize or pageNumber changes
    useEffect(() => {
        dispatch(getSchoolList(schoolData));
    }, [schoolData.pageNumber, schoolData.pageSize]);

    // Get Schools data from Redux store
    const { schools } = useSelector((state) => state.schools);
    const schoolsWithEmails = schools?.school ? schools?.school.filter(school => school.email) : [];

    const handleDeletePlan = () => {
        alert('handle delete plan function')
    }

    const [value, setValue] = useState(null);
    const onChangeDate = (time) => {
        // formik.setFieldValue('printTime', time.$d);
        const selectedHour = time.$d.getHours();
        if (selectedHour < 5) {
            // Display a warning message
            toast.warning("Maybe all the orders have not been placed Yet");
        }
        // Format the time as per 16:01 format
        const formattedTime = time.$d.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });

        formik.setFieldValue('schedule_time', formattedTime);
        setValue(time);
    };


    const schema = yup.object().shape({
        selectedEmails: yup.array().min(1, "At least one email is required"),
        dayChecked: yup.array().of(yup.string()).min(1, "At least one day is required"),
        schedule_time: yup.string().required('Enter valid time')
    });

    const formik = useFormik({
        initialValues: {
            dayChecked: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            schedule_time: '',
            today: 'true',
            tomorrow: 'false',
            selectedEmails: []
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {

            // const response = await commonFunction.saveScheduleData(values);
            const response = await dispatch(createSchedule(values));

            if (response.payload.status) {
                toast.success(response.payload.message);
                onClose();

                // Reset the form after successful submission
                formik.resetForm();
                setValue('');
                setSelectedIngredients([]);
                dispatch(getScheduledList(scheduleData));
            }

        },
    });


    return (
        <>
            <Dialog open={openModal} onClose={onClose} className="mui-modal">
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <div className="form-group">
                            <div className="form-floating">
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    Schedule printing
                                </Typography>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-floating">
                                <Typography variant="subtitle1" gutterBottom>
                                    Get the print list sent to your inbox every day
                                </Typography>
                            </div>
                        </div>

                        {/* Time input start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <Space wrap>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Print is sent every day at:
                                    </Typography>
                                    <TimePicker value={value} name='schedule_time' onChange={onChangeDate} format="HH:mm" />
                                </Space>
                                {formik.touched.schedule_time && formik.errors.schedule_time && (
                                    <div className="error m-1">{formik.errors.schedule_time}</div>
                                )}
                            </div>
                        </div>
                        {/* Time input end */}

                        <div className="form-group">
                            <div className="form-floating">
                                <Typography>
                                    <span className='error'>*</span> Send the print with data for
                                </Typography>
                            </div>
                        </div>

                        {/* Day Input start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <Typography>
                                    <button
                                        type='button'
                                        className={'btn ' + (formik.values.today === 'true' ? 'btn-primary' : 'border-dark')}
                                        style={{ 'borderRadius': '0px' }}
                                        onClick={() => {
                                            formik.setValues({ ...formik.values, today: 'true', tomorrow: 'false' });
                                        }}
                                    >
                                        Today
                                    </button>
                                    <button
                                        type='button'
                                        className={'btn ' + (formik.values.tomorrow === 'true' ? 'btn-primary' : 'border-dark')}
                                        style={{ 'borderRadius': '0px' }}
                                        onClick={() => {
                                            formik.setValues({ ...formik.values, today: 'false', tomorrow: 'true' });
                                        }}
                                    >
                                        Tomorrow
                                    </button>
                                </Typography>
                            </div>
                        </div>
                        {/* Day Input end */}

                        {/* Weekly input day start */}
                        {/* <div className="form-group">
                            <div className="form-floating">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="select-size justify-content-between">

                                            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day) => (
                                                <React.Fragment key={day}>
                                                    <input
                                                        type="checkbox"
                                                        name="dayChecked"
                                                        value={day}
                                                        id={day.toLowerCase()}
                                                        checked={formik.values.dayChecked.includes(day)}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <label htmlFor={day.toLowerCase()} className="rounded-circle">
                                                        {day}
                                                    </label>
                                                </React.Fragment>
                                            ))}
                                            {formik.touched.dayChecked && formik.errors.dayChecked && (
                                                <div className="error m-1">{formik.errors.dayChecked}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="form-group">
                            <div className="form-floating">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="select-size justify-content-between">
                                            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day) => {
                                                const isSelected = formik.values.dayChecked.includes(day);
                                                return (
                                                    <button
                                                        key={day}
                                                        type="button"
                                                        className={`rounded-circle day-button rounded-circle ${isSelected ? 'btn-primary' : 'btn-outline-dark'}`}
                                                        style={{
                                                            margin: '5px',
                                                            padding: '15px',
                                                            width: '50px',
                                                            height: '50px',
                                                            backgroundColor: isSelected ? '#ee4d1a' : '#ffffff', // Blue for selected, white for unselected
                                                            color: isSelected ? '#ffffff' : '#000000', // White text for selected, black for unselected
                                                            border: '1px solid #ccc', // Light border for better visibility
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            const updatedDays = isSelected
                                                                ? formik.values.dayChecked.filter(d => d !== day) // Remove day
                                                                : [...formik.values.dayChecked, day]; // Add day
                                                            formik.setFieldValue('dayChecked', updatedDays);
                                                        }}
                                                    >
                                                        {day}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                        {formik.touched.dayChecked && formik.errors.dayChecked && (
                                            <div className="error m-1">{formik.errors.dayChecked}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Weekly input day end */}

                        {/* email input start */}
                        <div className="form-group">
                            <div className="form-floating">
                                <div>
                                    <span className='error'>*</span> Email Address printed to ?
                                    <div className="form-group">

                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={schoolsWithEmails ? schoolsWithEmails : []}
                                            getOptionLabel={(option) => option.email}
                                            defaultValue={[schoolsWithEmails ? schoolsWithEmails[3] : '']}
                                            value={selectedIngredients}
                                            onChange={(_, newValue) => {
                                                const emailArray = newValue.map(schoolsWithEmails => schoolsWithEmails.email);
                                                setSelectedIngredients(newValue);
                                                formik.setFieldValue('selectedEmails', emailArray);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.schoolName === value.schoolName}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select Email"
                                                />
                                            )}
                                        />
                                        {formik.touched.selectedEmails && formik.errors.selectedEmails && (
                                            <div className="error m-1">{formik.errors.selectedEmails}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* email input start */}

                        <div className="form-group">
                            <div className="form-floating">
                                <DialogActions style={{ float: "left" }}>
                                    <button className="btn btn-danger" onClick={handleDeletePlan}>Delete the Plan</button>
                                </DialogActions>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                        <button className="btn btn-primary" type='submit'>Cofirm</button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default SchedulePrint