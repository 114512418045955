import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';

function ProductionData({ open, onClose, productionData, date, selectedSchoolName }) {

    const componentPdf = useRef();

    // function to print the Production data
    const generatePdf = useReactToPrint({
        content: () => componentPdf.current,
        documentTitle: 'Production Data',
        onAfterPrint: () => onClose()
    });

    return (
        <>
            <Dialog open={open} onClose={onClose} className="mui-modal xx-large-dialog">
                <DialogContent>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div ref={componentPdf} style={{ width: '100%' }}>
                                {/* <DialogTitle> */}
                                <Typography variant="h5" align="center" style={{ font: '600 25px/28px Raleway, sans-serif', marginTop: '25px' }}>Production List <br /> {selectedSchoolName} {date}</Typography>
                                {/* </DialogTitle> */}
                                <table className="table table-striped table-bordered" style={{ width: '76%', margin: '20px 0', marginLeft: '13%' }}>
                                    <thead className="thead-dark">
                                        <tr>
                                            {/* <th scope="col">#</th>
                                            <th scope="col">Date</th> */}
                                            <th scope="col" style={{ backgroundColor: '#212529', font: '600 25px/28px Raleway, sans-serif' }}>Menu</th>
                                            <th scope="col" style={{ backgroundColor: '#212529', font: '600 25px/28px Raleway, sans-serif' }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (productionData?.orders != undefined && productionData?.orders.length > 0) ? productionData?.orders.map((item, index) => (
                                                <tr key={index}>
                                                    {/* <th scope="row">{index + 1}</th> */}
                                                    {/* <td>
                                                        {item.deliveryDate}
                                                    </td> */}
                                                    {/* <td>
                                                        {item.itemName}
                                                    </td> */}
                                                    <td dangerouslySetInnerHTML={{ __html: item.itemName }} style={{ color: '#000000', font: '600 25px/25px Raleway, sans-serif' }} />
                                                    <td style={{ color: '#000000', font: '600 25px/25px Raleway, sans-serif' }}>{item.qty}</td>
                                                </tr>
                                            )) : 'No data Found '}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-5 d-flex justify-content-between">
                                <button type="button" className="btn btn-success" onClick={generatePdf}>
                                    Print data
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ProductionData