import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { DatePicker } from 'antd';
import { getSchoolList } from '../../features/schools/schools.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from "yup";
import { getDistributionList, getProductionList, getScheduledList } from '../../features/production/production.slice';
import CensorList from './censorList';
import ProductionData from './ProductionData';
import DistributionData from './DistributionData';
import { toast } from 'react-toastify';
import ScheduleList from './ScheduleList';

function ProductionList() {
    let schoolData = {
        pageNumber: 1,
        pageSize: 100,
    };
    const scheduleData = {
        pageNumber: 1,
        pageSize: 10,
    }
    const dispatch = useDispatch();
    const [date, setDate] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isCensorOpen, setIsCensorOpen] = useState(false);

    // Handel Production list pop up
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [productionListData, setProductionListData] = useState('');

    // Handel Distribution list pop up
    const [distributionListData, setDistributionListData] = useState();
    const [openDistributionList, setOpenDistributionList] = useState(false);

    //Getting the selected school name
    const [selectedSchoolName, setSelectedSchoolName] = useState('');

    // Getting the date in form
    const onChange = (date, dateString) => {
        setIsOpen(false);
        setDate(dateString);
    };

    // Use useEffect to fetch Schools when pageSize or pageNumber changes
    useEffect(() => {
        dispatch(getSchoolList(schoolData));
        dispatch(getScheduledList(scheduleData));
    }, [schoolData.pageNumber, schoolData.pageSize]);

    // Get Schools data from Redux store

    const { schools } = useSelector((state) => state.schools);

    // Get Production List data from Redux store
    const productionData = useSelector((state) => state.production.production);

    // Get Distribution List data from Redux store
    const distributionData = useSelector((state) => state.production.distribution);

    // Form  validation
    const schema = yup.object().shape({
        schoolId: yup.string().required("School is Required!"),
        date: yup.string().required('Date is required')
    });

    const formik = useFormik({
        initialValues: {
            schoolId: '',
            date: date
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const productionResponse = await dispatch(getProductionList(values));
                const distributionResponse = await dispatch(getDistributionList(values));

                if (productionResponse.payload && distributionResponse.payload) {
                    setIsOpen(true);
                }
            } catch (error) {
                // Handle error if necessary
                toast.error("Error submitting the form.");
            }
        },
    });

    const handleProductionList = () => {
        setOpenViewDialog(true);
        setProductionListData(productionData.data)
    }

    const handleDistributionList = () => {
        setOpenDistributionList(true)
        setDistributionListData(distributionData.data);
    }

    const handleCensorLunch = () => {
        isCensorOpen === true ? setIsCensorOpen(false) : setIsCensorOpen(true);
    }

    return (
        <>
            {/* Breadcrumb */}
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            Dashboard
                        </Link>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                        Product & Distribution section.
                    </li>
                </ol>
            </nav>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
                    <h1 className="h3 mb-0 text-gray-800 fontBold">
                        Product & Distribution.
                    </h1>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                <div className="col-md-6">
                                    <form onSubmit={formik.handleSubmit}>
                                        {/* For date */}
                                        <div className="form-group">

                                            <label htmlFor="date">Select date</label>
                                            <DatePicker onChange={onChange} style={{ width: '100%' }} format="DD/MM/YYYY" />
                                            {formik.touched.date && formik.errors.date && (
                                                <div className="error m-1">{formik.errors.date}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="schoolId">Select School</label>
                                            <select
                                                className="form-control"
                                                id="schoolId"
                                                name="schoolId"
                                                value={formik.values.schoolId}
                                                onChange={(event) => {
                                                    const selectedOption = event.target.options[event.target.selectedIndex];
                                                    const schoolName = selectedOption.text;
                                                    setSelectedSchoolName(schoolName);
                                                    setIsOpen(false);
                                                    const schoolId = parseInt(event.target.value); // Convert to number
                                                    formik.handleChange(event); // Handle change
                                                    formik.setFieldValue('schoolId', schoolId); // Set the value in Formik state
                                                }}
                                                onBlur={formik.handleBlur}
                                            >
                                                <option value="" disabled>Select a category</option>
                                                <option value="0">All Schools</option>

                                                {(schools?.school != undefined && schools?.school.length > 0) ? (
                                                    schools.school.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.schoolName}</option>
                                                    ))
                                                ) : (
                                                    <option disabled>No categories available</option>
                                                )}
                                            </select>
                                        </div>

                                        {formik.touched.schoolId && formik.errors.schoolId && (
                                            <div className="error m-1">{formik.errors.schoolId}</div>
                                        )}
                                        <div className="col-sm-12 col-md-6 pt-5">
                                            <button className="btn btn-primary" type="submit">Show Overview</button>
                                        </div>
                                    </form>
                                    {/* Buttons for the production and distribution */}
                                    {
                                        isOpen && (
                                            <div className="col-sm-6 col-md-12 pt-5">
                                                <button className="btn btn-primary" type="button" onClick={handleProductionList}>Production List</button>
                                                <button className="btn btn-primary ml-3" type="button" onClick={handleDistributionList}>Distribution List</button>
                                            </div>
                                        )
                                    }
                                    {/* Button for the Censor Lunches */}
                                    <div className="col-sm-6 col-md-6 pt-5">
                                        <button className="btn btn-secondary" type="button" onClick={handleCensorLunch}>Censor Lunches 2023</button>
                                    </div>
                                    {isCensorOpen && (
                                        <CensorList />
                                    )}
                                </div>
                                {/* Div for the list of scheduled production. */}
                                <ScheduleList
                                    scheduleData={scheduleData}
                                />
                            </div>

                            {/* View Production Item dialog */}
                            <ProductionData
                                open={openViewDialog}
                                onClose={() => setOpenViewDialog(false)}
                                productionData={productionListData}
                                date={date}
                                selectedSchoolName={selectedSchoolName}
                            />

                            {/* View Distribution Item dialog */}
                            <DistributionData
                                open={openDistributionList}
                                onClose={() => setOpenDistributionList(false)}
                                distributionData={distributionListData}
                                date={date}
                                selectedSchoolName={selectedSchoolName}
                            />
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default ProductionList