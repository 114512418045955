// Routes.js
import { createBrowserRouter } from "react-router-dom";
import Login from "./components/Login/Login";
import OrderList from "./components/Order/List";
import Dashboard from "./components/Dashboard";
import MainLayout from "./components/MainLayout";
import { checkAuthLoader } from "./utils/auth";
import Profile from './components/Admin/Profile';
import CategoryList from './components/Category/List'
import NotFound from "./components/NotFound/NotFound";
import UsersList from "./components/Users/List";
import MenuList from "./components/Menu/List";
import FaqList from "./components/Faq/List";
import IngredientList from "./components/Ingredient/List";
import ChangePassword from "./components/Admin/ChangePassword";
import InqueryList from "./components/Inquery/List";
import Contact from "./components/Contact/List";
import SchoolList from "./components/School/List";
import ClassesList from "./components/Classes/List";
import HotMealsList from './components/HotMeals/List'
import ProductionList from "./components/Production";
import ClosedDayList from "./components/ClosedDays/List";
import TransactionList from "./components/Transaction/Index";
import RequestDelete from "./components/Users/RequestDelete";
import ClosedAccount from "./components/Users/ClosedAccount";
import UserDetails from "./components/Users/UserDetails";
import WebsitePages from "./components/Pages/WebsitePages";

export const Routes = () => {
  return createBrowserRouter([
    { path: "/", element: <Login /> }, // Use "login" path instead of "/"
    {
      path: "/admin",
      element: <MainLayout />,
      loader: checkAuthLoader,
      children: [
        { index: true, element: <Dashboard /> },
        { path: "profile", element: <Profile /> },
        { path: "users", element: <UsersList /> },
        { path: "orders", element: <OrderList /> },
        { path: "categories", element: <CategoryList /> },
        { path: "menus", element: <MenuList /> },
        { path: "faqs", element: <FaqList /> },
        { path: "schools", element: <SchoolList /> },
        { path: "classes", element: <ClassesList /> },
        { path: 'inqueries', element: <InqueryList /> },
        { path: 'contact', element: <Contact /> },
        { path: "ingredients", element: <IngredientList /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "hot-meals", element: <HotMealsList /> },
        { path: "print-out", element: <ProductionList /> },
        { path: "closed-days", element: <ClosedDayList /> },
        { path: "transactions", element: <TransactionList /> },
        { path: "request-delete", element: <RequestDelete /> },
        { path: "closed-account", element: <ClosedAccount /> },
        { path: "users/user-detail/:idUser", element: <UserDetails /> },
        { path: "pages", element: <WebsitePages /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
};
