import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageLimit from '../Common/PageLimit';
import Pagination from '../Common/Pagination';
import { getCategories, updateActiveInactive, searchCategories } from "../../features/categories/categories.slice";
import { useDispatch, useSelector } from 'react-redux';
import DefaultPagination from '../../config/Pagination';
import { toast } from "react-toastify";
import { CAT_IMAGE_PATH, image_url } from '../../utils/baseUrl';
import DeleteCategory from './Delete'; // Import the DeleteCategory component
import AddCategory from './Add'; // Import the AddCategory component
import EditCategory from './Edit';

const List = () => {

  // Use state to manage category Data in pagination and search 
  const [categoryData, setCategoryData] = useState({
    pageSize: DefaultPagination.PAGE_SIZE,
    pageNumber: DefaultPagination.PAGE_NUMBER,
  });

  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingCategoryId, setDeletingCategoryId] = useState('');
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
  const [openEditCategoryDialog, setOpenEditCategoryDialog] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState('');

  // Use useEffect to fetch categories when pageSize or pageNumber changes
  useEffect(() => {
    dispatch(getCategories(categoryData));
  }, [categoryData.pageSize, categoryData.pageNumber]);

  // Get categories data from Redux store
  const { categories } = useSelector((state) => state.categories);

  // Function to handle change in page size dropdown
  const changeTableData = (e) => {
    // categoryData.pageSize = parseInt(e.target.value);
    // categoryData.pageNumber = DefaultPagination.PAGE_NUMBER;
    setCategoryData((prevUserData) => ({ ...prevUserData, pageSize: parseInt(e.target.value) }));
    dispatch(getCategories(categoryData));
  };

  // Function to handle pagination click with a specific page number
  const paginationWithPage = (pageNumber) => {
    // categoryData.pageNumber = pageNumber;
    setCategoryData((prevUserData) => ({ ...prevUserData, pageNumber: pageNumber }));
    dispatch(getCategories(categoryData));
  };

  // Function to handle delete category click
  const handleDeleteCategory = (categoryId) => {
    setDeletingCategoryId(categoryId);
    setOpenDeleteDialog(true);
  };

  const handleStatusChange = async (categoryId, status) => {
    const response = await dispatch(updateActiveInactive({ categoryId, status }));

    if (response.payload.status) {
      toast.success(response.payload.message);
      dispatch(getCategories(categoryData));
    } else {
      toast.error(response.payload.message);
    }
  };

  // Function to handle opening the Add Category dialog
  const handleOpenAddCategoryDialog = () => {
    setOpenAddCategoryDialog(true);
  };

  // Function to handle closing the Add Category dialog
  const handleCloseAddCategoryDialog = () => {
    setOpenAddCategoryDialog(false);
  };

  // Function to handle opening the Edit Category dialog
  const handleOpenEditCategoryDialog = (categoryId) => {
    setEditingCategoryId(categoryId);
    setOpenEditCategoryDialog(true);
  };

  // Function to handle closing the Edit Category dialog
  const handleCloseEditCategoryDialog = () => {
    setOpenEditCategoryDialog(false);
  };

  // Function to handle search as the user types in the search input field
  const handleSearchCategories = (keyword) => {
    categoryData.pageNumber = DefaultPagination.PAGE_NUMBER; // Reset page number on new search
    categoryData.search = keyword.target.value;
    dispatch(searchCategories(categoryData));
  };

  // JSX code for rendering the component UI
  return (
    <>
      {/* Breadcrumb */}
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin">
              Dashboard
            </Link>
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Categories
          </li>
        </ol>
      </nav>

      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
          <h1 className="h3 mb-0 text-gray-800 fontBold">
            Categories List
          </h1>
          <a>
            <span className="text">
              <button className="btn btn-primary" onClick={handleOpenAddCategoryDialog}>+ Add Category</button>
            </span>
          </a>
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                {/* Page size dropdown */}
                <PageLimit changeLimit={(e) => changeTableData(e)} />

                <div className="col-sm-12 col-md-6">
                  <div id="dataTable_filter" className="dataTables_filter">
                    <label>
                      {/* Search input */}
                      <input
                        type="search"
                        placeholder="search categories"
                        className="form-control form-control-sm"
                        aria-controls="dataTable"
                        onChange={handleSearchCategories} // Dynamically trigger search on input change
                      />
                    </label>
                  </div>
                </div>

                <table className="table table-bordered" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      {/* <th scope="col" width="5%">No</th> */}
                      <th scope="10%">Title</th>
                      <th scope="10%">Description</th>
                      <th scope="10%">Image</th>
                      <th scope="col">Status</th>
                      <th scope="col">Change Status</th>
                      <th width="10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Render categories data */}
                    {(categories?.data?.categories != undefined && categories?.data?.categories.length > 0) ?
                      categories?.data?.categories?.map((category, index) => {
                        return (
                          <tr key={index}>
                            {/* <td scope="row">{index + 1}</td> */}
                            <td>{category.title}</td>
                            <td>{category.description}</td>
                            <td>
                              {
                                category.imageUrl !== undefined && category.imageUrl ? (<div className="bordered-img-table">
                                  <img src={`${image_url}${CAT_IMAGE_PATH}` + category.imageUrl} className="table-img" />
                                </div>) : "--"
                              }
                            </td>
                            <td>{category.status ? "Active" : "Inactive"}</td>
                            <td className="tdTextCenter">
                              <div className="custom-control custom-checkbox small">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  htmlFor="customCheck"
                                  id="customCheck"
                                  name={['isChecked' + index]}
                                  checked={category.status}
                                  onChange={() => handleStatusChange(category.idCategory, category.status)}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                className="actionIcon"
                                title="Edit"

                                onClick={() => handleOpenEditCategoryDialog(category.idCategory)}
                              >
                                <span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span>
                              </a>
                              <a
                                className="actionIcon"
                                title="Delete"

                                onClick={() => handleDeleteCategory(category.idCategory)}
                              >
                                <span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                              </a>
                            </td>
                          </tr>
                        );
                      }) :
                      // Render "No records found" if there are no categories
                      <tr>
                        <td colSpan="6" className="noRecordFound">No records found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>

              {/* Pagination component */}
              <Pagination
                totalPage={categories?.data?.pagination?.totalPages || 1}
                currentPage={categories?.data?.pagination?.currentPage || 1}
                fullPagination={paginationWithPage}
                totalFaqs={categories?.data?.pagination?.totalCategories || 1}
                pageSize={categories?.data?.pagination?.pageSize || 10}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Add Category dialog */}
      <AddCategory
        open={openAddCategoryDialog}
        onClose={handleCloseAddCategoryDialog}
        categoryData={categoryData}
      />

      {/* Edit Category dialog */}
      <EditCategory
        open={openEditCategoryDialog}
        onClose={handleCloseEditCategoryDialog}
        categoryData={categoryData}
        categoryId={editingCategoryId}
      />

      {/* Delete category dialog */}
      <DeleteCategory
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        categoryId={deletingCategoryId}
        categoryData={categoryData}
      />
    </>
  );
}

export default List;
