import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { getIngredients, deleteIngredient } from "../../features/ingredients/ingredients.slice";
import { toast } from "react-toastify";

const DeleteIngredient = ({ open, onClose, ingredientId, ingredientData }) => {
  const dispatch = useDispatch();

  const handleConfirmDeleteIngredient = async () => {
    onClose();
    const response = await dispatch(deleteIngredient(ingredientId));

    if (response.payload.status) {
        toast.success(response.payload.message);
        dispatch(getIngredients(ingredientData));

    } else {
      toast.error(response.payload.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal">
      <DialogTitle>Delete Ingredient</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this ingredient?
      </DialogContent>
      <DialogActions>
        <button className="btn btn-primary" onClick={handleConfirmDeleteIngredient}>Delete</button>
        <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteIngredient;
