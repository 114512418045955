import React, { Component, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { currentUser } from "../../features/auth/auth.slice";
import { image_url, PROFILE_IMAGE_PATH } from "../../utils/baseUrl";

const TopBar = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(currentUser());
    }, []);

    const { user } = useSelector(state => state.auth)

    return (
        <Fragment>
            {/* <!-- Topbar --> */}
            <nav className="navbar navbar-expand navbar-light bg-white topbar static-top" style={{ backgroundImage: 'linear-gradient(to right, rgba(255,0,0,0), #2d363a)' }}>

                {/* <!-- Sidebar Toggle (Topbar) --> */}
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>

                <div>You can manage everything from this dashboard.</div>

                {/* <!-- Topbar Navbar --> */}
                <ul className="navbar-nav ml-auto">

                    {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <a className="nav-link dropdown-toggle" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-search fa-fw"></i>
                        </a>
                        {/* <!-- Dropdown - Messages --> */}
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    {/* <!-- Nav Item - Alerts --> */}

                    <div className="topbar-divider d-none d-sm-block"></div>

                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle profile-drop" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-600 small" style={{ color: '#fff' }}>
                                {user?.firstName}
                            </span>

                            <img className="img-profile rounded-circle"
                                src={(user?.avatarUrl) ? (image_url + PROFILE_IMAGE_PATH + user?.idUser + '/' + user?.avatarUrl) : "http://bootdey.com/img/Content/avatar/avatar1.png"}
                                alt='no'
                            />
                        </a>
                        {/* <!-- Dropdown - User Information --> */}
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <Link to="/admin/profile" className="dropdown-item">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                User Profile
                            </Link>

                            <Link to="/admin/change-password" className="dropdown-item">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Change Password
                            </Link>

                            <div className="dropdown-divider"></div>

                            <a className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>

                </ul>

            </nav>
            {/* <!-- End of Topbar --> */}
        </Fragment>
    )
}

export default TopBar
