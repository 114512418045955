import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { createSchool, getSchoolList } from '../../features/schools/schools.slice';

const schema = yup.object().shape({
    schoolName: yup.string().required('school name is required'),
    schoolAddress: yup.string().required('School Address is required'),
    email: yup.string().email('Enter Proper email address of school'),
});
export const AddSchool = ({ open, onClose, schoolData }) => {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            schoolName: '',
            schoolAddress: '',
            status: true,
            email: ''
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const response = await dispatch(createSchool(values));

                if (response.payload.status) {
                    toast.success(response.payload.message);
                    dispatch(getSchoolList(schoolData));
                    onClose();

                    // Reset the form after successful submission
                    formik.resetForm();
                } else {
                    toast.error(response.payload.message);
                }
            } catch (error) {
                // Handle error if necessary
                toast.error("Error submitting the form.");
            }
        },
    });
    return (
        <Dialog open={open} onClose={onClose} className="mui-modal x-large-dialog">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Add School</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="schoolName">School Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="schoolName"
                                placeholder="Enter School Name"
                                name="schoolName"
                                value={formik.values.schoolName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.schoolName && formik.errors.schoolName && (
                            <div className="error m-1">{formik.errors.schoolName}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="schoolAddress">School Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="schoolAddress"
                                placeholder="Enter School Address"
                                name="schoolAddress"
                                value={formik.values.schoolAddress}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.schoolAddress && formik.errors.schoolAddress && (
                            <div className="error m-1">{formik.errors.schoolAddress}</div>
                        )}
                    </div>

                    {/* Email input box */}

                    <div className="form-group">
                        <div className="form-floating">
                            <label htmlFor="schoolEmail">School Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="schoolEmail"
                                placeholder="Enter School Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                        </div>
                        {formik.touched.email && formik.errors.email && (
                            <div className="error m-1">{formik.errors.email}</div>
                        )}
                    </div>

                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" type="submit">Add</button>
                    <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddSchool;